export const _SystemPick = [
    {
        key: 3,
        value: 3,
        text: 'System 3'
    },
    {
        key: 4,
        value: 4,
        text: 'System 4'
    },
    {
        key: 5,
        value: 5,
        text: 'System 5'
    },
    {
        key: 6,
        value: 6,
        text: 'System 6'
    },
    {
        key: 7,
        value: 7,
        text: 'System 7'
    },
    {
        key: 8,
        value: 8,
        text: 'System 8'
    },
    {
        key: 9,
        value: 9,
        text: 'System 9'
    },
    {
        key: 10,
        value: 10,
        text: 'System 10'
    },
    {
        key: 11,
        value: 11,
        text: 'System 11'
    },
    {
        key: 12,
        value: 12,
        text: 'System 12'
    }
]

export const _PowerNumbers = [
    {
        key: 0,
        value: 0,
        text: 'No Power Number'
    },
    {
        key: 1,
        value: 1,
        text: '1 Power Number'
    },
    {
        key: 2,
        value: 2,
        text: '2 Power Numbers'
    },
    {
        key: 3,
        value: 3,
        text: '3 Power Numbers'
    }
]

export const _ForecastDrawType = [
    {
        key: 'ABCD',
        text: 'ABCD',
        value: 'ABCD'
    },
    {
        key: 'AABC',
        text: 'AABC',
        value: 'AABC'
    },
    {
        key: 'AABB',
        text: 'AABB',
        value: 'AABB'
    },
    {
        key: 'AAAB',
        text: 'AAAB',
        value: 'AAAB'
    }    
]

export const _DueDraw = [
    {
        text: "100 draws",
        value: 100
    },
    {
        text: "500 draws",
        value: 500
    },
    {
        text: "1000 draws",
        value: 1000
    },
    {
        text: "2000 draws",
        value: 2000
    },

]

export const _HotWarmColdDraw = [
    {
        text: "30 draws",
        value: 30
    },
    {
        text: "60 draws",
        value: 60
    },
    {
        text: "90 draws",
        value: 90
    },
    {
        text: "120 draws",
        value: 120
    }
]

export const _PickSystem = [
    {
        text: "Pick 3",
        value: 3
    },
    {
        text: "Pick 4",
        value: 4
    },
    {
        text: "Pick 5",
        value: 5
    },
    {
        text: "Pick 6",
        value: 6
    },
    {
        text: "Pick 7",
        value: 7
    },
    {
        text: "Pick 8",
        value: 8
    },
    {
        text: "Pick 9",
        value: 9
    },
    {
        text: "Pick 10",
        value: 10
    },
    {
        text: "Pick 11",
        value: 11
    },
    {
        text: "Pick 12",
        value: 12
    }
]

export const _PickMissingCombination = [
    {
        text: "Pick 3",
        value: 3
    },
    {
        text: "Pick 4",
        value: 4
    },
    {
        text: "Pick 5",
        value: 5
    }]


export const _PickSystemCombination = [
    {
        text: "Pick 2",
        value: 2
    },    
    {
            text: "Pick 3",
            value: 3
        },
        {
            text: "Pick 4",
            value: 4
        },
        {
            text: "Pick 5",
            value: 5
        }]
export const _Neighbour2Combination = [
    {
        text: "01-02",
        value: "01-02"
    },
    {
        text: "01-03",
        value: "01-03"
    },
    {
        text: "01-04",
        value: "01-04"
    },
    {
        text: "01-05",
        value: "01-05"
    },
    {
        text: "01-06",
        value: "01-06"
    },
    {
        text: "01-07",
        value: "01-07"
    },
    {
        text: "01-08",
        value: "01-08"
    },
    {
        text: "02-03",
        value: "02-03"
    },
    {
        text: "02-04",
        value: "02-04"
    },
    {
        text: "02-05",
        value: "02-05"
    },
    {
        text: "02-06",
        value: "02-06"
    },
    {
        text: "02-07",
        value: "02-07"
    },
    {
        text: "02-08",
        value: "02-08"
    },
    {
        text: "03-04",
        value: "03-04"
    },
    {
        text: "03-05",
        value: "03-05"
    },
    {
        text: "03-06",
        value: "03-06"
    },
    {
        text: "03-07",
        value: "03-07"
    },
    {
        text: "03-08",
        value: "03-08"
    },
    {
        text: "04-05",
        value: "04-05"
    },
    {
        text: "04-06",
        value: "04-06"
    },
    {
        text: "04-07",
        value: "04-07"
    }, {
        text: "04-08",
        value: "04-08"
    },
    {
        text: "05-06",
        value: "05-06"
    },
    {
        text: "05-07",
        value: "05-07"
    },
    {
        text: "05-08",
        value: "05-08"
    },
    {
        text: "06-07",
        value: "06-07"
    },
    {
        text: "06-08",
        value: "06-08"
    },
    {
        text: "07-08",
        value: "07-08"
    }

]    
export const _Neighbour3Combination = [
    {
        text: "01-02-03",
        value: "01-02-03"
    },
    {
        text: "01-02-04",
        value: "01-02-04"
    },
    {
        text: "01-02-05",
        value: "01-02-05"
    },
    {
        text: "01-02-06",
        value: "01-02-06"
    },
    {
        text: "01-02-07",
        value: "01-02-07"
    },
    {
        text: "01-02-08",
        value: "01-02-08"
    },
    {
        text: "01-03-04",
        value: "01-03-04"
    },
    {
        text: "01-03-05",
        value: "01-03-05"
    },
    {
        text: "01-03-06",
        value: "01-03-06"
    },
    {
        text: "01-03-07",
        value: "01-03-07"
    },
    {
        text: "02-03-04",
        value: "02-03-04"
    },
    {
        text: "02-03-05",
        value: "02-03-05"
    },
    {
        text: "02-03-06",
        value: "02-03-06"
    },
    {
        text: "02-03-07",
        value: "02-03-07"
    },
    {
        text: "02-03-08",
        value: "02-03-08"
    },
    {
        text: "01-04-05",
        value: "01-04-05"
    },
    {
        text: "01-04-06",
        value: "01-04-06"
    },
    {
        text: "01-04-07",
        value: "01-04-07"
    },
    {
        text: "01-04-08",
        value: "01-04-08"
    },
    {
        text: "02-04-05",
        value: "02-04-05"
    },
    {
        text: "02-04-06",
        value: "02-04-06"
    },
    {
        text: "02-04-07",
        value: "02-04-07"
    },
    {
        text: "02-04-08",
        value: "02-04-08"
    },
    {
        text: "03-04-05",
        value: "03-04-05"
    },

    {
        text: "03-04-06",
        value: "03-04-06"
    },
    {
        text: "03-04-07",
        value: "03-04-07"
    },
    {
        text: "03-04-08",
        value: "03-04-08"
    },
    {
        text: "01-05-06",
        value: "01-05-06"
    },
    {
        text: "01-05-07",
        value: "01-05-07"
    },
    {
        text: "01-05-08",
        value: "01-05-08"
    },
    {
        text: "02-05-06",
        value: "02-05-06"
    },
    {
        text: "02-05-07",
        value: "02-05-07"
    },
    {
        text: "02-05-08",
        value: "02-05-08"
    },
    {
        text: "03-05-06",
        value: "03-05-06"
    },
    {
        text: "03-05-07",
        value: "03-05-07"
    },
    {
        text: "03-05-08",
        value: "03-05-08"
    },
    {
        text: "04-05-06",
        value: "04-05-06"
    },
    {
        text: "04-05-07",
        value: "04-05-07"
    },
    {
        text: "04-05-08",
        value: "04-05-08"
    },
    {
        text: "03-06-07",
        value: "03-06-07"
    },
    {
        text: "03-06-08",
        value: "03-06-08"
    },
    {
        text: "04-06-07",
        value: "04-06-07"
    },
    {
        text: "04-06-08",
        value: "04-06-08"
    },
    {
        text: "05-06-07",
        value: "05-06-07"
    },
    {
        text: "05-06-08",
        value: "05-06-08"
    },
    {
        text: "01-07-08",
        value: "01-07-08"
    },
    {
        text: "02-07-08",
        value: "02-07-08"
    },
    {
        text: "03-07-08",
        value: "03-07-08"
    },

    {
        text: "04-07-08",
        value: "04-07-08"
    },
    {
        text: "05-07-08",
        value: "05-07-08"
    },
    {
        text: "06-07-08",
        value: "06-07-08"
    }

]
export const _FilterConditions = [
    { 
        text: "Hot/Warm/Cold Number",
        value: "00",
    },
    { 
        text: "Repeat Number",
        value: "01"
    },
    { 
        text: "Interval",
        value: "02"
    },
    { 
        text: "Hit Due",
        value: "20"
    },    
    { 
        text: "Power Number",
        value: "03"
    },
    {
        text: "Sum",
        value: "04"
    },
    {
        text: "Range",
        value: "05"
    },
    {
        text: "Single Neighbour",
        value: "06"
    },
    {
        text: "Pair Neighbour",
        value: "07"
    },
    {
        text: "Triple Neighbour",
        value: "08"
    },
    {
        text: "Combination Result",
        value: "09"
    },
    {
        text: "Combination Interval",
        value: "10"
    },
    {
        text: "Group Pattern",
        value: "11"
    },
    {
        text: "Group Direct Pattern",
        value: "12"
    },
    {
        text: "Group SubSet",
        value: "13"
    },
    {
        text: "Gap",
        value: "14"
    },
    {
        text: "Gap Pattern",
        value: "15"
    },
    {
        text: "Hit Due",
        value: "20"
    },
    ,
    {
        text: "Combination Hit Count",
        value: "21"
    },
    {
        text: "Coverage",
        value: "98"
    },
    {
        text: "Reduce Wheels",
        value: "99"
    }
]

export const _SystemBalls = [
    {
        text: "6 Balls",
        value: 6
    },
    {
        text: "5 Balls",
        value: 5
    },
    {
        text: "4 Balls",
        value: 4
    },
    {
        text: "3 Balls",
        value: 3
    },
    {
        text: "2 Balls",
        value: 2
    }
]

export const _RatingOptiosn = [
    {   text: "Any",
        value: 0,
    },
    {
        text: 'Excellent (8)',
        value: 8,
        image: {
            src: "/assets/point8.png",
            avatar: true
        }
    },
    {
        text: 'Excellent (7)',
        value: 7,
        image: {
            src: "/assets/point7.png",
            avatar: true
        }
    },
    {
        text: 'Very Good (6)',
        value: 6,
        image : {
            src: "/assets/point6.png",
            avatar: true
        }
    },
    {
        text: 'Good (5)',
        value: 5,
        image: {
            src: "/assets/point5.png",
            avatar: true
        }
    },
    {
        text: 'Average (4)',
        value: 4,
        image:{ 
            src: "/assets/point4.png",
            avatar: true
        }
    },
    {
        text: 'Average (3)',
        value: 3,
        image: {
            avatar: true,
            src: "/assets/point3.png"
        }
    },
    {
        text: 'Below Average (2)',
        value: 2,
        image: {
            src: "/assets/point2.png",
            avatar: true
        }
    },
    {
        text: 'Below Average (1)',
        value: 1,
        image: {
            src: "/assets/point1.png",
            avatar: true
        }
    },
] 
export const _DrawTypeOptions = [
    {
        key: 'ABCD',
        text: 'ABCD',
        value: 'ABCD'
    },
    {
        key: 'AABC',
        text: 'AABC',
        value: 'AABC'
    },
    {
        key: 'AABB',
        text: 'AABB',
        value: 'AABB'
    },
    {
        key: 'AAAB',
        text: 'AAAB',
        value: 'AAAB'
    },
    {
        key: 'AAAA',
        text: 'AAAA',
        value: 'AAAA'
    }
]

export const _DrawTypeOptionsWithAll = [
    {
        key: 'A',
        text: 'All',
        value: 'A'
    },
    {
        key: 'ABCD',
        text: 'ABCD',
        value: 'ABCD'
    },
    {
        key: 'AABC',
        text: 'AABC',
        value: 'AABC'
    },
    {
        key: 'AABB',
        text: 'AABB',
        value: 'AABB'
    },
    {
        key: 'AAAB',
        text: 'AAAB',
        value: 'AAAB'
    },
    {
        key: 'AAAA',
        text: 'AAAA',
        value: 'AAAA'
    }
]

export const  _StatusOptions = [
    {
      key: '0',
      text: 'All',
      value: '0'
    },
    {
      key: '1',
      text: 'In Range',
      value: '1'
    },
    {
      key: '2',
      text: 'Not Due',
      value: '2'
    },
    {
      key: '3',
      text: 'Over Due',
      value: '3'
    }

  ]

  export const _RowPerPageOptions = [
    { value: 5,  text: "5"  },
    { value: 10, text: "10" },
    { value: 20, text: "20" },
    { value: 50, text: "50" },
    { value: 999, text: "All" }
 ]


export const _PrizeTypeOptions = [
    {   key: "A",
        text: 'All Prizes',
        value: 'A'
    },
    {
        key: 'T',
        text: 'Top3',
        value: 'T'
    },
      
    {
      key: '1',
      text: '1st',
      value: '1'
    },
    {
        key: '2',
        text: '2nd',
        value: '2'
    },
    {
        key: '3',
        text: '3rd',
        value: '3'
    },
    {
        key: 'S',
        text: 'Starter',
        value: 'S'
    },
    {
        key: 'C',
        text: 'Consolation',
        value: 'C'
    }
]

export const _DrawType3DOptions = [
    {
        key: 'ABC',
        text: 'ABC',
        value: 'ABC'
    },
    {
        key: 'AAB',
        text: 'AAB',
        value: 'AAB'
    },
    {
        key: 'AAA',
        text: 'AAA',
        value: 'AAA'
    }
]

export const _DrawType3DOptionsWithAll = [
    {
        key: 'All',
        text: 'All',
        value: 'A'
    },
    {
        key: 'ABC',
        text: 'ABC',
        value: 'ABC'
    },
    {
        key: 'AAB',
        text: 'AAB',
        value: 'AAB'
    },
    {
        key: 'AAA',
        text: 'AAA',
        value: 'AAA'
    }
]

export const _DrawType2DOptions = [
    {
        key: 'AB',
        text: 'AB',
        value: 'AB'
    },
    {
        key: 'AA',
        text: 'AA',
        value: 'AA'
    }
]

export const _DigitPositionOptions = [
    {
        key: '1',
        text: '1st',
        value: 1
    },
      
    {
      key: '2',
      text: '2nd',
      value: 2
    },
    {
        key: '3',
        text: '3rd',
        value: 3
    },
    {
        key: '4',
        text: '4th',
        value: 4
    }
]

export const _3DPattern = [
    {
        text: 'ABC',
        value: "ABC"
    },
    {
        text: 'AAB',
        value: "AAB"
    },
    {
        text: 'AAA',
        value: "AAA"
    }
] 

export const _2DPattern = [
    {
        text: 'AB',
        value: "AB"
    },
    {
        text: 'AA',
        value: "AA"
    }
] 

export const _RankTypeOptions = [
    {
        key: 'S',
        text: 'All Rank',
        value: 'S'
    },
    {
      key: 'T',
      text: 'Top3 Rank',
      value: 'T'
    },
    {
        key: 'M',
        text: 'By Month - All Rank',
        value: 'M'
    },
    {
        key: 'N',
        text: 'By Month - Top3',
        value: 'N'
    }

]


export const _NoOfDrawOptions = [
    {
        key: '1',
        text: '1 Month',
        value: 12
    },
      
    {
      key: '2',
      text: '2 Months',
      value: 24
    },
    {
        key: '3',
        text: '3 Months',
        value: 36
    },
    {
        key: '6',
        text: '6 Months',
        value: 72
    },
    {
        key: '12',
        text: '1 Year',
        value: 144
    },
    {
        key: '720',
        text: '5 Years',
        value: 720
    },
    {
        key: '1440`',
        text: '10 Years',
        value: 1440
    }
]