import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useStore } from "../../app/store/store";
import { CSVLink } from "react-csv";
import { Dropdown, DropdownProps, Header, Icon, Input, Label, Popup, Table } from "semantic-ui-react";
import { MyDropDownStringItems, PredefinedWatchList, WheelingResult } from "../../app/models/IDayResult";
import AddWheel from "./AddWheel";
import agent from "../../app/api/agent";
import { _PowerNumbers, _SystemPick } from "../../app/lib/CommonData";
import NumberSelection from "./NumberSelection";
import { toast } from "react-toastify";
import LoadExistingData from "./LoadExistingData";

export default observer(function Wheeling() {
    const { userStore, modalStore } = useStore();
    const numbersRef = useRef<any>(null);
    const keyNumbersRef = useRef<any>(null);
    const [systemPick, setSystemPick] = useState(6);
    const [powerNumber, setPowerNumber] = useState(0);
    const [predefinedNumber, setPredefinedNumber] = useState<string>("");
    const [predefinedNumberOptions, setPredefinedNumberOptions] = useState<MyDropDownStringItems[]>([]);
    const [numbers, setNumbers] = useState<string>("");
    const [keyNumbers, setKeyNumbers] = useState<string>("");
    const [coverage, setCoverage] = useState("");
    const [coverageOptions, setCoverageOptions] = useState<MyDropDownStringItems[]>([]);
    const [data, setData] = useState<WheelingResult[]>([]);
    const [totoNumberOptions, setTotoNumberOptions] = useState<MyDropDownStringItems[]>([]);
    const [totoNumber, setTotoNumber] = useState("");

    const headers = [{ label: "Ball", key: "ball" },
    { label: "Sum", key: "total_Sum" },
    { label: "Range", key: "range" },
    { label: "Interval Pattern", key: "interval_Pattern" },
    { label: "Odd/Even Pattern", key: "odd_Even_Pattern" },
    { label: "Low/Mid/High", key: "high_Med_Low_Pattern" },
    { label: "Gap", key: "gap_Pattern" }]

    var requiredNumbers = 0;

    const loadClicked = () => {
        modalStore.openModal(<LoadExistingData></LoadExistingData>, `Load Existing Saved Data`, "large", null, () => {
            const value = localStorage.getItem("retVal");
            if (value && value.toString().length > 0) {
                var predefinedSavedData = JSON.parse(value);

                setSystemPick(predefinedSavedData.systemPick);
                setPowerNumber(predefinedSavedData.powerNumber);
                setCoverage(predefinedSavedData.coverage);

                setTotoNumber(predefinedSavedData.numberSelection.toString());
                setPredefinedNumber(predefinedSavedData.wheelID.toString());

                setKeyNumbers(predefinedSavedData.selectedPowerNumber);
                setNumbers(predefinedSavedData.selectedNumbers);

                if (numbersRef && numbersRef.current) {
                    numbersRef.current!.inputRef!.current!.value = predefinedSavedData.selectedNumbers;
                }
                if (keyNumbersRef && keyNumbersRef.current) {
                    keyNumbersRef.current!.inputRef!.current!.value = predefinedSavedData.selectedPowerNumber;
                }

            }
        });
    }

    const showPickNumbers = () => {
        const totalNumber = parseInt(totoNumber) - powerNumber

        if (!predefinedNumber || predefinedNumber.length === 0) {
            alert("Please select Pre-defined Wheeling ! ");
            return;
        }

        const numbers = numbersRef.current!.inputRef!.current!.value;

        if (numbers && numbers.length > 0 && !checkNumbers(numbers, "Toto Number")) {
            return;
        }

        localStorage.setItem("existingNumbers", numbers);
        localStorage.setItem("NumberLength", totalNumber + "");

        modalStore.openModal(<NumberSelection></NumberSelection>, `Select ${totalNumber} Toto Numbers`, "large", null, () => {
            setNumbers(localStorage.getItem("existingNumbers")!);
            numbersRef.current!.inputRef!.current!.value = localStorage.getItem("existingNumbers")!;

        });
    }

    const showKeyNumbers = () => {
        const totalNumber = powerNumber;

        if (!predefinedNumber || predefinedNumber.length === 0) {
            alert("Please select Pre-defined Wheeling ! ");
            return;
        }

        const keyNumbers = keyNumbersRef.current!.inputRef!.current!.value;

        if (keyNumbers && keyNumbers.length > 0 && !checkNumbers(keyNumbers,"Power Number")) {
            return;
        }
        
        localStorage.setItem("existingNumbers", keyNumbers);
        localStorage.setItem("NumberLength", totalNumber + "");

        modalStore.openModal(<NumberSelection></NumberSelection>, `Select ${totalNumber} Power Numbers`, "large", null, () => {
            setKeyNumbers(localStorage.getItem("existingNumbers")!);
            keyNumbersRef.current!.inputRef!.current!.value = localStorage.getItem("existingNumbers")!;
        })
    }

    const systemPickChanged = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        setSystemPick(parseInt(data.value + ""));

    }

    const predefinedNumberChanged = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        setPredefinedNumber(data.value + "");
    }

    const checkNumbers = (data: string, title: string) => {
        const selectedNumbers = data.split(',');
        var checkDuplicate:string[] = [];

        for (var i = 0; i < selectedNumbers.length; i++) {
            const selectedNumberToCheck = selectedNumbers[i];

            if (parseInt(selectedNumberToCheck) > 49 || parseInt(selectedNumberToCheck) <= 0) {
                window.alert(`${title}: ${selectedNumberToCheck} is invalid. The number must be between 1 and 49!`);

                return false;
            }

            const duplicateIndex = checkDuplicate.findIndex((x) => x === selectedNumberToCheck);
            if (duplicateIndex >= 0) {
                window.alert(`${title}: ${selectedNumberToCheck} is duplicate in key numbers !`);

                return false;
            }
            checkDuplicate.push(selectedNumberToCheck);
        }

        return true;
    }

    const wheelClicked = () => {
        if (!predefinedNumber || predefinedNumber.length === 0) {
            alert("Please select Pre-defined Wheeling ! ");
            return;
        }

        setData([]);

        var totalKeyEntered = 0;
        var totalNumberEntered = 0;
        const keyNumbers = keyNumbersRef.current! === null ? "" : keyNumbersRef.current!.inputRef!.current!.value;
        const numbers = numbersRef.current!.inputRef!.current!.value;

        if (keyNumbers && keyNumbers.trim().length > 0) {
            totalKeyEntered = keyNumbers.split(',').length;
        }

        if (numbers && numbers.trim().length > 0) {
            totalNumberEntered = numbers.split(',').length;
        }

        const totalNumbers = totalKeyEntered + totalNumberEntered;

        if (totalNumbers !== parseInt(totoNumber)) {
            window.alert("Selected Numbers are not matched with Required Numbers !");
            return;
        }

        const selectedNumbers = numbers.split(',');
        const selectedKeyNumbers = keyNumbers.split(',');

        var allNumbers = "";
        var checkDuplicate:string[] = [];

        if (keyNumbers && keyNumbers.trim().length > 0) {
            for (var i = 0; i < selectedKeyNumbers.length; i++) {
                const selectedNumberToCheck = selectedKeyNumbers[i];

                if (parseInt(selectedNumberToCheck) > 49 || parseInt(selectedNumberToCheck) <= 0) {
                    window.alert(`Key Number: ${selectedNumberToCheck} is invalid. The number must be between 1 and 49!`);

                    return;
                }
                const index = selectedNumbers.findIndex((x:string) => x === selectedNumberToCheck);
                if (index >= 0) {
                    window.alert(`Key Number: ${selectedNumberToCheck} is already selected in toto numbers!`);

                    return;
                }

                const duplicateIndex = checkDuplicate.findIndex((x) => x === selectedNumberToCheck);
                if (duplicateIndex >= 0) {
                    window.alert(`Key Number: ${selectedNumberToCheck} is duplicate in key numbers !`);

                    return;
                }


                checkDuplicate.push(selectedNumberToCheck);
            }
            allNumbers = keyNumbers + "," + numbers;
        }
        else {
            allNumbers = numbers;
        }

        checkDuplicate = [];
        if (numbers && numbers.trim().length > 0) {
            for (var i = 0; i < selectedNumbers.length; i++) {
                const selectedNumberToCheck = selectedNumbers[i];

                if (parseInt(selectedNumberToCheck) > 49 || parseInt(selectedNumberToCheck) <= 0) {
                    window.alert(`Toto Number: ${selectedNumberToCheck} is invalid. The number must be between 1 and 49!`);

                    return;
                }

                const duplicateIndex = checkDuplicate.findIndex((x) => x === selectedNumberToCheck);
                if (duplicateIndex >= 0) {
                    window.alert(`Toto Number: ${selectedNumberToCheck} is duplicate !`);

                    return;
                }
                checkDuplicate.push(selectedNumberToCheck);
            }
        }
        agent.DrawTotoResult.getWheelOrdinaryResult(predefinedNumber, allNumbers).then((response) => {
            setData(response);
        });

    }

    const saveClicked = () => {
        var data: PredefinedWatchList = {
            keyNumbers: keyNumbers,
            numbers: numbers,
            userName: userStore.user!.username,
            wheelID: predefinedNumber
        }

        agent.DrawTotoResult.savePredefinedWatchList(data).then((response) => {
            if (response === "OK") {
                toast.info("Data saved !");
            } else {
                toast.error(response);
            }
        })


    }
    const powerNumberChanged = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        setPowerNumber(parseInt(data.value + ""));
        setKeyNumbers("");
    }

    const coverageChanged = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        setCoverage(data.value + "");

    }

    const totoNumberChanged = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        setTotoNumber(data.value + "");
    }

    const addClicked = () => {
        modalStore.openModal(<AddWheel></AddWheel>, "Add Wheel", "large", null, () => {
            agent.DrawTotoResult.getWheelingSource(userStore.user?.username!).then((response) => {
                //setWheeling(response);
            });
        })
    }


    useEffect(() => {
        agent.DrawTotoResult.getCoverage(systemPick, powerNumber).then((response) => {
            setCoverageOptions(response);
        });

    }, [systemPick, powerNumber]);

    useEffect(() => {
        agent.DrawTotoResult.getTotoNumberToWheel(systemPick, powerNumber, coverage).then((response) => {
            setTotoNumberOptions(response);
        });

    }, [systemPick, powerNumber, coverage]);

    useEffect(() => {
        agent.DrawTotoResult.getPredefinedWheeling(systemPick, powerNumber, coverage, totoNumber).then((response) => {
            setPredefinedNumberOptions(response);
        });

    }, [systemPick, powerNumber, coverage, totoNumber]);


    if (!userStore.isLogged) {
        return (<Header>Not Authorized</Header>)
    }
    return (
        <div style={{ overflowX: "auto" }}>
            <Table celled unstackable selectable singleLine size="small">
                <Table.Header>
                    <Table.Row key="line1">
                        <Table.Cell>
                        </Table.Cell>
                        <Table.Cell colSpan="3">
                            <CSVLink data={data} filename="PredefinedTickets" headers={headers}>
                                <Icon name="download"></Icon>Download
                            </CSVLink>
                        </Table.Cell>
                        <Table.Cell colSpan="3">
                            PRE-DEFINED WHEELING
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {userStore.isLogged && userStore.user?.username! === "SteveXie" &&
                                <Icon color="blue" name="add" onClick={addClicked}></Icon>
                            }
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row key="line2">
                        <Table.Cell>
                        </Table.Cell>
                        <Table.Cell colSpan="1">
                            <Popup size="large" content="Please Select Pick System" position="top center" trigger={
                                <Dropdown fluid style={{ "zIndex": "21" }}
                                    selection data-moveup="false"
                                    onChange={systemPickChanged}
                                    placeholder="Select Pick System"
                                    options={_SystemPick}
                                    value={systemPick}
                                    direction="right"
                                />
                            }>
                            </Popup>
                        </Table.Cell>
                        <Table.Cell colSpan="2">
                            <Popup size="large" content="Please Select Power Number(s)" position="top center" trigger={
                                <Dropdown fluid style={{ zIndex: 21 }}
                                    selection data-moveup="false"
                                    onChange={powerNumberChanged}
                                    placeholder="Select Power Number(s)"
                                    options={_PowerNumbers}
                                    value={powerNumber}
                                    direction="right"
                                />
                            }>
                            </Popup>
                        </Table.Cell>

                        <Table.Cell colSpan="3">
                            <Popup content="Select Min Guaranteed Win" position="top center" trigger={
                                <Dropdown fluid style={{ zIndex: 21 }}
                                    selection
                                    search
                                    onChange={coverageChanged}
                                    placeholder="Select Min Guaranteed Win"
                                    options={coverageOptions}
                                    value={coverage}
                                    direction="right"
                                />
                            }>
                            </Popup>
                        </Table.Cell>
                        <Table.Cell>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row key="line3">
                        <Table.Cell textAlign="center">
                            <Popup content="Load from database" trigger={
                                <Icon size="large" color="blue" name='arrow alternate circle right' inverted circular link onClick={() => loadClicked()} />
                            }>
                            </Popup>
                        </Table.Cell>

                        <Table.Cell colSpan="3">

                            <Popup size="large" content="Please select Total Numbers to Pick" position="top center" trigger={
                                <Dropdown fluid style={{ "zIndex": "20" }}
                                    selection data-moveup="false"
                                    onChange={totoNumberChanged}
                                    placeholder="Please select Total Numbers to Pick"
                                    options={totoNumberOptions}
                                    value={totoNumber}
                                    direction="right"
                                />
                            }>
                            </Popup>

                        </Table.Cell>
                        <Table.Cell colSpan="3">
                            <Popup content="Select Predefined Wheeling" position="top center" trigger={
                                <Dropdown fluid style={{ zIndex: 20 }}
                                    selection
                                    search
                                    onChange={predefinedNumberChanged}
                                    placeholder="Select Predefined Wheeling"
                                    options={predefinedNumberOptions}
                                    value={predefinedNumber}
                                    direction="right"
                                />
                            }>
                            </Popup>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {predefinedNumber && predefinedNumber.length > 0 &&
                                <Popup content="Save to database" trigger={
                                    <Icon size="large" color="red" name='save' inverted circular link onClick={() => saveClicked()} />
                                }>
                                </Popup>
                            }
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row key="line4" verticalAlign="middle">
                        <Table.Cell>
                        </Table.Cell>
                        {
                            (powerNumber !== 0 &&
                                <Table.Cell colSpan="2" verticalAlign="middle">
                                    <Input size="large" maxLength={30} fluid ref={keyNumbersRef} style={{ "vertical-align": "middle" }}
                                        placeholder={`Enter ${powerNumber} power number(s)`} defaultValue={keyNumbers}
                                        onKeyPress={(event: any) => {
                                            if (event.key !== ",") {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }
                                        }}>

                                        <input />
                                        <Popup content={`Enter ${powerNumber} power number(s)`} trigger={
                                            <Icon size="large" color="red" name='tasks' inverted circular link onClick={() => showKeyNumbers()} />
                                        }>
                                        </Popup>
                                    </Input>
                                </Table.Cell>
                            )
                        }
                        <Table.Cell colSpan={powerNumber === 0 ? "6" : "4"} verticalAlign="middle">
                            <Input size="large" maxLength={255} fluid ref={numbersRef}
                                placeholder={requiredNumbers === 0 ? "Enter or Select Numbers to be wheel" : "Enter or Select Numbers to Wheel: " + requiredNumbers} defaultValue={numbers}
                                onKeyPress={(event: any) => {
                                    if (event.key !== ",") {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }
                                }}>
                                <input />
                                <Popup content="Enter new or Edit Toto Nos" trigger={
                                    <Icon size="large" color="red" name='tasks' inverted circular link onClick={() => showPickNumbers()} />
                                }>
                                </Popup>
                            </Input>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Popup content="Generate Wheeled Toto Nos" trigger={<Icon size="large" name='random' inverted circular link onClick={wheelClicked} />}>
                            </Popup>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row key="line5">
                        <Table.HeaderCell textAlign="center" rowSpan="2">#</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" rowSpan="2">Ball</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" rowSpan="2" data-position="bottom right" data-tooltip="Sum All Numbers">Sum</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" rowSpan="2" data-position="bottom right" data-tooltip="Max Number - Min Number">Range</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" colSpan="3">Pattern</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row key="line6">
                        <Table.HeaderCell textAlign="center" data-tooltip="Interval 0-5|6-10|11-99">Interval</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" data-tooltip="Even can be divided by 2. Odd cannot be divided by 2" >Odd/Even</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" data-tooltip="Low: 1-16, Med: 17-32, High: 33-49">Low/Med/High</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" data-tooltip="Gap between adjacent numbers">Gap</Table.HeaderCell>

                    </Table.Row>

                </Table.Header>
                <Table.Body>
                    {data.map(((x, index) => {
                        return (
                            <Table.Row key={index} className="freeze-head-and-col">
                                <Table.Cell textAlign="center">{index + 1}</Table.Cell>
                                <Table.Cell textAlign="center">
                                    {
                                        x.ball.split('-').map((ball) => {
                                            return (<Label circular size="big" className="font-number" color="blue">{ball}</Label>)
                                        })
                                    }
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {x.total_Sum}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {x.range}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {x.interval_Pattern}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {x.odd_Even_Pattern}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {x.high_Med_Low_Pattern}
                                </Table.Cell>

                                <Table.Cell textAlign="center">
                                    {x.gap_Pattern}
                                </Table.Cell>
                            </Table.Row>
                        );
                    }))
                    }

                </Table.Body>
            </Table>
        </div>
    );

});