import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Button,  Header,  Label } from "semantic-ui-react";
import { useStore } from "../app/store/store";
import * as Yup from 'yup';
import MyTextInputNonFluidPassword from "../app/lib/MyTextInputNonFluidPassword";
import { useParams } from "react-router";


export default observer(function ResetPassword(){
    const {userStore} = useStore();
    const validationSchema = Yup.object({
        newPassword: Yup.string().required("New Password is required").min(8,"Length must be at least 8 !").matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&$])(?=.{8,})/,
            'Invalid password. Must have at least 1 upper, 1 lower case, 1 number, and 1 symbol'
          ),
        confirmPassword: Yup.string().required("Confirm Password is required").min(8,"Length must be at least 8 !").oneOf([Yup.ref('newPassword'), null], 'New and Confirm Passwords must match'),

    });
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);



    const togglePassword1 = ()=> {
        setShowPassword1(!showPassword1);
    };
    const togglePassword2 = ()=> {
        setShowPassword2(!showPassword2);
    };
    let {id} = useParams<{id: string}>();
  
  
    return (
        <Formik  initialValues={{confirmPassword: "", id:id,  newPassword:"", error: null}}
                onSubmit={
                    (values,{setErrors}) => userStore.resetActualPassword(values).catch(err => setErrors({error: 'Unable to reset the password'}))
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <Header>Password length must be at least 8. Should contain at least one upper case, one lower case, one digit, and one symbol such as $,!,%, etc</Header>
                        <MyTextInputNonFluidPassword togglePassword={togglePassword1} name="newPassword" placeholder="New Password" type={!showPassword1 ? "password" : ""} showPassword={showPassword1}></MyTextInputNonFluidPassword>
                        <MyTextInputNonFluidPassword togglePassword={togglePassword2} name="confirmPassword" placeholder="Confirm Password" type={!showPassword2 ? "password" : ""} showPassword={showPassword2}></MyTextInputNonFluidPassword>
 
                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Button icon="reset" loading={isSubmitting} positive content="Reset Password" type="submit" fluid></Button>
                    </Form>
                
            )}
        </Formik>
    )
})