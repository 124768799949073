import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react"
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { LabelKeyObject } from "react-csv/components/CommonPropTypes";
import {  Table, Header, Checkbox, CheckboxProps, Input, Icon, Popup,  DropdownProps, Dropdown } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import { _HotWarmColdDraw, _PickMissingCombination, _PickSystemCombination } from "../../app/lib/CommonData";
import { _DisplayEmptyIfZero, _DisplayZero, _GetBonusText } from "../../app/lib/CommonFunctions";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { CombinationHitCount, MissingDrawsCombination, MissingDrawsCombinationDetail} from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";

export default observer(function CombinationHitCount(){
    const selectedDrawRef = useRef<any>();
    const [noOfDraw, setNoOfDraw] = useState(30);
    const [loading, setLoading] = useState(true);
    const [useBonus, setUseBonus] = useState(true);
    const [analysis, setAnalysis] = useState<CombinationHitCount>();
    const {userStore} = useStore();
    const [combination, setCombination] = useState(3);

    //const periodDisplay = (curYear - 11).toString() + " to " + (curYear - 1).toString();
    const noOfDrawClicked = () =>{

      const selectedDraw =  selectedDrawRef.current!.inputRef!.current!.value;

      if (Number(selectedDraw)){
        setNoOfDraw(selectedDraw);
        setLoading(true);
      }
    }


    const onBonusChanged =  (_ : any, data: CheckboxProps )=>{
      setUseBonus(data.checked!);
      setLoading(true);
    }

    const combinationChanged = (_ : any, data: DropdownProps ) => {
        setCombination(parseInt(data.value + ""));
    }


    useEffect(()=>{
          agent.DrawTotoResult.getCombinationHitCount(noOfDraw,  useBonus, combination).then((response)=>{
            setAnalysis(response);
              setLoading(false);
              
          });
    },[noOfDraw, useBonus, combination]);


    if(!userStore.isLogged){
      return (<Header>Not Authorized</Header>)
    }

    if (loading || !analysis || analysis === undefined){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto"}}>
        <Table celled unstackable selectable singleLine size="small">
        <Table.Header>
          <Table.Row key="header1">
          <Table.HeaderCell textAlign="center">
          <Input ref={selectedDrawRef} fluid maxLength={4} onKeyPress={(event:any) => {
                                if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }}
                        icon={<Icon name='search' inverted circular link onClick={noOfDrawClicked} />}
                        placeholder="No of Draws" defaultValue={noOfDraw}></Input>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" colSpan="4">
                    <Popup content="Please select Combination" position="top center" trigger={
                        <Dropdown  fluid   style={{zIndex:21}}
                            selection data-moveup="false"
                            onChange = {combinationChanged}
                            placeholder="Select Combination"
                            options={_PickSystemCombination}
                            defaultValue = {combination}
                            direction = "right"
                        />     
                      }>
                      </Popup>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" colSpan="6">
          <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged}>
          </Checkbox>
          </Table.HeaderCell>
          
          <Table.HeaderCell textAlign="left" colSpan="20">
         <Header>FREQUENCY BY PICK</Header>
         </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" colSpan="6">

         </Table.HeaderCell>
          </Table.Row>
          {
            analysis && analysis.columnValues && Object.entries(analysis.columnValues[0]).map(([key, value])=>{
              return (
              key === "Draw Date" &&
              <Table.HeaderCell textAlign="center">
                {key}
              </Table.HeaderCell>)
            }
            )
          }
          {
            analysis && analysis.columnValues && Object.entries(analysis.columnValues[0]).map(([key, value])=>{
              return (
                key !== "Draw Date" &&
              <Table.HeaderCell textAlign="center">
                {key}
              </Table.HeaderCell>)
            }
            )
          }

        </Table.Header>
    
        <Table.Body>
        {
            analysis && analysis.columnValues && analysis.columnValues.map((x, index)=>{
              return(
                <Table.Row key={index}>
                  {
                    Object.entries(x).map(([key,value])=>{
                      return (
                        key === "Draw Date" && 
                        <Table.Cell textAlign="center">
                          {_DisplayZero(value)}
                        </Table.Cell>
                      )
                    })
                  }
                  {
                    Object.entries(x).map(([key,value])=>{
                      return (
                        key !== "Draw Date" &&
                        <Table.Cell textAlign="center">
                          {_DisplayZero(value)}
                        </Table.Cell>
                      )
                    })
                  }

                </Table.Row>
              )
            }) 
        }
 
        </Table.Body>
      </Table>
   
      </div>
    );
});