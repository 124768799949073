import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Label } from "semantic-ui-react";
import * as Yup from 'yup';
import agent from "../../app/api/agent";
import MyMultiTextInputNonFluid from "../../app/lib/MyMultiTextInputNonFluid";
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import { useStore } from "../../app/store/store";


export default observer(function AddWhell() {
    const { modalStore } = useStore();

    const validationSchema = Yup.object({
        wheeling_Name: Yup.string().required("Wheeling Name is required"),
        detail: Yup.string().required("Numbers are required !"),
    });

    return (
        <Formik initialValues={{ coverage: "", power_number: 0, system_pick: 6, wheeling_Name: "", detail: "", separator: "", error: null }}
            onSubmit={
                (values, { setErrors }) => agent.Account.saveWheelingDetail(values).then((response) => {
                    modalStore.closeModal();
                }).catch(err => setErrors({ error: 'Unable to save wheeling details. Message: !' + err }))
            } validationSchema={validationSchema}
        >
            {(
                { handleSubmit, isSubmitting, errors }) => (
                <Form className="ui form" onSubmit={handleSubmit}>
                    <MyTextInputNonFluid name="wheeling_Name" placeholder="Wheeling Name/Description"></MyTextInputNonFluid>
                    <MyTextInputNonFluid name="coverage" placeholder="Coverage"></MyTextInputNonFluid>
                    <MyTextInputNonFluid name="power_number" placeholder="Power Number"></MyTextInputNonFluid>
                    <MyTextInputNonFluid name="system_pick" placeholder="System Pick"></MyTextInputNonFluid>

                    <MyTextInputNonFluid name="separator" placeholder="Separator"></MyTextInputNonFluid>
                    <MyMultiTextInputNonFluid name="detail" placeholder="Numbers Detail"></MyMultiTextInputNonFluid>

                    <ErrorMessage name="error" render={() => <Label style={{ marginBottom: 10 }} basic color="red" content={errors.error}></Label>} />
                    <Button icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>
                </Form>

            )}
        </Formik>
    )
})