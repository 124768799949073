import React from "react";
import { Link } from "react-router-dom";
import { Button, Header, Label, } from "semantic-ui-react";
import ViewGraphics3DPopup from "../../feature/Search/ViewGraphics3DPopup";
import { useStore } from "../store/store";

interface Props{
    number: string;
    bonus: boolean;
    title?: string;
    children?: any;
    ballValue: string;
}

export default function DrawLinkWithResult(props: Props){
    
    const {userStore, modalStore} = useStore();
    const onClick = () => {
        localStorage.setItem("NumberToSearch", props.number);
        localStorage.setItem("NumberBonus",  props.bonus ? "1" : "0");
        modalStore.openModal(<ViewGraphics3DPopup></ViewGraphics3DPopup>,"View Interval", "fullscreen", null, ()=> {
        });
    }

    return (
        <Button size="mini" circular content={props.number} onClick={onClick}
                    color={props.ballValue.endsWith("B-X") ? "black" :
                            (props.ballValue.endsWith("O-X") ? "green" : "vk")}>
        </Button>
    );
}