import { useField } from "formik";
import React from "react";
import { Form,  Icon,  Input,  Label } from "semantic-ui-react";

interface Props {
    children?: never[];
    name: string;
    placeholder: string;
    label?: string;
    type?: string;
    maxLength?: number;
    focus?: boolean;
    showPassword: boolean;
    togglePassword: ()=>void;
}
export default function MyTextInputNonFluidPassword(props: Props){
    const [field, meta] = useField(props.name);

    return (
        <Form.Field inline error={meta.touched && !!meta.error}>
            <label>{props.label}</label>
            <Input fluid {...field} name={props.name} placeholder={props.placeholder} label={props.label}
                type={props.type} maxLength={props.maxLength} focus={props.focus} 
                icon={<Icon name={props.showPassword ? "eye slash" : "eye"} link onClick={props.togglePassword} />}
            />
            {meta.touched && meta.error ? <Label basic color='red'>{meta.error}</Label> : null
            } 
        </Form.Field>
    );
}