import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react"
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { LabelKeyObject } from "react-csv/components/CommonPropTypes";
import {  Table, Header, Checkbox, CheckboxProps, Input, Icon, Popup,  DropdownProps, Dropdown } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import { _HotWarmColdDraw, _PickMissingCombination } from "../../app/lib/CommonData";
import { _DisplayEmptyIfZero, _GetBonusText } from "../../app/lib/CommonFunctions";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { MissingDrawsCombination, MissingDrawsCombinationDetail} from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";

export default observer(function MissingDrawsCombination(){
    const selectedDrawRef = useRef<any>();
    const [noOfDraw, setNoOfDraw] = useState(30);
    const [loading, setLoading] = useState(true);
    const [useBonus, setUseBonus] = useState(true);
    const [analysis, setAnalysis] = useState<MissingDrawsCombinationDetail[]>([]);
    const {userStore} = useStore();
    const [combination, setCombination] = useState(3);

    function displayHeader(){
      if (analysis?.length === 0) return null;
      var headerData = [];
      for (var i = 0; i <= analysis![0].max_Number;i++){
        headerData.push(i);
      }
      return(
        headerData.map((x)=>{
          return (<Table.HeaderCell textAlign="center">{x}</Table.HeaderCell>)
        })
      );
    }

    function displayValue(data: MissingDrawsCombinationDetail){
      if (analysis?.length === 0) return null;

      var detailData = [];
      
      for (var i = 0; i <= analysis![0].max_Number;i++){
        detailData.push(i);
      }

      return(
        detailData.map((x) => {
          return (
            Object.entries(data).map(([key, value]) => {
              if (key.startsWith("miss_") && key === "miss_" + x){
                return (<Table.Cell textAlign="center">{_DisplayEmptyIfZero(value)}</Table.Cell>)
              }
              return null;
            })
          )
        })
      )
    }

    function getHeaders():LabelKeyObject[]{
      var data:LabelKeyObject[] = [];
  
      data.push({
        label:"Draw Date",
        key: "drawDate"
      });

      for (var i = 0; i <= 35; i++){
        data.push({
          label:"Missed " + i,
          key: "Miss_" + i
        });
      
      }

        return data;
    }    
  
    //const periodDisplay = (curYear - 11).toString() + " to " + (curYear - 1).toString();
    const noOfDrawClicked = () =>{

      const selectedDraw =  selectedDrawRef.current!.inputRef!.current!.value;

      if (Number(selectedDraw)){
        setNoOfDraw(selectedDraw);
        setLoading(true);
      }
    }


    const onBonusChanged =  (_ : any, data: CheckboxProps )=>{
      setUseBonus(data.checked!);
      setLoading(true);
    }

    const combinationChanged = (_ : any, data: DropdownProps ) => {
        setCombination(parseInt(data.value + ""));
    }


    useEffect(()=>{
          agent.DrawTotoResult.getMissingCombination(noOfDraw,  useBonus, combination).then((response)=>{
              setAnalysis(response);
              setLoading(false);
              
          });
    },[noOfDraw, useBonus, combination]);


    if(!userStore.isLogged){
      return (<Header>Not Authorized</Header>)
    }

    if (loading || !analysis || analysis === undefined){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto"}}>
        <Table celled unstackable selectable singleLine size="small">
        <Table.Header>
          <Table.Row key="header1">
          <Table.HeaderCell textAlign="center">
          <Input ref={selectedDrawRef} fluid maxLength={4} onKeyPress={(event:any) => {
                                if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }}
                        icon={<Icon name='search' inverted circular link onClick={noOfDrawClicked} />}
                        placeholder="No of Draws" defaultValue={noOfDraw}></Input>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" colSpan="4">
                    <Popup content="Please select Combination" position="top center" trigger={
                        <Dropdown  fluid   style={{zIndex:21}}
                            selection data-moveup="false"
                            onChange = {combinationChanged}
                            placeholder="Select Combination"
                            options={_PickMissingCombination}
                            defaultValue = {combination}
                            direction = "right"
                        />     
                      }>
                      </Popup>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" colSpan="6">
          <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged}>
          </Checkbox>
          </Table.HeaderCell>
          
          <Table.HeaderCell textAlign="left" colSpan="20">
         <Header>MISSING COMBINATION</Header>
         </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" colSpan="6">

         </Table.HeaderCell>
          </Table.Row>
          <Table.Row key="header2">
            <Table.HeaderCell textAlign="center">
               Draw Date
            </Table.HeaderCell>
            {displayHeader()}
         </Table.Row>
        </Table.Header>
    
        <Table.Body>

          {analysis.map((x => {
            return (
                <Table.Row key={x.drawDate} className="freeze-head-and-col">
                    <Table.Cell textAlign="center">
                        {x.drawDate}
                    </Table.Cell>
                    {displayValue(x)}


                </Table.Row>
              );
            }))
          }   
           </Table.Body>
      </Table>
   
      </div>
    );
});