import { observer } from "mobx-react-lite";
import React from "react"
import { Tab } from 'semantic-ui-react'
import AnalysisByNeighbourPair from "./AnalysisByNeighbourPair";
import AnalysisByNeighbourSingle from "./AnalysisByNeighbourSingle";
import AnalysisByNeighbourTriple from "./AnalysisByNeighbourTriple";

export default observer(function AnalysisByNeighbour(){

  const panes = [
    {
      menuItem: 'Single',
      render: () => <Tab.Pane attached={false}><AnalysisByNeighbourSingle></AnalysisByNeighbourSingle></Tab.Pane>,
    },
    {
      menuItem: 'Pair',
      render: () => <Tab.Pane attached={false}><AnalysisByNeighbourPair></AnalysisByNeighbourPair></Tab.Pane>,
    },
    {
      menuItem: 'Triple',
      render: () => <Tab.Pane attached={false}><AnalysisByNeighbourTriple></AnalysisByNeighbourTriple></Tab.Pane>,
    }

  ]  
    return (
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    );

});