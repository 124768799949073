import { ErrorMessage, Field, Form, Formik } from "formik";
import { observer} from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button,  Divider,  Label } from "semantic-ui-react";
import * as Yup from 'yup';
import agent from "../../app/api/agent";
import { _SystemBalls } from "../../app/lib/CommonData";
import { _GetBonusText, _GetFilterName, _GetMatch } from "../../app/lib/CommonFunctions";
import { DropdownField } from "../../app/lib/DropdownField";
import { MyCheckBoxField } from "../../app/lib/MyCheckBoxField";
import { MyCheckBoxMatchField } from "../../app/lib/MyCheckBoxMatchField";
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import { MyDropDownStringItems, WheelingCondition } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";


export default observer(function FilterGroupDirectPattern(){
    const {userStore, modalStore} = useStore();
    const [groups, setGroups] = useState<MyDropDownStringItems[]>([]);


    const validationSchema = Yup.object({
        min: Yup.number().required("Minimum is required !").integer().test("checkGreater","Minimum must be smaller than Maximum !",(value, ctx)=> Number(ctx.parent.max) >= Number(value)),
        max: Yup.number().required("Maximum is required !").integer(),
        group: Yup.string().required("Group is required !"),
        subset: Yup.string().required("SubSet is required !")
    });

    useEffect(()=>{
        agent.DrawTotoResult.getGroup().then((response)=>{
            setGroups(response);
        })
    }, []);

    return (
        <Formik initialValues={{ min:0, max:3, group: "", subset: "",  error: null}}
                onSubmit={
                    (values,{setErrors}) => {
                        const group = values["group"];
                        const subset = values["subset"];
                        const min = values["min"];
                        const max = values["max"];

                        var filterCondition: WheelingCondition = {
                            filterType : "13",
                            filterTypeName: _GetFilterName("13") ,
                            data : group + "|" + subset,
                            min: min,
                            max: max
                        };

                        localStorage.setItem("FilterCondition", JSON.stringify(filterCondition));
                        modalStore.closeModal();
                    }
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <Field 
                                placeholder="Enter/Select Group"
                                search
                                options={groups}
                                name="group"
                                component={DropdownField}
                        />
                        <MyTextInputNonFluid data-tooltip="Enter row, col, or row-1, col-1"  name="subset" placeholder="Enter row, col, or row-1, col-1. For multi values, separated by coma"></MyTextInputNonFluid>
                         
                        <MyTextInputNonFluid data-tooltip="Minimum value to filter" type="number" name="min" placeholder="Enter Mininum"></MyTextInputNonFluid>
                        <MyTextInputNonFluid data-tooltip="Maximum value to filter" type="number" name="max" placeholder="Enter maximum"></MyTextInputNonFluid>
                       
                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Divider />
                        <Button icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>
                    </Form>
                
            )}
        </Formik>
    )
})