import { observer } from "mobx-react-lite";
import React, {  useState } from "react"
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { Table, Header, Checkbox, CheckboxProps, Input, Button, InputOnChangeData, Icon, Label } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import { _GetBonusText, _getWindowDimensions } from "../../app/lib/CommonFunctions";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { AnalysisByDrawDueResult, AnalysisByDrawResult } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";
import DrawSummary from "./DrawSummary";

export default observer(function AnalysisByDrawDue(){
    
    const [loading, setLoading] = useState(true);
    const [useBonus, setUseBonus] = useState(true);
    const [analysis, setAnalysis] = useState<AnalysisByDrawDueResult[]>([]);
    const {userStore, modalStore} = useStore();
    const [refreshData, setRefreshData] = useState(true);
    const [selectedNumber, setSelectedNumber] = useState("");
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(_getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    

    const headers = [{label: "Ball (100)",key: "ball"},
                    {label: "Due (100)", key: "dueHit"},
                    {label:"Due Type (100)", key: "dueType"},
                    {label: "Ball (500)",key:"ball1"},
                    {label: "Due (500)",key:"dueHit1"},
                    {label: "Due Type (500)",key:"dueType1"},
                    {label: "Ball (1000)",key:"ball2"},
                    {label: "Due (1000)",key:"dueHit2"},
                    {label: "Due Type (1000)",key:"dueType2"},
                    {label: "Ball (2000)",key:"ball3"},
                    {label: "Due (2000)",key:"dueHit3"},
                    {label: "Due Type (2000)",key:"dueType3"}
                  ]        

    const cellClicked = (numberClicked: number) =>{
      setSelectedNumber(numberClicked.toString());
    }

    const getSelectedColor = (numberToCheck: number) =>{
      if (numberToCheck.toString() === selectedNumber){
        return "lightgreen";
      }
      return "";
    }
    const onBonusChanged =  (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps )=>{
      setUseBonus(data.checked!);
      setLoading(true);
    }


    const searchData = () => {
      setRefreshData(true);

    }

    useEffect(()=>{

        agent.DrawTotoResult.getAnalysisByDrawDue(100, 500, 1000, 2000, useBonus).then((response)=>{

            setAnalysis(response);
            setLoading(false);
            setRefreshData(false);
            
        });

        
    },[useBonus,  refreshData]);

    const openSummary = ()=>{
      localStorage.setItem("useBonus", (useBonus ? "1" : "0"));

      modalStore.openModal(<DrawSummary></DrawSummary>,"Hot Warm Cold Summary","medium", null, ()=>{})

    }

    if(!userStore.isLogged){
      return (<Header>Not Authorized</Header>)
    }

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto",height:windowDimensions.height - 160}}>
        <Table celled unstackable selectable singleLine size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
              <Button color="blue" icon="share square" onClick={()=>openSummary()}></Button>
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="2">
                <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged}>
                </Checkbox>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center" colSpan="8">
                  <Header>ANALYSIS BY DRAW DUE
                        <Label circular color="red">OVER DUE</Label>                        
                        <Label circular color="green">IN RANGE</Label>                        
                        <Label circular color="orange">NOT DUE</Label>                     
                  </Header>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center" colSpan="2">
                        <CSVLink data={analysis} filename="AnalysisByDrawDue" headers={headers}>
                            <Icon name="download"></Icon>Download 
                        </CSVLink>         

              </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
                Draw #
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan="3">
              <Header as="h2">100</Header>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan="3">
              <Header as="h2">500</Header>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan="3">
              <Header as="h2">1000</Header>
            </Table.HeaderCell> 
            <Table.HeaderCell textAlign="center" colSpan="3">
              <Header as="h2">2000</Header>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
            <Table.HeaderCell  textAlign="center">
              Ball<br/>Number
            </Table.HeaderCell> 
            <Table.HeaderCell textAlign="center">
              Due<br/>Type
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Due<br/>Draw
            </Table.HeaderCell>
            <Table.HeaderCell  textAlign="center">
              Ball<br/>Number
            </Table.HeaderCell> 
            <Table.HeaderCell textAlign="center">
              Due<br/>Type
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Due<br/>Draw
            </Table.HeaderCell>
            <Table.HeaderCell  textAlign="center">
              Ball<br/>Number
            </Table.HeaderCell> 
            <Table.HeaderCell textAlign="center">
              Due<br/>Type
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Due<br/>Draw
            </Table.HeaderCell>
            <Table.HeaderCell  textAlign="center">
              Ball<br/>Number
            </Table.HeaderCell> 
            <Table.HeaderCell textAlign="center">
              Due<br/>Type
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Due<br/>Draw
            </Table.HeaderCell>
            </Table.Row>
        
        </Table.Header>
    
        <Table.Body>

          {analysis.map(((x, index) => {
            return (
                <Table.Row key={x.ball} className="freeze-head-and-col">
                  <Table.Cell onClick={()=>cellClicked(x.ball)} textAlign="center">{index + 1}</Table.Cell>
                  <Table.Cell onClick={()=>cellClicked(x.ball)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball)}>
                    <Label circular color={x.dueType === "OD" ? "red" : (x.dueType === "IR" ? "green" :"orange")}  size="medium">
                    {x.ball}
                    </Label>
                  </Table.Cell>
                  <Table.Cell onClick={()=>cellClicked(x.ball)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball)}>
                    {x.dueType}
                  </Table.Cell>
                  <Table.Cell onClick={()=>cellClicked(x.ball)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball)}>
                    {x.dueHit}
                  </Table.Cell>

                  <Table.Cell onClick={()=>cellClicked(x.ball1)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball1)}>
                    <Label circular color={x.dueType1 === "OD" ? "red" : (x.dueType1 === "IR" ? "green" :"orange")}  size="medium">
                    {x.ball1}
                    </Label>
                  </Table.Cell>
                  <Table.Cell onClick={()=>cellClicked(x.ball1)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball1)}>
                    {x.dueType1}
                  </Table.Cell>
                  <Table.Cell onClick={()=>cellClicked(x.ball1)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball1)}>
                    {x.dueHit1}
                  </Table.Cell>

                  <Table.Cell onClick={()=>cellClicked(x.ball2)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball2)}>
                    <Label circular color={x.dueType2 === "OD" ? "red" : (x.dueType2 === "IR" ? "green" :"orange")}  size="medium">
                    {x.ball2}
                    </Label>
                  </Table.Cell>
                  <Table.Cell onClick={()=>cellClicked(x.ball2)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball2)}>
                    {x.dueType2}
                  </Table.Cell>
                  <Table.Cell onClick={()=>cellClicked(x.ball2)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball2)}>
                    {x.dueHit2}
                  </Table.Cell>

                  <Table.Cell onClick={()=>cellClicked(x.ball3)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball3)}>
                    <Label circular color={x.dueType3 === "OD" ? "red" : (x.dueType3 === "IR" ? "green" :"orange")}  size="medium">
                    {x.ball3}
                    </Label>
                  </Table.Cell>
                  <Table.Cell onClick={()=>cellClicked(x.ball3)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball3)}>
                    {x.dueType3}
                  </Table.Cell>
                  <Table.Cell onClick={()=>cellClicked(x.ball3)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball3)}>
                    {x.dueHit3}
                  </Table.Cell>

                </Table.Row>
              );
            }))
          }   
           </Table.Body>
      </Table>
   
      </div>
    );
});