import { ErrorMessage, Form, Formik} from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button,  Card,   Label, Message } from "semantic-ui-react";
import * as Yup from 'yup';
import MyTextInputNonFluid from "../app/lib/MyTextInputNonFluid";
import { toast } from "react-toastify";
import MyMultiTextInputNonFluid from "../app/lib/MyMultiTextInputNonFluid";
import { FeedbackMessage } from "../app/models/IDayResult";
import agent from "../app/api/agent";


export default observer(function AboutForm(){

    const sendEmail = (values: FeedbackMessage, errorMetod: any)=> {
        agent.Account.sendMessage(values);
        values.email = "";
        values.message = "";
        values.phone = "";
        values.senderName = "";      
        toast.success("Message sent !");  
    };


    const validationSchema = Yup.object({
        senderName: Yup.string().required("Name is required"),
        email: Yup.string().required("Email is required"),
        message: Yup.string().required("Message is required"),
        phone: Yup.string().required("Phone is required")
    });
    const curYear = new Date().getFullYear();
    
    return (
        <>
            <Message info>
            <p><b>
            Toto Grand Master 2022 Trial Version (Built on 18 Sep 2022)<br/>
                Jointly developed by Charles Chia (4-D Master) &amp; Steve Chia (Software Specialist)<br/><br/>
            Copyright © {curYear} All Rights Reserved - TotoGrandMaster.com
            </b></p>
            </Message>

            <Card.Group>
            <Card info
                header='Charles Chia'
                meta='Sales &amp; Operations Support'
                description='HP: 93874088'
                extra='Email: charles@4dgrandmaster.com'
            />

            <Card
                header='Steve Chia'
                meta='Technical Support:'
                description='HP: 88113042'
                extra='Email: support@4dgrandmaster.com'
            />
        </Card.Group>
        <Message attached header="Or Leave your Feedback/Message here"></Message>
        <Formik initialValues={{phone: "", senderName: "", email:"", message: "", error: null}} 
                onSubmit={
                    (values,{setErrors, resetForm}) => {
                        sendEmail(values, setErrors);
                        resetForm();
                    }
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <MyTextInputNonFluid  name="senderName" placeholder="Your Name"></MyTextInputNonFluid>
                        <MyTextInputNonFluid  name="phone" placeholder="Your Phone"></MyTextInputNonFluid>
                        <MyTextInputNonFluid  name="email" placeholder="Your Email"></MyTextInputNonFluid>
                        <MyMultiTextInputNonFluid  name="message" placeholder="Message"></MyMultiTextInputNonFluid>

                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Button icon="envelope" positive content="Send" type="submit" fluid></Button>
                    </Form>
                
            )}
        </Formik>
        </>
    )
})