import { ErrorMessage, Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { Button,  Divider,  Label, Form as Form1 } from "semantic-ui-react";
import * as Yup from 'yup';
import { _HotWarmColdDraw } from "../../app/lib/CommonData";
import { _GetBonusText, _GetFilterName } from "../../app/lib/CommonFunctions";
import { DropdownField } from "../../app/lib/DropdownField";
import { MyCheckBoxField } from "../../app/lib/MyCheckBoxField";
import MyDropDown from "../../app/lib/MyDropDown";
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import { WheelingCondition } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";

export default observer(function FilterHotWrmCold(){
    function getFilterType(filterType: number){
        if (filterType === 0){
            return "HOT";
        }
        if (filterType === 1){
            return "WARM";
        }

        return "COLD";
    }
    const {userStore, modalStore} = useStore();
    
    const FilterMode = [
        {
            text: "Hot Number",
            value: 0
        },
        {
            text:"Warm Number",
            value: 1
        },
        {
            text:"Cold Number",
            value: 2
        }
    ]

    const validationSchema = Yup.object({
        noOfDraw: Yup.number().positive().integer().min(30,"No Of Draw cannot be smaller than 30 ").max(2000,"No Of Draw cannot be greater than 2000"),
        min: Yup.number().positive().integer().min(0,"Minimum cannot be smaller than 0 ").max(12,"Maximum cannot be greater than 12").test("checkGreater","Minimum must be smaller than Maximum !",(value, ctx)=> Number(ctx.parent.max) >= Number(value)),
        max: Yup.number().positive().integer().min(0,"Minimum cannot be smaller than 0 ").max(12,"Maximum cannot be greater than 12"),
    });

    const defDate = moment().add(1, "y").format("DD/MM/yyyy")
    return (
        <Formik initialValues={{noOfDraw : 30, condition: 0, min: 0, max: 3, useBonus: true,error: null}}
                onSubmit={
                    (values,{setErrors}) => {
                        const filterType = Number(values["condition"]);
                        const minValue = values["min"];
                        const maxValue = values["max"];
                        const useBonus = values["useBonus"];
                        const noOfDraw = values["noOfDraw"];

                        var filterCondition: WheelingCondition = {
                            filterType : "00",
                            filterTypeName: _GetFilterName("00"),
                            data :  getFilterType(filterType) + '|' + noOfDraw + "|" + _GetBonusText(useBonus),
                            min: Number(minValue),
                            max: Number(maxValue)
                        };

                        localStorage.setItem("FilterCondition", JSON.stringify(filterCondition));
                        modalStore.closeModal();
                    }
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <Field className="form field" 
                                placeholder="Filter Type"
                                options={FilterMode}
                                name="condition"
                                component={DropdownField}
                        />

                        <Field className="form field"
                                placeholder="Enter/Select Draw"
                                search
                                options={_HotWarmColdDraw}
                                name="noOfDraw"
                                component={DropdownField}
                        />
                        <MyTextInputNonFluid data-tooltip="Minimum value to filter" type="number" name="min" placeholder="Enter Mininum"></MyTextInputNonFluid>
                        <MyTextInputNonFluid data-tooltip="Maximum value to filter" type="number" name="max" placeholder="Enter maximum"></MyTextInputNonFluid>

                        <Field
                                name="useBonus"
                                component={MyCheckBoxField}
                        />

                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Divider />
                        <Button icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>
                    </Form>
                
            )}
        </Formik>
    )
})