import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button, Checkbox, CheckboxProps, Header, Icon, Input, InputOnChangeData, Popup, Tab, Table } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { _GetBonusText, _getWindowDimensions } from "../../app/lib/CommonFunctions";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { NumberSearchResult } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";
import ViewGraphics3D from "./ViewGraphics3D";
import ViewTotoNumberDetail from "./ViewTotoNumberDetail.";
import ViewTotoNumberSummary from "./ViewTotoNumberSummary";

export default observer(function ViewTotoNumber(){
    const panes = [
        {
          menuItem: 'Detail',
          render: () => <Tab.Pane attached={false}><ViewTotoNumberDetail></ViewTotoNumberDetail></Tab.Pane>,
        },
        {
          menuItem: 'Summary',
          render: () => <Tab.Pane attached={false}><ViewTotoNumberSummary></ViewTotoNumberSummary></Tab.Pane>,
        }
    ]


    return (
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      );
  });