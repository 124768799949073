import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button,  Label } from "semantic-ui-react";
import { useStore } from "../app/store/store";
import * as Yup from 'yup';
import MyTextInputNonFluid from "../app/lib/MyTextInputNonFluid";
import { history } from "..";


export default observer(function ChangePassword(){
    const {userStore} = useStore();

    if(!userStore.isLogged){
        history.push("/");
    }

    const validationSchema = Yup.object({
        password: Yup.string().required("Existing Password is required"),
        newPassword: Yup.string().required("New Password is required"),
        confirmPassword: Yup.string().required("Confirm Password is required").oneOf([Yup.ref('newPassword'), null], 'New & Confirm Passwords must match')
    });

    return (
        <Formik initialValues={{confirmPassword: "", password:"", newPassword:"", error: null}}
                onSubmit={
                    (values,{setErrors}) => userStore.changePassword(values).catch(err => setErrors({error: 'Unable to Login to the System'}))
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <MyTextInputNonFluid focus name="password" placeholder="Existing Password" type="password"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="newPassword" placeholder="New Password" type="password"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="confirmPassword" placeholder="Confirm Password" type="password"></MyTextInputNonFluid>

                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Button icon="sign-in" loading={isSubmitting} positive content="Sign In" type="submit" fluid></Button>
                    </Form>
                
            )}
        </Formik>
    )
})