import { observer } from "mobx-react-lite";
import React, { useState } from "react"
import { useEffect } from "react";
import {  Table, Header, Checkbox, CheckboxProps, Input, Icon, InputOnChangeData, Popup, Grid, Label } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import { _GetBonusText, _getWindowDimensions } from "../../app/lib/CommonFunctions";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { LastDrawCycleNumbers } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";

export default observer(function AnalysisByLastDrawCycle(){
    const [analysis, setAnalysis] = useState<LastDrawCycleNumbers[]>([]);
    const [loading, setLoading] = useState(true);
    const [useBonus, setUseBonus] = useState(true);
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());
    const {userStore} = useStore();
    
    useEffect(() => {
        function handleResize() {
          setWindowDimensions(_getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    


    const onBonusChanged =  (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps )=>{
      setUseBonus(data.checked!);
      setLoading(true);
    }


    useEffect(()=>{
          agent.DrawTotoResult.getLastDrawCycle(useBonus).then((response)=>{

              setAnalysis(response);
              setLoading(false);
              
          });
    },[useBonus]);


    if(!userStore.isLogged){
      return (<Header>Not Authorized</Header>)
    }

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflow:"auto",height:windowDimensions.height - 160}}>
        <Table celled unstackable selectable singleLine size="small">
        <Table.Header>
          <Table.Row>
          <Table.HeaderCell colSpan="3">
                <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged}/>
          </Table.HeaderCell>

          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
               Ball
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
               Prev
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
               Current
            </Table.HeaderCell>
         </Table.Row>
        </Table.Header>
    
        <Table.Body>

          {analysis && analysis.map((x => {
            return (
                <Table.Row key={x.ball} className="freeze-head-and-col">
                    <Table.Cell textAlign="center">
                        {x.ball}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.prevDrawCycle}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.currentDrawCycle}
                    </Table.Cell>
                </Table.Row>
              );
            }))
          }   
           </Table.Body>
      </Table>
   
      </div>
    );
});