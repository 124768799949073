import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Image, Menu, Input, InputOnChangeData, Dropdown, Button, Icon } from "semantic-ui-react";
import ChangeDevice from "../../feature/admin/ChangeDevice";
import ListUser from "../../feature/admin/ListUser";
import Registration from "../../feature/admin/Registration";
import RenewSubscription from "../../feature/admin/RenewSubscription";
import ChangePasswrord from "../../feature/ChangePasswrord";
import LoginForm from "../../feature/LoginForm";
import agent from "../api/agent";
import { useStore } from "../store/store";



function TotoNavBar() {
    var history = useHistory();
    const [data, setData] = useState("");

    const searchClicked = () => {
        const numberToSearch = Number(data);
        if (isNaN(numberToSearch)) {
            toast.error("Number must be 1-49 !");
            return;
        }

        if (numberToSearch < 0 || numberToSearch > 49) {
            toast.error("Number must be 1-49 !");
            return;
        }

        history.push("/search/" + numberToSearch + "/True");
    }

    const listUser = () => {
        modalStore.openModal(<ListUser></ListUser>, "User List", "large", null, () => { })
    }


    const changePasword = () => {
        modalStore.openModal(<ChangePasswrord></ChangePasswrord>, "Change Password", "mini", null, () => { })
    }

    const registerUser = () => {
        modalStore.openModal(<Registration></Registration>, "Register Member", "mini", null, () => { })
    }

    const changeDevice = () => {
        modalStore.openModal(<ChangeDevice></ChangeDevice>, "Change/Reset Device", "mini", null, () => { })
    }

    const renewSubscription = () => {
        modalStore.openModal(<RenewSubscription></RenewSubscription>, "Renew Subscription", "mini", null, () => { })
    }

    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {

        setData(data.value);
    };

    const { userStore: { user, isLogged, systemLogout }, modalStore } = useStore();

    const photoPath = user == null || user.image == null ? "/assets/user.png" : agent.Photo.getURL(user?.image!);

    return (
        <Menu inverted fixed="top" fluid stackable>
            <Menu.Item header as={NavLink} to="/" name="home">
                <img src="/assets/toto-logo.png" alt="Logo" style={{ marginRight: "10px" }} />
                Toto Grandmaster
            </Menu.Item>
            {isLogged ? (
                <>
                    <Dropdown item text="Analysis" name="analysis" key="analysis">
                        <Dropdown.Menu>
                            <Dropdown.Item key="0.0" as={NavLink} to="/monthlyforecast" text="Monthly Forecast" />
                            <Dropdown.Item key="0.1" as={NavLink} to="/weeklyforecast" text="Weekly Forecast" />

                            <Dropdown.Item key="1.1" as={NavLink} to="/overview" text='Toto Analytic Overview' />
                            <Dropdown.Item key="1.2" as={NavLink} to="/overview1" text='Group Analysis' />
                            {user?.groupID === 2 &&
                                <Dropdown.Item key="1.8" as={NavLink} to="/overviewbydue" text='Draw Due' />
                            }
                            <Dropdown.Item key="1.3" as={NavLink} to="/overviewbyyear" text='Frequency by year' />
                            <Dropdown.Item key="1.4" as={NavLink} to="/overviewbymonth" text='Frequency by month' />
                            <Dropdown.Item key="1.5" as={NavLink} to="/overviewbydraw" text='Frequency by periods' />
                            <Dropdown.Item key="1.6" as={NavLink} to="/combinationhitcount" text='Frequency by pick' />
                            <Dropdown.Item key="1.7" as={NavLink} to="/combinationinterval" text='Interval by pick' />

                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown item text="Trend" name="trend" key="trend">
                        <Dropdown.Menu>
                            <Dropdown.Item key="2.1" as={NavLink} to="/totoresult" text='Toto Result' />
                            <Dropdown.Item key="2.11" as={NavLink} to="/hotwarmcoldtrend" text='Hot Warm Cold' />
                            <Dropdown.Item key="2.2" as={NavLink} to="/analysisbygroup" text='Group' />
                            <Dropdown.Item key="2.3" as={NavLink} to="/analysisinterval" text='Interval' />
                            <Dropdown.Item key="2.4" as={NavLink} to="/analysisneighbour" text='Neighbour' />
                            <Dropdown.Item key="2.5" as={NavLink} to="/analysisgap" text='Gap' />

                            <Dropdown.Item key="2.8" as={NavLink} to="/analysisdrawcycle" text='Draw Cycle' />
                            {user?.groupID === 2 &&
                                <Dropdown.Item key="2.9" as={NavLink} to="/analysisByDue" text='Draw Due' />
                            }
                            {
                                user?.groupID === 2 &&
                                <Dropdown.Item key="2.12" as={NavLink} to="/overviewbygroupdue" text='Group Draw Due' />
                            }
                            {
                                user?.groupID === 2 &&
                                <Dropdown.Item key="2.15" as={NavLink} to="/overviewbygrouphotwarmcold" text='Group Hot Warm Cold' />
                            }
                            {
                                user?.groupID === 2 &&
                                <Dropdown.Item key="2.16" as={NavLink} to="/overviewbygroupskip" text='Group Interval' />
                            }
                            {
                                user?.groupID === 2 &&
                                <Dropdown.Item key="2.17" as={NavLink} to="/overviewbygroupneighbour" text='Group Triple Neighbour' /> 
                            }
                            {
                                user?.groupID === 2 &&
                                <Dropdown.Item key="2.18" as={NavLink} to="/analysispairneighbour" text='Group Pair Neighbour' />
                            }

                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown item text="Wheeling" name="wheeling" style={{ zIndex: 999999 }}>
                        <Dropdown.Menu>
                            <Dropdown.Item key="3.1" as={NavLink} to="/predefined" text='Pre-defined Wheel' />

                            <Dropdown.Item key="3.2" as={NavLink} to="/fullwheel" text='Full Wheel' />
                            <Dropdown.Item key="3.3" as={NavLink} to="/displayfullwheel/0" text='Show Full Wheel Result' />


                        </Dropdown.Menu>
                    </Dropdown>

                    {
                        (user?.username === "Charles" || user?.username === "SteveXie") &&
                        <Dropdown scrolling item text="Admin" name="Admin" direction="left" pointing="top right">
                            <Dropdown.Menu>
                                <Dropdown.Item key="4.1" onClick={registerUser} text='Register Member' />
                                <Dropdown.Item key="4.2" onClick={listUser} text='User List' />

                                <Dropdown.Item key="4.3" onClick={renewSubscription} text='Renew Subscription' />
                                <Dropdown.Item key="4.4" onClick={changeDevice} text='Change/Reset Device' />
                                <Dropdown.Item key="4.5" as={NavLink} to="/about" text='Contact Us' icon="envelope" />

                                <Dropdown.Item key="4.6" as={NavLink} to="/viewForecastMethod" text='View Forecast Result' />

                            </Dropdown.Menu>
                        </Dropdown>
                    }


                </>)
                : null
            }
            {isLogged ? (
                <Menu.Item name="search" position="right" >
                    <Input autoComplete="on" placeholder='Search Toto Numbers (01 to 49)' onChange={handleChanged} value={data}
                        icon={<Icon name='search' inverted circular link onClick={searchClicked} />}
                        onKeyPress={(event: any) => {
                            if (event === "Enter") {
                                searchClicked();
                            }
                        }}
                    />
                </Menu.Item>
            ) : (
                <Menu.Item name="login" position="right">
                    <Button icon="lock" fluid onClick={() => {
                        modalStore.openModal(<LoginForm></LoginForm>, "Login", "mini", null, () => { })
                    }} positive content="Login"></Button>
                </Menu.Item>

            )}

            {isLogged ? (
                <Menu.Item name="user" position="right" style={{ marginRight: "20px" }}>
                    <Image src={photoPath} avatar spaced="right"></Image>

                    <Dropdown pointing="top right" text={user?.displayName} item style={{ zIndex: 999999 }}>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to={'/profile'} text="My Profile" icon="user"></Dropdown.Item>
                            <Dropdown.Item onClick={changePasword} text="Change Password" icon="key"></Dropdown.Item>
                            <Dropdown.Item as={NavLink} to="/about" text='Contact Us' icon="envelope" />

                            <Dropdown.Item onClick={systemLogout} text="Logout" icon="power"></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Item>
            ) : (
                null
            )}
        </Menu>
    );
}

export default observer(TotoNavBar);

