import { ErrorMessage, Field, Form, Formik } from "formik";
import { observer} from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button,  Divider,  Label } from "semantic-ui-react";
import * as Yup from 'yup';
import agent from "../../app/api/agent";
import { _SystemBalls } from "../../app/lib/CommonData";
import { _GetBonusText, _GetFilterName, _GetMatch } from "../../app/lib/CommonFunctions";
import { DropdownField } from "../../app/lib/DropdownField";
import { MyCheckBoxField } from "../../app/lib/MyCheckBoxField";
import { MyCheckBoxMatchField } from "../../app/lib/MyCheckBoxMatchField";
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import { MyDropDownStringItems, WheelingCondition } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";


export default observer(function FilterGroupDirectPattern(){
    const {userStore, modalStore} = useStore();
    const [groups, setGroups] = useState<MyDropDownStringItems[]>([]);


    const validationSchema = Yup.object({
        group: Yup.string().required("Group is required !"),
        pattern: Yup.string().required("Pattern is required !")
    });

    useEffect(()=>{
        agent.DrawTotoResult.getGroup().then((response)=>{
            setGroups(response);
        })
    }, []);

    return (
        <Formik initialValues={{ group: "", pattern: "", useMatch: false, error: null}}
                onSubmit={
                    (values,{setErrors}) => {
                        const group = values["group"];
                        const pattern = values["pattern"];
                        const useMatch = values["useMatch"];

                        var filterCondition: WheelingCondition = {
                            filterType : "12",
                            filterTypeName: _GetFilterName("12") ,
                            data : group + "|" + pattern + "|" + _GetMatch(useMatch),
                            min: 0,
                            max: 0
                        };

                        localStorage.setItem("FilterCondition", JSON.stringify(filterCondition));
                        modalStore.closeModal();
                    }
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <Field 
                                placeholder="Enter/Select Group"
                                search
                                options={groups}
                                name="group"
                                component={DropdownField}
                        />
                        <MyTextInputNonFluid data-tooltip="Enter direct group pattern. For multi values, separated by coma"  name="pattern" placeholder="Enter direct group pattern example 2-1-1-1-1 for pick 6 First Digit. For multi values, separated by coma"></MyTextInputNonFluid>
                         
                        <Field

                                name="useMatch"
                                component={MyCheckBoxMatchField}
                        />

                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Divider />
                        <Button icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>
                    </Form>
                
            )}
        </Formik>
    )
})