import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { history } from "../..";
import {  AnalysisByDrawCycleResult, AnalysisByDrawDueResult, AnalysisByDrawResult, AnalysisByDueHistory, AnalysisByGapResult, AnalysisByGroupDueResult, AnalysisByGroupHotWarmColdResult, AnalysisByGroupNeighbourResult, AnalysisByGroupResult, AnalysisByGroupSkipResult, AnalysisByIntervalResult, AnalysisByMonthResult, AnalysisByNeighbourPairResult, AnalysisByNeighbourResult, AnalysisByNeighbourTripleResult, AnalysisByYearResult, CombinationHitCount, FeedbackMessage, FullWheelingResult, GroupNumbers, HotWarmColdSummary, LastDrawCycleNumbers, MissingDrawsCombination, MissingDrawsCombinationDetail, MyDropDownStringItems, NumberSearchGraphicResult, NumberSearchResult, NumberSearchSummaryResult, OverviewResult, PredefinedSavedData, PredefinedWatchList, TotoResult, TrendHotWarmColdNumbers, UserList, WheelingCondition, WheelingResult, WheelingTemplate, WheelNumbersDetail, WheelTemplateDetail } from "../models/IDayResult";
import { changePasswordFormValues, resetPasswordFormValues, User, userFormValues, userRegistrationValues, WeeklyForecastValues } from "../models/user";
import { store } from "../store/store";

axios.defaults.headers['Access-Control-Allow-Methods'] = "DELETE,POST,GET,OPTIONS";
axios.defaults.headers['Access-Control-Allow-Credentials'] = "true";
axios.defaults.headers['Access-Control-Allow-Origin'] = 'https://totograndmaster.com';
axios.defaults.headers["Access-Control-Allow-Headers"] = "Origin,Authorization,X-Auth-Token";
axios.defaults.withCredentials = true;
//axios.defaults.timeout = 500000;
axios.defaults.baseURL = "https://sg4api.totograndmaster.com/api";
// Current
//axios.defaults.baseURL = "https://apisg.totograndmaster.com/api";


//axios.defaults.baseURL = "https://localhost:44385/api";

axios.interceptors.request.use(config => {
    const token = store.commonStore._token;
    if (token){
        config.headers.Authorization = `bearer ${token}`
    }
   
    return config;
})

axios.interceptors.response.use(async response => {
    return response;
}, (error:AxiosError)=>{
    if(!error.response || !error.response.data){
        if (error.response && error.response?.status === 401){
            store.userStore.logout();

            return;
        }

        return Promise.reject(error);
    }
    const {data, status, config, headers} = error.response!;


    switch(status){
        case 400:
            if (typeof data === "string") {
                toast.error(data);
                return;
            }
            if(config.method === "get" && data.errors && data.errors.hasOwnProperty("id")){
                history.push("/not-found");
                return;
            }
            if(data.errors){
                const modelStateErrors = [];
                for(const key in data.errors){
                    if(data.errors[key]){
                        modelStateErrors.push(data.errors[key])
                    }
                }
                throw modelStateErrors.flat();
            }
            break;
        case 401:
            if (headers["WWW-Authenticate"].startsWith('Bearer error="invalid_token"')){
                store.userStore.logout();

                return Promise.reject(error);
            }
            //toast.error("Unauthorised !");
            break;
        case 404:
            history.push('/not-found');
            break;
        case 500:
            store.commonStore.setServerError(data);
            history.push("/server-error");
            break;
    }

    return Promise.reject(error);
});
const responseBody = <T>(response: AxiosResponse<T>) => {
    const errorMessage =  { code : 403, message : "Wrong Response" };

    if (!response || !response.data || response.data === undefined) throw errorMessage;  
    return response.data;
}

const config1 = {
    headers: {    
        'content-type': 'application/x-www-form-urlencoded',    
    }    
}

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, data: any) => axios.post<T>(url, data).then(responseBody),
    put: <T>(url: string, data: any) => axios.put<T>(url, data).then(responseBody),
    del: <T> (url: string, data: any) => axios.delete<T>(url, data).then(responseBody),
    postWithFile: <T>(url: string, data: FormData) => axios.post<T>(url, data, config1).then(responseBody),
    
}

const DrawTotoResult = {
    getExistingSavedPredefined: () => requests.get<PredefinedSavedData[]>("/DrawResult/getPredefinedSavedData"),
    getPredefinedWheeling: (systemPick: number, powerNumber: number, coverage: string, totoNumber: string) => requests.get<MyDropDownStringItems[]>(`/DrawResult/getPredefinedWheeling/${systemPick}/${powerNumber}/${coverage}/${totoNumber}`),
    getTotoNumberToWheel: (systemPick: number, powerNumber: number, coverage: string) => requests.get<MyDropDownStringItems[]>(`/DrawResult/getTotoNumberToWheel/${systemPick}/${powerNumber}/${coverage}`),
    getCoverage: (systemPick: number, powerNumber: number) => requests.get<MyDropDownStringItems[]>(`/DrawResult/GetCoverage/${systemPick}/${powerNumber}`),
    getWeeklyForecast: (selectedDate: string, selectedGroup: string) => requests.get<WeeklyForecastValues[]>(`/DrawResult/getWeeklyForecast/${selectedDate}/${selectedGroup}`),
    getWeeklyForecastDate: () => requests.get<MyDropDownStringItems[]>(`/DrawResult/getWeeklyForecastDate`),
    getMonthlyForecastDate: () => requests.get<MyDropDownStringItems[]>(`/DrawResult/getMonthlyForecastDate`),
    getMonthlyForecast: (selectedDate: string, selectedGroup: string) => requests.get<GroupNumbers[]>(`/DrawResult/getMonthlyForecast/${selectedDate}/${selectedGroup}`),
    getAnalysisByDueHistory: (noOfDraw: number,  useBonus: boolean) => requests.get<AnalysisByDueHistory[]>(`/DrawResult/getAnalysisByDueHistory/${noOfDraw}/${useBonus}`),
    getAnalysisByDrawDue:(draw1: number, draw2: number, draw3: number, draw4: number, useBonus: boolean) => requests.get<AnalysisByDrawDueResult[]>(`/DrawResult/getAnalysisByDrawDue/${draw1}/${draw2}/${draw3}/${draw4}/${useBonus}`),
    getLastDrawCycle: (useBonus: boolean) => requests.get<LastDrawCycleNumbers[]>(`/DrawResult/getLastDrawCycleNumbers/${useBonus}`), 
    savePredefinedWatchList: (data: PredefinedWatchList) => requests.post<string>(`/DrawResult/savePredefinedWatchList`, data),
    getPredefinedWatchList: (userName: string) => requests.get<MyDropDownStringItems[]>(`/DrawResult/getPredefinedWatchList/${userName}`), 
    getFullwheelResult: (wheelID: string) => requests.get<FullWheelingResult[]>(`/DrawResult/getFullwheelResult/${wheelID}`),
    submitCancellation:(id: string) => requests.post<string>(`/DrawResult/CancelWheeling`, id),
    submitWheel:(condition: WheelingTemplate) => requests.post<string>(`/DrawResult/submitWheel`, condition),
    saveTemplate:(condition: WheelingTemplate) => requests.post<string>(`/DrawResult/saveTemplate`, condition),
    getTotoResult: (page: number, useBonus: boolean)=> requests.get<TotoResult[]>(`/DrawResult/GetResult/${page}/${useBonus}`),
    getForecast: (noOfDraw: number, useBonus: boolean, group: string)=> requests.get<OverviewResult>(`/DrawResult/GetForecast/${noOfDraw}/${useBonus}/${group}`),
    getDetailedNumber: (ball: string, useBonus: boolean, isDue: boolean)=>requests.get<NumberSearchGraphicResult[]>(`/DrawResult/GetDetailedBallSkip/${ball}/${useBonus}/${isDue}`),
    getSummaryNumber: (ball: string, useBonus: boolean)=>requests.get<NumberSearchSummaryResult[]>(`/DrawResult/GetSummaryBallSkip/${ball}/${useBonus}`),
    getAnalysisByYear:(useBonus: boolean, column: string, direction: string) => requests.get<AnalysisByYearResult[]>(`/DrawResult/GetAnalysByYear/${useBonus}/${column}/${direction}`),
    getAnalysisByMonth:(useBonus: boolean, column: string, direction: string) => requests.get<AnalysisByMonthResult[]>(`/DrawResult/GetAnalysByMonth/${useBonus}/${column}/${direction}`),
    getAnalysisByDraw:(noOfDraw: number, noOfDraw1:number,noOfDraw2: number, noOfDraw3: number, useBonus: boolean, column: string, direction: string) => requests.get<AnalysisByDrawResult[]>(`/DrawResult/GetAnalysByDraw/${noOfDraw}/${noOfDraw1}/${noOfDraw2}/${noOfDraw3}/${useBonus}/${column}/${direction}`),
    getAnalysisByGroup:(noOfDraw: number, groupCode: string, useBonus: boolean) => requests.get<AnalysisByGroupResult>(`/DrawResult/GetAnalysByGroup/${noOfDraw}/${groupCode}/${useBonus}`),
    getAnalysisByGroupDue: (noOfDraw: number, dueNoOfDraw: number, groupCode: string, useBonus: boolean) => requests.get<AnalysisByGroupDueResult>(`/DrawResult/GetAnalysByGroupDue/${noOfDraw}/${dueNoOfDraw}/${groupCode}/${useBonus}`),
    getAnalysisByGroupNeighbour: (noOfDraw: number, neighbour: string, groupCode: string, useBonus: boolean) => requests.get<AnalysisByGroupNeighbourResult>(`/DrawResult/GetAnalysByGroupNeighbour/${noOfDraw}/${neighbour}/${groupCode}/${useBonus}`),
    getAnalysisByGroupSkip: (noOfDraw: number, groupCode: string, useBonus: boolean) => requests.get<AnalysisByGroupSkipResult>(`/DrawResult/GetAnalysByGroupSkip/${noOfDraw}/${groupCode}/${useBonus}`),
    getAnalysisByGroupHotWarmCold: (noOfDraw: number, hotWarmColdDraw: number, groupCode: string, useBonus: boolean) => requests.get<AnalysisByGroupHotWarmColdResult>(`/DrawResult/GetAnalysByGroupHotWarmCold/${noOfDraw}/${hotWarmColdDraw}/${groupCode}/${useBonus}`),
    getGroup: () => requests.get<MyDropDownStringItems[]>(`/DrawResult/GetGroup/ABC`),
    getAnalysisByInterval:(noOfDraw: number, useBonus: boolean) => requests.get<AnalysisByIntervalResult>(`/DrawResult/GetAnalysByInterval/${noOfDraw}/${useBonus}`),
    getAnalysisByNeighbour:(noOfDraw: number, useBonus: boolean) => requests.get<AnalysisByNeighbourResult>(`/DrawResult/GetAnalysByNeighbour/${noOfDraw}/${useBonus}`),
    getAnalysisByNeighbourPair:(noOfDraw: number, useBonus: boolean) => requests.get<AnalysisByNeighbourPairResult>(`/DrawResult/GetAnalysByNeighbourPair/${noOfDraw}/${useBonus}`),
    getAnalysisByNeighbourTriple:(noOfDraw: number, useBonus: boolean) => requests.get<AnalysisByNeighbourTripleResult>(`/DrawResult/GetAnalysByNeighbourTriple/${noOfDraw}/${useBonus}`),
    getAnalysisByGap:(noOfDraw: number, useBonus: boolean) => requests.get<AnalysisByGapResult>(`/DrawResult/GetAnalysByGap/${noOfDraw}/${useBonus}`),
    getWheelingSource:(userName: string) => requests.get<MyDropDownStringItems[]>(`/DrawResult/GetWheelingSource/${userName}`),
    getWheelOrdinaryResult:(wheelingID: string, numbers: string)=> requests.get<WheelingResult[]>(`/DrawResult/GetWheelingOrdinary/${wheelingID}/${numbers}`),
    getWheelingNumbersLength:(wheelingID: string) => requests.get<number>(`/DrawResult/GetWheelingNumbersLength/${wheelingID}`),
    getAnalysisByDrawCycle:(noOfDraw: number, useBonus: boolean) => requests.get<AnalysisByDrawCycleResult>(`/DrawResult/GetAnalysByDrawCycle/${noOfDraw}/${useBonus}`),
    getAnalysisByDrawCycleCurrent:(noOfDraw: number, useBonus: boolean) => requests.get<AnalysisByDrawCycleResult>(`/DrawResult/GetAnalysByDrawCycleCurrent/${noOfDraw}/${useBonus}`),
    getWheelTemplateDetail:(userID: string) => requests.get<WheelTemplateDetail[]>(`/DrawResult/GetWheelTemplateDetail/${userID}`),
    getHotWarmColdSummary: (useBonus: boolean) => requests.get<HotWarmColdSummary[]>(`/DrawResult/getHotWarmColdSummary/${useBonus}`),
    getHotWarmColdTrend: ( noOfDraw: number,useBonus: boolean, baseDraw: number) => requests.get<TrendHotWarmColdNumbers[]>(`/DrawResult/getHotWarmColdTrend/${noOfDraw}/${useBonus}/${baseDraw}`),
    getMissingCombination: ( noOfDraw: number,useBonus: boolean, combination: number) => requests.get<MissingDrawsCombinationDetail[]>(`/DrawResult/getMissingCombination/${noOfDraw}/${useBonus}/${combination}`),
    getCombinationHitCount: ( noOfDraw: number,useBonus: boolean, combination: number) => requests.get<CombinationHitCount>(`/DrawResult/getCombinationHitCount/${noOfDraw}/${useBonus}/${combination}`),
    getCombinationInterval: ( noOfDraw: number,useBonus: boolean, combination: number) => requests.get<CombinationHitCount>(`/DrawResult/getCombinationInterval/${noOfDraw}/${useBonus}/${combination}`),
    deleteWatchList: (watchList: PredefinedSavedData) => requests.post<string>('/DrawResult/DeletePredefinedWatchList', watchList)
}

const Account = {
    retrieveUserByEmail: (email: string) => requests.get<userRegistrationValues>(`/account/retrieveUserByEmail/${email}`),
    getUserList:(column: string, direction: string)=>requests.get<UserList[]>(`/account/getUserList/${column}/${direction}`),
    getWheelCondition: (groupID: number) => requests.get<MyDropDownStringItems[]>(`/Account/GetWheelCondition/${groupID}`),
    submitForecast:(values: WeeklyForecastValues) => requests.post<boolean>("/account/submitForecast", values),
    current: ()=> requests.get<User>("/account"),
    login: (user: userFormValues) => requests.post<User>("/account/login", user),
    resetActualPassword: (user: resetPasswordFormValues) => requests.post<User>("/account/resetActualPassword", user),
    resetPassword: (user: userFormValues) => requests.post<User>("/account/resetPassword", user),
    changePassword: (user: changePasswordFormValues) => requests.post<User>("/account/changePassword", user),
    register: (user: userRegistrationValues) => requests.post<User>("/account/RegisterMember", user),
    renewSubscription: (user: userRegistrationValues) => requests.post<User>("/account/RenewSubscription", user),
    changeDevice: (user: userRegistrationValues) => requests.post<User>("/account/ChangeDevice", user),
    refreshToken: () => requests.post<User>("/account/refreshToken", {}),
    logout: () => requests.post("/account/logout",{}),
    changeDisplayName:(user: FormData)=>requests.postWithFile<User>("/account/ChangeName",user),
    sendMessage:(feedback: FeedbackMessage)=>requests.post<void>("/account/SendFeedback",feedback),
    saveWheelingDetail: (data: WheelNumbersDetail) => requests.post<string>('/account/SaveWheelingDetail', data)
}

const Photo = {
    getPhoto: (imageName:string) => requests.get<any>(`/photo/GetPhoto/${imageName}`),
    getURL: (fileName:string) => axios.defaults.baseURL + "/Photo/GetPhoto/" + fileName,
}

const agent = {
    DrawTotoResult,
    Account,
    Photo
}

export default agent;