import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useParams } from "react-router";
import { Button, Header, Icon, Label, Table } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { _getWindowDimensions } from "../../app/lib/CommonFunctions";
import { FullWheelingResult, WheelingResult } from "../../app/models/IDayResult";

export default observer(function DisplayWheeling(){
    const [isActive, setActive] = useState(true);
    const [data, setData] = useState<FullWheelingResult[]>([]);
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(_getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    

    const headers = [{label: "Ball",key: "ball"},
    {label: "Sum", key: "total_Sum"},
    {label:"Range", key: "range"},
    {label: "Std Dev",key:"std_Dev"},
    {label:"Interval Pattern", key: "interval_Pattern"},
    {label:"Odd/Even Pattern", key: "odd_Even_Pattern"},
    {label:"Low/Mid/High", key: "high_Med_Low_Pattern"},
    {label:"Gap", key: "gap_Pattern"}]

    let {id} = useParams<{id: string}>();
    const interval = useRef<any>(null);
    
    if (!id || id === undefined){
      id = "0";
    }

    const cancelWheeling = ()=>{
      if (window.confirm("Are you sure to cancel ?")){
        agent.DrawTotoResult.submitCancellation(id);
      }
    }
    useEffect(()=>{
        if (isActive) {
            interval.current = setInterval(() => {
                agent.DrawTotoResult.getFullwheelResult((id ? id : "0") ).then((response)=>{
                    setData(response);
                    if (response.length > 0){
                        console.log("Status: " +response[0].status)

                        setActive((response[0].status === 1 ? false : true));
                    }        
                });
            }, 5000);
          } else {
            clearInterval(interval.current);
            interval.current = null;
          }
      
          return () => {
            clearInterval(interval.current);
          };
    }, [isActive, id]);


    return (
        <div style={{overflowX:"auto", height:windowDimensions.height - 160}}>
          <Table celled unstackable selectable singleLine>
          <Table.Header>
          <Table.Row key="header1">
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell colSpan="3" textAlign="center">
              <CSVLink data={data} filename="Ordinary" headers={headers}>
              <Icon name="download"></Icon>Download 
              </CSVLink>            
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="4" textAlign="center">
              <Header>FULL WHEEL RESULT</Header>
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="2"  textAlign="center">
                <span color={data.length === 1 && data[0].status === 2 ? "red": ""}>
                {"Status: " + (data.length === 0 || data[0].status === 8  ? "Processing...." : data[0].status === 1 ? "Success" : (data[0].status === 0 ? "In Queue" : "Failed"))}
                </span>
                {
                  (data.length === 0 || data[0].status === 8 || data[0].status === 0) &&
                  <Button style={{marginLeft:"10px"}} primary icon="delete" content="Cancel" onClick={()=>cancelWheeling()}></Button>
                }
            </Table.HeaderCell>
            </Table.Row>
  
            <Table.Row key="header2">
              <Table.HeaderCell textAlign="center">
              </Table.HeaderCell>
              <Table.HeaderCell colSpan="3">
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="6">
                </Table.HeaderCell>
            </Table.Row>
            <Table.Row key="header3">
              <Table.HeaderCell textAlign="center" rowSpan="2">#</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" rowSpan="2">Ball</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" rowSpan="2">Sum</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" rowSpan="2">Range</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" rowSpan="2">Std Dev</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" colSpan="4">Pattern</Table.HeaderCell>
            </Table.Row>
            <Table.Row key="header4">
              <Table.HeaderCell textAlign="center" data-tooltip="Interval 0-5|6-10|11-99">Interval</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Odd/Even</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Low/Med/High</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Gap</Table.HeaderCell>
  
            </Table.Row>
          </Table.Header>
      
          <Table.Body>
  
            {data.map(((x, index) => {
              return (
                  <Table.Row key={index} className="freeze-head-and-col">
                    <Table.Cell textAlign="center">{index + 1}</Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.ball.split(' ').map((b)=>{
                            return <Label circular size="big" className="font-number" color="blue">{b}</Label>
                        })}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.total_Sum}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.range}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.std_Dev}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.interval_Pattern}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.odd_Even_Pattern}
                    </Table.Cell>                  
                    <Table.Cell textAlign="center">
                        {x.high_Med_Low_Pattern}
                    </Table.Cell>
                   
                    <Table.Cell textAlign="center">
                        {x.gap_Pattern}
                    </Table.Cell>
                  </Table.Row>
                );
              }))
            }   
             </Table.Body>
        </Table>
     
        </div>
      );
})
