import { observer } from "mobx-react-lite";
import React, {  useState } from "react"
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { Table, Header, Checkbox, CheckboxProps, Input, InputOnChangeData, Icon, Label } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import { _GetBonusText, _getWindowDimensions } from "../../app/lib/CommonFunctions";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { AnalysisByDueHistory } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";

export default observer(function AnalysisByDueHistory(){
    
    const [loading, setLoading] = useState(true);
    const [useBonus, setUseBonus] = useState(true);
    const [analysis, setAnalysis] = useState<AnalysisByDueHistory[]>([]);
    const {userStore, modalStore} = useStore();
    const [noOfDraw, setNoOfDraw] = useState(50);

    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(_getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    

    const headers = [{label: "Draw Date)",key: "draw_Date"},
                    {label: "Not Due(100)", key: "nd"},
                    {label:"In Range(100)", key: "ir"},
                    {label: "Over Due(100)",key:"od"},
                    {label: "Not Due(500)", key: "nD1"},
                    {label:"In Range(500)", key: "iR1"},
                    {label: "Over Due(500)",key:"oD1"},
                    {label: "Not Due(1000)", key: "nD2"},
                    {label:"In Range(1000)", key: "iR2"},
                    {label: "Over Due(1000)",key:"oD2"},
                    {label: "Not Due(2000)", key: "nD3"},
                    {label:"In Range(2000)", key: "iR3"},
                    {label: "Over Due(2000)",key:"oD3"}
 
                  ]        

    const onBonusChanged =  (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps )=>{
      setUseBonus(data.checked!);
      setLoading(true);
    }

    const noOfDrawClicked = () =>{
      if (!localStorage.getItem("NoOfDraw")){
        localStorage.setItem("NoOfDraw", noOfDraw + "");
      }
      setNoOfDraw(parseInt(localStorage.getItem("NoOfDraw") +""));
      setLoading(true);
    }


    const onDrawNoChanged = (event: React.ChangeEvent<HTMLElement>, data: InputOnChangeData )=>{
     
      if (Number(data.value)){
          localStorage.setItem("NoOfDraw", data.value + "");
      }
      else{
          toast.error("No of Draw must be numbers !");
      }
  }    

    useEffect(()=>{

        agent.DrawTotoResult.getAnalysisByDueHistory(noOfDraw, useBonus).then((response)=>{
            
            setAnalysis(response);
            setLoading(false);
            
        });

        
    },[useBonus,  noOfDraw]);

    
    if(!userStore.isLogged){
      return (<Header>Not Authorized</Header>)
    }

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto",height:windowDimensions.height - 160}}>
        <Table celled unstackable selectable singleLine size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
              <Input fluid onChange={onDrawNoChanged} maxLength={4} onKeyPress={(event:any) => {
                                if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }}
                        icon={<Icon name='search' inverted circular link onClick={noOfDrawClicked} />}
                        placeholder="No of Draws" defaultValue={noOfDraw}></Input>
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="2">
                <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged}>
                </Checkbox>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center" colSpan="8">
                  <Header>DRAW DUE HISTORY
                  </Header>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center" colSpan="2">
                        <CSVLink data={analysis} filename="DrawDueHistory" headers={headers}>
                            <Icon name="download"></Icon>Download 
                        </CSVLink>         
              </Table.HeaderCell>
          </Table.Row>

          <Table.Row>
            <Table.HeaderCell textAlign="center" rowSpan="2">
                Draw Date
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan="3" className="doubleBar">
              <Header>100</Header>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan="3" className="doubleBar">
              <Header>500</Header>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan="3" className="doubleBar">
              <Header>1000</Header>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan="3">
              <Header>2000</Header>
            </Table.HeaderCell>

            </Table.Row>

          <Table.Row>
            <Table.HeaderCell textAlign="center">
              <Header color="orange">Not Due</Header>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" color="red">
              <Header color="green">In Range</Header>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="doubleBar">
              <Header color="red" >Over Due</Header>
            </Table.HeaderCell> 

            <Table.HeaderCell textAlign="center">
              <Header color="orange">Not Due</Header>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" color="red">
              <Header color="green">In Range</Header>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="doubleBar">
              <Header color="red">Over Due</Header>
            </Table.HeaderCell> 
        
            <Table.HeaderCell textAlign="center">
              <Header color="orange">Not Due</Header>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" color="red">
              <Header color="green">In Range</Header>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="doubleBar">
              <Header color="red" >Over Due</Header>
            </Table.HeaderCell> 
        
            <Table.HeaderCell textAlign="center" >
              <Header color="orange">Not Due</Header>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" color="red">
              <Header color="green">In Range</Header>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              <Header color="red">Over Due</Header>
            </Table.HeaderCell> 
        
            </Table.Row>
        
        </Table.Header>
    
        <Table.Body>

          {analysis.map(((x, index) => {
            return (
                <Table.Row key={x.draw_Date} className="freeze-head-and-col">
                  <Table.Cell textAlign="center">{x.draw_Date}</Table.Cell>
                  <Table.Cell textAlign="center">{x.nd}</Table.Cell>
                  <Table.Cell textAlign="center">{x.ir}</Table.Cell>
                  <Table.Cell textAlign="center" className="doubleBar">{x.od}</Table.Cell>

                  <Table.Cell textAlign="center" >{x.nD1}</Table.Cell>
                  <Table.Cell textAlign="center">{x.iR1}</Table.Cell>
                  <Table.Cell textAlign="center" className="doubleBar">{x.oD1}</Table.Cell>

                  <Table.Cell textAlign="center">{x.nD2}</Table.Cell>
                  <Table.Cell textAlign="center">{x.iR2}</Table.Cell>
                  <Table.Cell textAlign="center" className="doubleBar">{x.oD2}</Table.Cell>

                  <Table.Cell textAlign="center">{x.nD3}</Table.Cell>
                  <Table.Cell textAlign="center">{x.iR3}</Table.Cell>
                  <Table.Cell textAlign="center">{x.oD3}</Table.Cell>

                </Table.Row>
              );
            }))
          }   
           </Table.Body>
      </Table>
   
      </div>
    );
});