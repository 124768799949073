import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { Button,  Label } from "semantic-ui-react";
import * as Yup from 'yup';
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import { useStore } from "../../app/store/store";


export default observer(function RenewSubscription(){
    const {userStore} = useStore();

    const validationSchema = Yup.object({
        email: Yup.string().email().required("Email is required or Invalid Email"),
        expiryDate: Yup.string().required("Expired Date is required"),
    });

    const defDate = moment().add(1, "y").format("DD/MM/yyyy")
    return (
        <Formik initialValues={{id: "", remark: "", price: 0, noOfDevice:2 , userName: "", email: "", phoneNo:"", password:"", displayName: "",  expiryDate: defDate, deviceID: "", error: null}}
                onSubmit={
                    (values,{setErrors}) => userStore.renewSubscription(values).catch(err => setErrors({error: 'Unable to renew the subscription ! Error: ' + err}))
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <MyTextInputNonFluid name="email" placeholder="Email"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="expiryDate" placeholder="Expiry Date. Format: dd/MM/yyyy"></MyTextInputNonFluid>

                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Button icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>
                    </Form>
                
            )}
        </Formik>
    )
})