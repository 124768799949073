import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button, Checkbox, CheckboxProps, Header, Icon, Input, InputOnChangeData, Label, Popup, Tab, Table } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { _GetBonusText, _getWindowDimensions } from "../../app/lib/CommonFunctions";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { NumberSearchResult, NumberSearchSummaryResult } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";
import ViewGraphics3D from "./ViewGraphics3D";

export default observer(function ViewTotoNumberDetail(){
    let {id, bonus} = useParams<{id: string, bonus: string}>();
    const [data, setData]  = useState<NumberSearchSummaryResult[]>([]);
    const [enteredNumbers, setEnteredNumbers] = useState(id);
    const [numbersToSearch, setNumbersToSearch] = useState(id);
    const {modalStore} = useStore();
    const [isDue, setIsDue] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [useBonus, setUseBonus] = useState(bonus === "true" ? true : false);
    const {userStore: {user, isLogged}} = useStore();
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(_getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    

    const onBonusChanged =  (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps )=>{
        setUseBonus(data.checked!);
        setIsLoading(true);
    }

    const onDueChanged =  (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps )=>{
        setIsDue(data.checked!);
        setIsLoading(true);
    }

    const onNumberChanged = (event: React.ChangeEvent<HTMLElement>, data: InputOnChangeData )=>{
        setEnteredNumbers(data.value);
    }

    const numbersClicked = () =>{
        if (enteredNumbers !== numbersToSearch){
            setNumbersToSearch(enteredNumbers);
            setIsLoading(true);
        }
    }


    const showIntervalGraph = () => {
        localStorage.setItem("NumberBonus", (useBonus ? "1" :"0"));
        localStorage.setItem("NumberToSearch", numbersToSearch);
        localStorage.setItem("NumberToSearchData", JSON.stringify(data));
        localStorage.setItem("IsDueMode", (isDue ? "1" : "0"));
        modalStore.openModal(<ViewGraphics3D></ViewGraphics3D>, "View " + (isDue ? "Due" : "Interval") + " Graph","fullscreen", null,()=>{})
     
     }

    useEffect(()=>{
        const tempNo = parseInt(numbersToSearch).toString();

        agent.DrawTotoResult.getSummaryNumber(tempNo, useBonus).then((response) =>{
            setData(response);
            setIsLoading(false);
        });

    }, [id, bonus, useBonus, numbersToSearch, isDue]);

    if (!isLogged || !user){
        return (<Header>Not Authorized</Header>)
    }

    if (isLoading){
        return (<LoadingComponent></LoadingComponent>)
    }
    return (
    
    
        <div style={{overflow:"scroll", height:windowDimensions.height - 160}}>
        <Table celled unstackable singleLine>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        
                        <Input fluid value={enteredNumbers} onChange={onNumberChanged}  onKeyPress={(event:any) => {
                              
                              if (event.key === "Enter"){
                                numbersClicked();
                                return;
                              }
                            
                              if (!/[0-9-]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            icon={<Icon name='search' inverted circular link onClick={numbersClicked} />}                        
                            ></Input>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged}>
                        </Checkbox>
                        {user!.groupID === 8 &&
                        <Checkbox style={{marginLeft:"10px"}} toggle label={isDue ? "Due" : "Interval"} defaultChecked={isDue} onChange={onDueChanged}>
                        </Checkbox>}           

                        <Popup content={"Show " + (isDue ? "Due":"Interval") +" Graph"} trigger={
                                <Button  style={{marginLeft:"50px"}} data-position="bottom center" data-tooltip="Due" icon="chart line" onClick={()=>{
                                    showIntervalGraph();
                                    }}></Button>
                                }></Popup>
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan="4" textAlign="center">
                                   Interval Analysis Summary  
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell textAlign="center">
                        #
                    </Table.HeaderCell>

                    <Table.HeaderCell textAlign="center">
                        Last Skip
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                          # of Hit          
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
            {data.map((x, index) => {
                return(
                <Table.Row key={x.last_Skip}>
                    <Table.Cell textAlign="center">
                    {index + 1}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {
                            x.current_Skip === x.last_Skip &&
                            <Label  color='blue' ribbon>
                            Current
                          </Label>
                        }
                        {x.last_Skip}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.no_Of_Hit}
                    </Table.Cell>
                </Table.Row>);
            })}
            </Table.Body>
        </Table>            
    </div>
    
    
    )
});