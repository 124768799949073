import { Dictionary } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react"
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { LabelKeyObject } from "react-csv/components/CommonPropTypes";
import { toast } from "react-toastify";
import { Table, Header, Checkbox, CheckboxProps, Input, Icon, InputOnChangeData, Popup, Dropdown, DropdownProps, Label, Button } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import { _DueDraw } from "../../app/lib/CommonData";
import { _GetBonusText, _getWindowDimensions } from "../../app/lib/CommonFunctions";
import LoadingComponent from "../../app/lib/LoadingComponent";
import {  AnalysisByGroupDueDataResult, AnalysisByGroupDueResult, GroupNumbers, MyDropDownStringItems } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";

export default observer(function Analysis() {

    function getGroupName(groupID: string) {
        if (!groupID || groupID.length === 0) {
            return "GROUP";
        }

        var retVal = "";
        groups.map((x) => {
            if (x.value === groupID) {
                retVal = x.text;
                return false;
            }
            return true;
        });
        return retVal.toUpperCase();
    }


    const displayNumbers = (groupNumbers: GroupNumbers[], code: string) => {
        return groupNumbers.map((x) => {
            if (x.code === code) {
                return x.numbers;
            }
            return "";
        })
    }

    function getData(): AnalysisByGroupDueDataResult[] {
        if (!analysis) return [];

        return analysis!.data;
    }


    function getHeaders(): LabelKeyObject[] {
        var data: LabelKeyObject[] = [];

        data.push({
            label: "Draw Date",
            key: "drawDate"
        });

        if (analysis && analysis.data && analysis.data.length > 0) {
            analysis.groupNames.split(',').map((x, index) => {

                data.push({
                    label: x,
                    key: "col" + (index + 1).toString()
                })
                return true;
            })
        }
        return data;
    }


    const [noOfDraw, setNoOfDraw] = useState(50);
    const [loading, setLoading] = useState(true);
    const [useBonus, setUseBonus] = useState(true);
    const [analysis, setAnalysis] = useState<AnalysisByGroupDueResult>();
    const { userStore } = useStore();
    const [group, setGroup] = useState("");
    const [tempNoOfDraw, setTempNoOfDraw] = useState(noOfDraw);
    const [groups, setGroups] = useState<MyDropDownStringItems[]>([]);
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());
    const [dueDraw, setDueDraw] = useState(500)

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(_getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    //const periodDisplay = (curYear - 11).toString() + " to " + (curYear - 1).toString();
    const noOfDrawClicked = () => {
        setNoOfDraw(tempNoOfDraw);
        setLoading(true);
    }

    const onDrawNoChanged = (event: React.ChangeEvent<HTMLElement>, data: InputOnChangeData) => {

        if (Number(data.value)) {
            setTempNoOfDraw(Number(data.value));
        }
        else {
            toast.error("No of Draw must be numbers !");
        }
    }

    const onBonusChanged = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        setUseBonus(data.checked!);
        setLoading(true);
    }

    const dropDownChanged = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        setGroup(data.value + "");
    }

    const dropDownDueDrawChanged = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        setDueDraw(parseInt(data.value + ""));
    }

    var groupNamesLength = (!analysis ? 0 : analysis.groupNames.split(',').length)

    useEffect(() => {
        agent.DrawTotoResult.getGroup().then((response) => {
            setGroups(response);
            setGroup(response[0].value)
        });
    }, []);

    useEffect(() => {
        if (group.length > 0) {
            agent.DrawTotoResult.getAnalysisByGroupDue(tempNoOfDraw, dueDraw, group, useBonus).then((response) => {

                setAnalysis(response);

                setLoading(false);

            });
        }
        else {
            setAnalysis(undefined);

            setLoading(false);

        }

    }, [noOfDraw, group, useBonus, dueDraw]);


    if (!userStore.isLogged) {
        return (<Header>Not Authorized</Header>)
    }

    if (loading) {
        return (<LoadingComponent></LoadingComponent>);
    }

    if (!analysis) {
        return (<LoadingComponent></LoadingComponent>);
    }
    var colsIndex: number[] = [];

    for (var i = 0; i < (groupNamesLength); i++) {
        colsIndex.push(i + 1);
    }

    var summary: Dictionary<number> = {};

        analysis.data.map((x) => {
            Object.entries(x).map(([key, value]) => {
                colsIndex.map((y) => {
                    if (key === "col" + y) {
                        var temp = summary[key];
                        if (!temp) temp = 0;

                        summary[key] = temp + value;
                        return true;
                    }
                    else {
                        return false;
                    }
                })
                return true;
            })

            return true;
        })
       
    return (
        <div style={{ overflowX: "auto", height: windowDimensions.height - 160 }}>
            <Table celled unstackable selectable singleLine size="small">
                <Table.Header>
                    <Table.Row key="Hdr1">
                        <Table.HeaderCell>
                            <Popup style={{ zIndex: 23 }}  content="Please select group" position="top right" trigger={
                                <Dropdown fluid style={{ zIndex: 23}}
                                    selection data-moveup="false"
                                    onChange={dropDownChanged}
                                    placeholder="Select Group"
                                    options={groups}
                                    defaultValue={group}
                                    direction="right"
                                />
                            }>
                            </Popup>
                        </Table.HeaderCell>

                        <Table.HeaderCell>
                            <Input fluid onChange={onDrawNoChanged} maxLength={4} onKeyPress={(event: any) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                                icon={<Icon name='search' inverted circular link onClick={noOfDrawClicked} />}
                                placeholder="No of Draws" defaultValue={tempNoOfDraw}></Input>

                        </Table.HeaderCell>


                        <Table.HeaderCell textAlign="center" colSpan={(groupNamesLength * 3)} >
                            <Header>ANALYSIS BY {getGroupName(group)} Due</Header>
                        </Table.HeaderCell>

                        <Table.HeaderCell textAlign="center" rowSpan="3">
                            <CSVLink data={getData()} filename="AnalysisByGroupDue" headers={getHeaders()}>
                                <Icon name="download"></Icon>Download
                            </CSVLink>
                        </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row key="Hdr2">
                        <Table.HeaderCell>
                            <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged} />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <Popup content="Please select Due Draw #" position="top center" trigger={
                                <Dropdown fluid style={{ zIndex: 21 }}
                                    selection data-moveup="false"
                                    onChange={dropDownDueDrawChanged}
                                    placeholder="Select Due Draw #"
                                    options={_DueDraw}
                                    defaultValue={dueDraw}
                                    direction="right"
                                />
                            }>
                            </Popup>
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" colSpan={groupNamesLength / 3}>
                            <Label color="orange">NOT DUE</Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" colSpan={groupNamesLength / 3}>
                            <Label color="green">IN RANGE</Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" colSpan={groupNamesLength / 3}>
                            <Label color="red">OVER DUE</Label>
                        </Table.HeaderCell>

                    </Table.Row>


                    <Table.Row key="Hdr3">
                        <Table.HeaderCell>
                            Draw Date
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                        </Table.HeaderCell>

                        {
                            analysis.groupNames.split(',').map(x => {
                                if (x.startsWith("[0_ND")) {
                                    return (<Table.HeaderCell textAlign="center">
                                        <Popup hoverable content={displayNumbers(analysis.groupNumbers, x)} position="bottom center" trigger={
                                            <Label color="orange">{x.replace("0_ND_", "")}</Label>
                                        }></Popup>
                                    </Table.HeaderCell>);
                                }
                                else return null;
                            })
                        }
                        {
                            analysis.groupNames.split(',').map(x => {
                                if (x.startsWith("[1_IR")) {
                                    return (<Table.HeaderCell textAlign="center">
                                        <Popup hoverable content={displayNumbers(analysis.groupNumbers, x)} position="bottom center" trigger={
                                            <Label color="green">{x.replace("1_IR_", "")}</Label>
                                        }></Popup>
                                    </Table.HeaderCell>);
                                }
                                else return null;
                            })
                        }
                        {
                            analysis.groupNames.split(',').map(x => {
                                if (x.startsWith("[2_OD")) {
                                    return (<Table.HeaderCell textAlign="center">
                                        <Popup hoverable content={displayNumbers(analysis.groupNumbers, x)} position="bottom center" trigger={
                                            <Label color="red">{x.replace("2_OD_", "")}</Label>
                                        }></Popup>
                                    </Table.HeaderCell>);
                                }
                                else return null;
                            })
                        }
                        <Table.Cell></Table.Cell>
                    </Table.Row>
                </Table.Header>

                <Table.Header key="summary1" style={{zIndex: -1} }>
                    <Table.HeaderCell colspan="2" textAlign="center">
                    Summary
                    </Table.HeaderCell>
                    {
                        Object.keys(summary).map(key=> {

                            return <Table.HeaderCell textAlign="center">{summary[key]}</Table.HeaderCell>
                        })
                    }
                </Table.Header>
                <Table.Body>
                    {
                        analysis.data.map((x => {

                            return (
                                <Table.Row key={x.drawDate} className="freeze-head-and-col">
                                    <Table.Cell textAlign="center">
                                        {x.drawDate}
                                    </Table.Cell>
                                    <Table.Cell>

                                    </Table.Cell>
                                    {
                                        colsIndex.map(idx => {
                                            return Object.entries(x).map(([key, value]) => {
                                                if (key === "col" + idx.toString()) {
                                                    return (<Table.Cell textAlign="center">{value}</Table.Cell>);
                                                }
                                                else {
                                                    return null;
                                                }
                                            })
                                        })

                                    }
                                    <td></td>
                                </Table.Row>
                            )
                        }))
                    }
                </Table.Body>

            </Table>

        </div>
    );
});