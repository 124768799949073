import React, { useEffect } from 'react';
import './App.css';
import { Grid, Segment } from 'semantic-ui-react';
import FourDNavBar from './TotoNavBar';
import { observer } from 'mobx-react-lite';
import HomePage from '../../feature/home/HomePage';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useStore } from '../store/store';
import ModalContainer from '../Modal/ModalContainer';
import TotoNavBarMobile from './TotoNavBarMobile';
import about from '../../feature/about';
import LoginForm from '../../feature/LoginForm';
import ResetPassword from '../../feature/ResetPassword';
import NotFound from '../lib/notfound';
import servererror from '../lib/servererror';
import Forecast from '../../feature/home/Forecast';
import ViewTotoNumber from '../../feature/Search/ViewTotoNumber';
import AnalysisByYear from '../../feature/analysis/AnalysisByYear';
import AnalysisByMonth from '../../feature/analysis/AnalysisByMonth';
import AnalysisByDraw from '../../feature/analysis/AnalysisByDraw';
import AnalysisByGroup from '../../feature/analysis/AnalysisByGroup';
import AnalysisByInterval from '../../feature/analysis/AnalysisByInterval';
import AnalysisByNeighbour from '../../feature/analysis/AnalysisByNeighbour';
import AnalysisByGap from '../../feature/analysis/AnalysisByGap';
import Wheeling from '../../feature/Wheeling/Wheeling';
import AnalysisByDrawCycle from '../../feature/analysis/AnalysisByDrawCycle';
import TotoResult from '../../feature/home/TotoResult';
import Fullwheel from '../../feature/Wheeling/Fullwheel';
import ForecastVertical from '../../feature/home/ForecastVertical';
import DisplayWheeling from '../../feature/Wheeling/DisplayWheeling';
import TrendHotWarmCold from '../../feature/Trend/TrendHotWarmCold';
import MissingDrawsCombination from '../../feature/Trend/MissingDrawsCombination';
import CombinationHitCount from '../../feature/Trend/CombinationHitCount';
import CombinationInterval from '../../feature/Trend/CombinationInterval';
import AnalysisByDrawDue from '../../feature/analysis/AnalysisByDrawDue';
import AnalysisByDueHistory from '../../feature/analysis/AnalysisByDueHistory';
import AnalysisByGroupDue from '../../feature/Trend/AnalysisByGroupDue.';
import AnalysisByGroupHotWarmCold from '../../feature/Trend/AnalysisByGroupHotWarmCold.';
import AnalysisByGroupSkip from '../../feature/Trend/AnalysisByGroupSkip.';
import AnalysisByGroupNeighbour from '../../feature/Trend/AnalysisByGroupNeighbour.';
import MonthlyForecast from '../../feature/forecast/MonthlyForecast';
import WeeklyForecast from '../../feature/forecast/WeeklyForecast';
import UserProfile from '../../feature/profile/UserProfile';
import AnalysisByNeighbourPair from '../../feature/analysis/AnalysisByNeighbourPair';
import AnalysisByGroupPairNeighbour from '../../feature/Trend/AnalysisByGroupPairNeighbour.';




function App() {
    const location = useLocation();

    const { commonStore, userStore } = useStore();
    let marginTop = window.innerWidth <= 768 ? "3em" : "1em";

    if (!userStore.isLogged) {
        marginTop = "1em";
    }

    useEffect(() => {

        if (commonStore._token) {
            userStore.getUser().finally(() => {
                commonStore.setAppLoaded();
            });
        }
        else {
            commonStore.setAppLoaded();
        }
    }, [commonStore, userStore]);


    const curYear = new Date().getFullYear();

    //if(!commonStore._appLoaded){
    //return (<LoadingComponent></LoadingComponent>);
    //}

    return (
        <>
            <ModalContainer></ModalContainer>
            <ToastContainer position="bottom-right" hideProgressBar></ToastContainer>

            {window.innerWidth <= 768 ? <TotoNavBarMobile></TotoNavBarMobile> :
                <FourDNavBar></FourDNavBar>
            }

            <Grid celled>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment style={{ marginTop: marginTop, marginBottom: 20, overflow: "none" }}>
                            <Switch>
                                <Route exact path="/" component={HomePage}></Route>
                                <Route exact path="/about" component={about}></Route>
                                <Route exact path="/profile" component={UserProfile}></Route>

                                <Route exact path="/homepage" component={HomePage}></Route>
                                <Route exact path="/login" component={LoginForm}></Route>
                                <Route exact path="/totoresult" component={TotoResult}></Route>
                                <Route exact path="/fullwheel" component={Fullwheel}></Route>
                                <Route exact path="/combinationhitcount" component={CombinationHitCount}></Route>
                                <Route exact path="/combinationinterval" component={CombinationInterval}></Route>

                                <Route exact path="/hotwarmcoldtrend" component={TrendHotWarmCold}></Route>
                                <Route exact path="/missingcombination" component={MissingDrawsCombination}></Route>

                                <Route exact path='/resetPassword/:id' component={ResetPassword}></Route>
                                <Route exact path='/overviewbyyear' component={AnalysisByYear}></Route>
                                <Route exact path='/overviewbymonth' component={AnalysisByMonth}></Route>
                                <Route exact path='/overviewbydraw' component={AnalysisByDraw}></Route>
                                <Route exact path='/overviewbydue' component={AnalysisByDrawDue}></Route>
                                <Route exact path='/analysisbydue' component={AnalysisByDueHistory}></Route>

                                <Route exact path="/overview" component={Forecast}></Route>
                                <Route exact path="/overview1" component={ForecastVertical}></Route>
                                <Route exact path="/analysisbygroup" component={AnalysisByGroup}></Route>
                                <Route exact path="/analysisinterval" component={AnalysisByInterval}></Route>
                                <Route exact path="/analysisneighbour" component={AnalysisByNeighbour}></Route>
                                <Route exact path="/analysispairneighbour" component={AnalysisByGroupPairNeighbour}></Route>
                                
                                <Route exact path="/analysisgap" component={AnalysisByGap}></Route>
                                <Route exact path="/predefined" component={Wheeling}></Route>

                                <Route exact path="/analysisdrawcycle" component={AnalysisByDrawCycle}></Route>
                                <Route exact key={location.key} path={['/displayfullwheel/0', '/showfullwheel/:id']} component={DisplayWheeling}></Route>

                                <Route exact key={location.key} path={['/viewNumber', '/search/:id/:bonus']} component={ViewTotoNumber}></Route>
                                <Route exact path="/overviewbygroupdue" component={AnalysisByGroupDue}></Route>
                                <Route exact path="/overviewbygrouphotwarmcold" component={AnalysisByGroupHotWarmCold}></Route>
                                <Route exact path="/overviewbygroupskip" component={AnalysisByGroupSkip}></Route>
                                <Route exact path="/overviewbygroupneighbour" component={AnalysisByGroupNeighbour}></Route>
                                <Route exact path="/monthlyforecast" component={MonthlyForecast}></Route>
                                <Route exact path="/weeklyforecast" component={WeeklyForecast}></Route>

                                <Route path="/server-error" component={servererror}></Route>
                                <Route component={NotFound}></Route>
                            </Switch>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <footer>
                <Segment textAlign='center' color='blue'>
                    Copyright © {curYear} All Rights Reserved - TotoGrandMaster.com
                </Segment>

            </footer>
        </>
    );
}

export default observer(App);