import { makeAutoObservable, reaction } from "mobx";
import { ServerError } from "../models/servererror";

export default class CommonStore{
    _error: ServerError | null = null;
    _token: string | null = window.localStorage.getItem("totogm_jwt_new");
    _appLoaded = false;

    constructor(){
        makeAutoObservable(this);

        reaction(
            ()=> this._token,
            token => {
                if(token){
                    window.localStorage.setItem("totogm_jwt_new", token);
                }
                else{
                    window.localStorage.removeItem("totogm_jwt_new");
                }
            }
        )
    }

    setServerError = (error: ServerError) => {
        this._error = error;
    }

    setToken = (token: string | null) => {
        this._token = token;
    }

    setAppLoaded = () =>{
        this._appLoaded = true;
    }
}