import { ErrorMessage, Field, Form, Formik } from "formik";
import { observer} from "mobx-react-lite";
import React, { useState } from "react";
import { Button,  Divider,  Label } from "semantic-ui-react";
import * as Yup from 'yup';
import { _SystemBalls } from "../../app/lib/CommonData";
import { _GetBonusText, _GetFilterName } from "../../app/lib/CommonFunctions";
import { DropdownField } from "../../app/lib/DropdownField";
import { MyCheckBoxField } from "../../app/lib/MyCheckBoxField";
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import { WheelingCondition } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";


export default observer(function FilterPairNeighbour(){
    const {userStore, modalStore} = useStore();
    

    const validationSchema = Yup.object({
        interval: Yup.string().required("Interval is required !").test("checkInterval","Invalid Interval. Use - for range or coma for one interval ! ", (value, ctx) => {
            if (!value) return false;

            const allIntervals = value!.split(',');
            let ok = true;    
            allIntervals.map((y) => {
                const intervals = y.split('-');

                if (intervals.length > 2){
                    ok = false;
                    return false;
                }
                if (intervals.length === 2){
                    const fromInterval = Number(intervals[0]);
                    const toInterval = Number(intervals[1]);

                    if (isNaN(fromInterval) || isNaN(toInterval)){
                        ok = false;
                        return false;
                    }

                    if(fromInterval > toInterval){
                        ok = false;
                        return false;
                    }
                    return true;
                }

                const interval = Number(y);

                if (isNaN(interval)){
                    ok = false;
                    return false;
                }
                return true;
            })

            return ok;
        }),
        min: Yup.number().required("Minimum is required !").positive().integer().min(0,"Minimum cannot be smaller than 0 ").max(9999,"Maximum cannot be greater than 9999").test("checkGreater","Minimum must be smaller than Maximum !",(value, ctx)=> Number(ctx.parent.max) >= Number(value)),
        max: Yup.number().required("Maximum is required !").positive().integer().min(0,"Minimum cannot be smaller than 0 ").max(9999,"Maximum cannot be greater than 9999"),
    });

    return (
        <Formik initialValues={{ combination: 5, interval: "", min: 0, max: 1, useBonus : true,  error: null}}
                onSubmit={
                    (values,{setErrors}) => {
                        const combination = values["combination"];
                        const interval = values["interval"];
                        const minValue = values["min"];
                        const maxValue = values["max"];
                        const useBonus = values["useBonus"];

                        var filterCondition: WheelingCondition = {
                            filterType : "10",
                            filterTypeName: _GetFilterName("10"),
                            data : combination + "|" + interval + "|" + _GetBonusText(useBonus) ,
                            min: Number(minValue),
                            max: Number(maxValue)
                        };

                        localStorage.setItem("FilterCondition", JSON.stringify(filterCondition));
                        modalStore.closeModal();
                    }
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <Field 
                                placeholder="Ball Combination Filter"
                                options={_SystemBalls}
                                name="combination"
                                component={DropdownField}
                        />
                        <MyTextInputNonFluid data-tooltip="Interval for the ball combination"  name="interval" placeholder="Enter Interval"></MyTextInputNonFluid>
                         
                        <MyTextInputNonFluid data-tooltip="Minimum value to filter" type="number" name="min" placeholder="Enter Mininum"></MyTextInputNonFluid>
                        <MyTextInputNonFluid data-tooltip="Maximum value to filter" type="number" name="max" placeholder="Enter maximum"></MyTextInputNonFluid>
                        <Field
                                name="useBonus"
                                component={MyCheckBoxField}
                        />

                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Divider />
                        <Button icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>
                    </Form>
                
            )}
        </Formik>
    )
})