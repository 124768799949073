import { ErrorMessage,  Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button,  Divider,  Label } from "semantic-ui-react";
import * as Yup from 'yup';
import { _GetBonusText, _GetFilterName } from "../../app/lib/CommonFunctions";
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import { WheelingCondition } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";


export default observer(function FilterNumber(){
    const {userStore, modalStore} = useStore();
    

    const validationSchema = Yup.object({
        numbersToEnter: Yup.string().required("Numbers is required !").test("checkInterval","Invalid Numbers. Use - for range or coma for one number (1-49) ! ", (value, ctx) => {
            if (!value) return false;

            const allNumbers = value!.split(',');
            let ok = true;    
            allNumbers.map((y) => {
                const numbers = y.split('-');

                if (numbers.length > 2){
                    ok = false;
                    return false;
                }
                if (numbers.length === 2){
                    const fromNumber = Number(numbers[0]);
                    const toNumber = Number(numbers[1]);

                    if (isNaN(fromNumber) || isNaN(toNumber)){
                        ok = false;
                        return false;
                    }

                    if (fromNumber < 0 || fromNumber > 49){
                        ok = false;
                        return false;
                    }

                    if (toNumber < 0 || toNumber > 49){
                        ok = false;
                        return false;
                    }

                    if(fromNumber > toNumber){
                        ok = false;
                        return false;
                    }
                    return true;
                }

                const eachNumber = Number(y);

                if (isNaN(eachNumber)){
                    ok = false;
                    return false;
                }

                if (eachNumber < 0 || eachNumber > 49){
                    ok = false;
                    return false;
                }
                return true;
            })

            return ok;
        }),
        min: Yup.number().required("Minimum is required !").positive().integer().min(0,"Minimum cannot be smaller than 0 ").max(8,"Maximum cannot be greater than 8").test("checkGreater","Minimum must be smaller than Maximum !",(value, ctx)=> Number(ctx.parent.max) >= Number(value)),
        max: Yup.number().required("Maximum is required !").positive().integer().min(0,"Minimum cannot be smaller than 0 ").max(8,"Maximum cannot be greater than 8"),
    });

    return (
        <Formik initialValues={{ numbersToEnter: "", min: 0, max: 3, error: null}}
                onSubmit={
                    (values,{setErrors}) => {
                        const numbersToEnter = values["numbersToEnter"]
                        const minValue = values["min"];
                        const maxValue = values["max"];

                        var filterCondition: WheelingCondition = {
                            filterType : "03",
                            filterTypeName: _GetFilterName("03"),
                            data : numbersToEnter ,
                            min: Number(minValue),
                            max: Number(maxValue)
                        };

                        localStorage.setItem("FilterCondition", JSON.stringify(filterCondition));
                        modalStore.closeModal();
                    }
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <MyTextInputNonFluid data-tooltip="Enter number list to filter" name="numbersToEnter" placeholder="Enter 01 to 49 separated by - or coma"></MyTextInputNonFluid>
                        <MyTextInputNonFluid data-tooltip="Minimum value to filter" type="number" name="min" placeholder="Enter Mininum"></MyTextInputNonFluid>
                        <MyTextInputNonFluid data-tooltip="Maximum value to filter" type="number" name="max" placeholder="Enter maximum"></MyTextInputNonFluid>

                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Divider />
                        <Button icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>
                    </Form>
                
            )}
        </Formik>
    )
})