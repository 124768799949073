import { ErrorMessage, Field, Form, Formik } from "formik";
import { observer} from "mobx-react-lite";
import React, { useState } from "react";
import { Button,  Divider,  Label } from "semantic-ui-react";
import * as Yup from 'yup';
import { _SystemBalls } from "../../app/lib/CommonData";
import { _GetBonusText, _GetFilterName } from "../../app/lib/CommonFunctions";
import { DropdownField } from "../../app/lib/DropdownField";
import { MyCheckBoxField } from "../../app/lib/MyCheckBoxField";
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import { WheelingCondition } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";


export default observer(function ReduceSameNumbers(){
    const {userStore, modalStore} = useStore();
    

    const validationSchema = Yup.object({
        totalRecord:Yup.number().required("Total Combination Record Match is required !").integer().min(0,"Total Record minimum is 0 ").max(99,"Maximum total match is 99"), 
        max: Yup.number().required("Maximum is required !").positive().integer().min(1,"Minimum cannot be smaller than 0 ").max(12,"Maximum cannot be greater than 12"),
    });

    return (
        <Formik initialValues={{ totalRecord: 0, combination: 5, max: 1,   error: null}}
                onSubmit={
                    (values,{setErrors}) => {
                        const combination = values["combination"];
                        const minValue = values["totalRecord"];
                        const maxValue = values["max"];

                        var filterCondition: WheelingCondition = {
                            filterType : "99",
                            filterTypeName: _GetFilterName("99"),
                            data : combination + "",
                            min: Number(minValue),
                            max: Number(maxValue)
                        };

                        localStorage.setItem("FilterCondition", JSON.stringify(filterCondition));
                        modalStore.closeModal();
                    }
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <Field 
                                placeholder="Ball Combination Filter"
                                options={_SystemBalls}
                                name="combination"
                                component={DropdownField}
                        />
                        <MyTextInputNonFluid data-tooltip="Maximum combination ticket if match" type="number" name="totalRecord" placeholder="Maximum combination tickets if match"></MyTextInputNonFluid>
                        
                        <MyTextInputNonFluid data-tooltip="Maximum value to filter" type="number" name="max" placeholder="Enter maximum"></MyTextInputNonFluid>
                      
                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Divider />
                        <Button icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>
                    </Form>
                
            )}
        </Formik>
    )
})