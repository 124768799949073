import { ErrorMessage, Form, Formik, useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect } from "react";
import { Button,  Label } from "semantic-ui-react";
import * as Yup from 'yup';
import agent from "../../app/api/agent";
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import { useStore } from "../../app/store/store";


export default observer(function Registration(){
    const {userStore} = useStore();
    const retrieveData = (email: string, setFieldValue: any) =>{
        setFieldValue("id", "");
        setFieldValue("userName","");
        setFieldValue("price", 0);
        setFieldValue("displayName", "");
        setFieldValue("expiryDate", "");
        setFieldValue("noOfDevice", 2);
        setFieldValue("remark", "");        
        setFieldValue("password","");
        
        agent.Account.retrieveUserByEmail(email).then((response)=> {
            setFieldValue("id", response.id);
            setFieldValue("userName", response.userName);
            setFieldValue("price", response.price);
            setFieldValue("displayName", response.displayName);
            setFieldValue("expiryDate", response.expiryDate);
            setFieldValue("noOfDevice", response.noOfDevice);
            setFieldValue("remark", response.remark);
            setFieldValue("password","Cannot Change");
        });
    };
    const validationSchema = Yup.object({
        price: Yup.number().required("Price is required"),
        userName: Yup.string().required("User Name is required"), 
        email: Yup.string().email().required("Email is required or Invalid Email"),
        password: Yup.string().required("Password is required"),
        displayName:  Yup.string().required("Display Name is required"),
        expiryDate: Yup.string().required("Expired Date is required"),
        noOfDevice: Yup.number().required("Number Of Device is required").min(1, "Minimum is 1 device !")
    });

    const defDate = moment().add(1,"y").format("DD/MM/yyyy")
    return (
        <Formik enableReinitialize={true}
                initialValues={{id: "", remark: "", price: 0, noOfDevice:2 , phoneNo: "", userName: "", email: "", password:"", displayName: "",  expiryDate: defDate, deviceID: "", error: null}}
                onSubmit={
                    (values,{setErrors}) => userStore.register(values).catch(err => setErrors({error: 'Unable to register to the System'}))
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors, setFieldValue, values}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <MyTextInputNonFluid name="email" placeholder="Email"></MyTextInputNonFluid>

                        <Button style={{marginTop:"10px"}} icon="sync" loading={isSubmitting} positive content="Retrieve"  fluid onClick={()=>{
                            retrieveData(values["email"], setFieldValue);
                        }}></Button>

                        <MyTextInputNonFluid name="userName" placeholder="User Name"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="displayName" placeholder="Display Name"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="phoneNo" placeholder="Phone Number of Member"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="password" placeholder="Password"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="expiryDate" placeholder="Expiry Date. Format: dd/MM/yyyy"></MyTextInputNonFluid>
                        <MyTextInputNonFluid maxLength={2} type="number" name="noOfDevice" placeholder="Limit to Number ot Device"></MyTextInputNonFluid>
                        <MyTextInputNonFluid maxLength={10} type="number" name="price" placeholder="Subscription Price"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="remark" placeholder="Remark"></MyTextInputNonFluid>
                        
                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Button icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>

                    </Form>
                
            )}
        </Formik>
    )
})