import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Button, Header, Table } from "semantic-ui-react";
import { _RandomNumberInRange } from "../../app/lib/CommonFunctions";
import { useStore } from "../../app/store/store";

export default observer(function NumberSelection() {

    const [data, setData] = useState<string[]>([]);
    const { modalStore } = useStore();

    var sortedData = [...data];
    sortedData.sort();

    var requiredNumbersLength = parseInt(localStorage.getItem("NumberLength")!);

    useEffect(() => {
        const existingNumbers = localStorage.getItem("existingNumbers");

        if (existingNumbers && existingNumbers.trim().length > 0) {
            setData(existingNumbers.split(','));
        }
        else {
            setData([]);
        }
    }, []);

    const isActive = (activeNumber: string) => {

        var foundNumber = data.filter(x => parseInt(x) === parseInt(activeNumber));

        return (foundNumber.length > 0 ? true : false);
    }
    const CreateNumberInput = (index: number) => {
        var localTemp = [];
        for (var i = 0; i < 7; i++) {
            localTemp.push(i + 1 + (index - 1));
        }
        return (
            <Table.Row key={index}>
                {
                    localTemp.map((x, idx) => {
                        return (
                            <Table.Cell textAlign="center">
                                <Button circular size="large" toggle active={isActive(x.toString())} onClick={() => toggleButton(x)}>
                                    {x.toString().length === 1 ? "0" + x.toString() : x.toString()}
                                </Button>
                            </Table.Cell>
                        )
                    })
                }
            </Table.Row>
        )
    }

    const randomSelection = () => {
        var tempData: string[] = [];

        for (var i = 1; i <= requiredNumbersLength; i++) {
            while (true) {
                const rndNumber = _RandomNumberInRange(1, 49);
                const rndString = rndNumber.toString().length < 2 ? "0" + rndNumber.toString() : rndNumber.toString();
                const index = tempData.findIndex((x) => x === rndString);
                if (index < 0) {
                    tempData.push(rndString);
                    break;
                }
            }
        }
        setData(tempData);
    }

    const toggleButton = (totoNumber: number) => {

        var tempData: string[] = [...data];
        const foundIndex = tempData.findIndex(x => parseInt(x) === totoNumber);

        if (foundIndex < 0) {
            tempData.push(totoNumber.toString().length === 1 ? "0" + totoNumber.toString() : totoNumber.toString());
        }
        else {
            tempData.splice(foundIndex, 1);
        }

        setData(tempData);
    }

    const selectNumbers = () => {

        const outstanding = requiredNumbersLength - data.length;
        if (outstanding < 0) {
            window.alert("You have selected more than required !");
            return;
        }

        if (outstanding !== 0) {
            if (!window.confirm("Are you sure to select ? Not all numbers selected yet.")) {
                return;
            }
        }
        var tempData = data.join(", ");
        if (tempData.startsWith(", ")) {
            tempData = tempData.substring(2);
        }
        localStorage.setItem("existingNumbers", tempData);
        modalStore.closeModal();
    }

    return (
        <div style={{ overflowX: "auto" }}>
            <Table unstackable selectable singleLine size="small">
                <Table.Header>
                    <Table.Row key="line1">
                        <Table.Cell colSpan="5">
                            <Header as="h3">
                                Selection:
                                {data.join(", ")}
                            </Header>
                        </Table.Cell>
                        <Table.Cell colSpan="2" textAlign="right">
                            <Header as="h3">
                                # left to select:
                                {requiredNumbersLength - data.length}
                            </Header>
                        </Table.Cell>
                    </Table.Row>

                    
                </Table.Header>
                <Table.Body>
                    {
                        CreateNumberInput(1)
                    }
                    {
                        CreateNumberInput(8)
                    }
                    {
                        CreateNumberInput(15)
                    }
                    {
                        CreateNumberInput(22)
                    }
                    {
                        CreateNumberInput(29)
                    }
                    {
                        CreateNumberInput(36)
                    }
                    {
                        CreateNumberInput(43)
                    }
                </Table.Body>
                <Table.Footer>
                    <Table.Row style={{ "margin-top": "20px" }} >
                        <Table.Cell colSpan="7" textAlign="center">
                            <Button style={{ "float": "left" }} secondary size="large" onClick={() => setData([])}>
                                Clear
                            </Button>

                            <Button primary size="large" onClick={() => selectNumbers()}>
                                Select
                            </Button>

                            <Button positive style={{ "float": "right" }} secondary size="large" onClick={() => randomSelection()}>
                                Smart Pick
                            </Button>

                        </Table.Cell>
                    </Table.Row>
                    <Table.Row key="line2">
                        <Table.Cell colSpan="7">
                            <Header as="h3">
                                Sorted Selection:
                                {sortedData.join(", ")}
                            </Header>
                        </Table.Cell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>
    );

})