import { observer } from "mobx-react-lite";
import React, { useEffect, useState }  from "react";
import { toast } from "react-toastify";
import { CartesianGrid, LabelList, Legend, Line, LineChart,  ReferenceLine,  Tooltip, XAxis, YAxis } from "recharts";
import { Props } from "recharts/types/component/DefaultLegendContent";
import {Checkbox, CheckboxProps, Divider, Grid, Header, Icon, Input, InputOnChangeData, Label, Segment } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { _GetBonusText } from "../../app/lib/CommonFunctions";
import {  NumberSearchGraphicResult } from "../../app/models/IDayResult";



export default observer( function ViewGraphics3D(){

  const [numberToSearch, setNumberToSearch]  = useState(localStorage.getItem("NumberToSearch") + "");
  const [numberToSearchData, setNumberToSearchData] = useState<NumberSearchGraphicResult[]>([]);
  const [enteredNumbers, setEnteredNumbers] = useState(localStorage.getItem("NumberToSearch") + "");
  const [useBonus, setUseBonus] = useState(localStorage.getItem("NumberBonus") === "1" ? true:false);

  const isDue: boolean = localStorage.getItem("IsDueMode") === "1" ? true : false;
  const onBonusChanged =  (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps )=>{
    setUseBonus(data.checked!);
  }
  var minRange = 0;
  var maxRange = 0;
  var average = 0;
  var belowMin = 0;
  var belowAvg = 0;
  var aboveAvg = 0;
  var aboveMax = 0;


  const onNumberChanged = (event: React.ChangeEvent<HTMLElement>, data: InputOnChangeData )=>{
    setEnteredNumbers(data.value);
  }

  const numbersClicked = () =>{
    const newNumber = Number(enteredNumbers);

    if (isNaN(newNumber) || newNumber < 0 || newNumber > 49){
      toast.error("Value: " + enteredNumbers + " is not allowed. Enter 0 - 49 !");
      return;
    }

    setNumberToSearch(newNumber + "");
  } 
  
  
  

  if (numberToSearchData.length > 0){
      minRange = numberToSearchData[0].min_Range;
      maxRange = numberToSearchData[0].max_Range;
      average = Math.round((maxRange + minRange) / 2);

      numberToSearchData.map((x) => {
          if (x.last_Skip < minRange){
              belowMin++;
          }
          else{
            if (x.last_Skip < average){
                belowAvg++;
            }
            else{
              if (x.last_Skip <= maxRange){
                  aboveAvg++;
              }
              else{
                  aboveMax++;
              }
            }
          }
          return true;
      })
  }
  
  
  const CustomTooltip = ({ active, payload, label } : any) => {
    if (active && payload && payload.length) {

      let interval = 0;
      let index = 0;

      numberToSearchData.map((x, pos)=>{
        if (x.draw_Date === label){
          index = pos;
          interval = x.last_Skip;
          return false;
        }
        return true;
      });

      if (index === 0){
        return (
          <Segment>
            {"Date : " + label}<br/>
            {(isDue ? "Current Due : " : "Current Interval: ") + interval}<br/><br/>
          </Segment>
        );
  


      }
      return (
        <Segment>
          {"Date : " + label}<br/>
          {(isDue ? "Due : ": "Interval: ") + interval}<br/>
        </Segment>
      );
    }
  
    return null;
  };

/*const CustomizeLabel = (props: any) =>{
  const { x, y, stroke, value } = props;

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
        {value}
      </text>
    ); 
}*/

const getHotWarmCold = (noOfDraw: number)=>{
  if (noOfDraw === 30){
    if (numberToSearchData[0].hot_30){
      return (<Label color='red'>30 Draws[Hot]</Label>);
    }

    if (numberToSearchData[0].warm_30){
      return (<Label color='yellow'>30 Draws[Warm]</Label>);
    }

    if (numberToSearchData[0].warm_30){
      return <Label color='yellow'>30 Draws[Cold]</Label>;
    }

  }

  if (noOfDraw === 60){
    if (numberToSearchData[0].hot_60){
      return (<Label color='red'>60 Draws[Hot]</Label>);
    }

    if (numberToSearchData[0].warm_60){
      return (<Label color='yellow'>60 Draws[Warm]</Label>);
    }

    if (numberToSearchData[0].warm_60){
      return <Label color='yellow'>60 Draws[Cold]</Label>;
    }

  }

  if (noOfDraw === 90){
    if (numberToSearchData[0].hot_90){
      return (<Label color='red'>90 Draws[Hot]</Label>);
    }

    if (numberToSearchData[0].warm_90){
      return (<Label color='yellow'>90 Draws[Warm]</Label>);
    }

    if (numberToSearchData[0].warm_90){
      return <Label color='yellow'>90 Draws[Cold]</Label>;
    }

  }

  if (noOfDraw === 120){
    if (numberToSearchData[0].hot_120){
      return (<Label color='red'>120 Draws[Hot]</Label>);
    }

    if (numberToSearchData[0].warm_120){
      return (<Label color='yellow'>120 Draws[Warm]</Label>);
    }

    if (numberToSearchData[0].warm_120){
      return <Label color='yellow'>120 Draws[Cold]</Label>;
    }

  }

  return "";
}

const maxInterval = numberToSearchData.length > 0 ? Math.max.apply(Math, numberToSearchData.map(function(o) { return o.last_Skip; })):0;



  const CustomizedLabel = (props: any) => {
    const { x, y, stroke, value } = props;
  
    return (
      <text x={x} y={y} dy={-10} fill={stroke} fontSize={10} textAnchor="middle">
        {value}
      </text>
    );
  };
  
  
  const renderLegend = (prop: Props) => {
    return (
      <ul>
      </ul>
    );
  }

    const RenderDefaultLabel  = ({ viewBox } : any) => {
      const state = numberToSearchData[0].last_Skip < numberToSearchData[0].min_Range ? "Not Due(Stay)" :
        numberToSearchData[0].last_Skip > numberToSearchData[0].max_Range ? "Overdue(Hold)": "InRange(Buy)";
      const colorSpan = numberToSearchData[0].last_Skip < numberToSearchData[0].min_Range ? "orange" :
        numberToSearchData[0].last_Skip > numberToSearchData[0].max_Range ? "red": "green";

      return (

      <g>
        
        <foreignObject x={viewBox.x} y={0} width={viewBox.width} height={150}>
              <div>Toto Number {isDue ? "Due" : "Interval"} Graph<br/>
                    Number Analysed: <b>{numberToSearch}</b><br/>
                    Current {isDue ? "Due" : "Interval"} : {numberToSearchData[0].last_Skip}&nbsp;&nbsp;
                    [<span style={{color:colorSpan}}>State: {state}
                    </span>]
                    <br/>No. Hits - <span style={{color:"orange"}}>ND: &lt;{minRange}={belowMin}</span>
                    <span style={{color:"green", marginLeft: "20px", marginRight:"5px"}}>IR1: {minRange} to {average - 1} = {belowAvg}
                    ,</span>
                    <span style={{color:"green", marginLeft: "5px", marginRight:"20px"}}>IR2: {average} to {maxRange} = {aboveAvg}
                    </span>
                    <span style={{color:"red", marginLeft: "20px", marginRight:"20px"}}>OD: &gt; {maxRange} = {aboveMax}</span><br/>
                    <span style={{color: "blue"}}>Awaken: {numberToSearchData[0].awaken}</span>
                    <br/>
                    {
                        getHotWarmCold(30)
                    }
              </div>
          </foreignObject>
      </g>);
    }

    const CustomizedDot = (props: any) => {
      const { cx, cy,  payload } = props;
      if (payload.draw_Date.startsWith("Current")){
        return (<circle cx={cx} r="6" cy={cy} fill="blue">
          <animate
          attributeName="r"
          from="5"
          to="15"
          dur="1.5s"
          begin="0s"
          repeatCount="indefinite" />
          </circle>
        );
      }
  
      return (
          
          <circle cx={cx} r="6" cy={cy} fill={payload.bonus_Number ? "black" : "lightgreen"}>
          </circle>
      );
    };
  
    const ActiveDot = (props: any) => {
      const {cx, cy} = props;
      
      return (
          <circle cx={cx} r="4" cy={cy} fill="blue">
          </circle>
      );
    };
  
  
   
    useEffect(()=>{
      if (!numberToSearch || numberToSearch.length === 0){
        return;
      }
      const tempNo = parseInt(numberToSearch).toString();
      const isDue: boolean = localStorage.getItem("IsDueMode") === "1" ? true : false;

      agent.DrawTotoResult.getDetailedNumber(tempNo, useBonus, isDue).then((response) =>{
          setNumberToSearchData(response);
      });

  }, [numberToSearch, useBonus]);

  

    if (numberToSearchData.length === 0) return (<Header>No Data</Header>);
    return (
        <div style={{overflowX:"auto", textAlign:"center", height:"100%", width:"100%"}}>
        <Grid>

          <Grid.Column width={8}>
              <Input fluid value={enteredNumbers} onChange={onNumberChanged}  onKeyPress={(event:any) => {
                                if (event.key === "Enter"){
                                  numbersClicked();
                                  return;
                                }
                                if (!/[0-9-]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            icon={<Icon name='search' inverted circular link onClick={numbersClicked} />}                        
                            ></Input>
          </Grid.Column>
          <Grid.Column width={8}>
                        <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged}>
                        </Checkbox>

          </Grid.Column>
        </Grid>
        <Divider hidden />
        <LineChart
          width={1200}
          height={600}
          data={numberToSearchData}
          margin={{
            top: 150,
            right: 10,
            left: 10,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="draw_Date">
          <Label value="Draw Date" offset={-10} position="insideBottom" />
          </XAxis>
          <YAxis label={{value: "Due",angle: -90, position: 'insideLeft', textAnchor: 'middle' }}/>
          <Tooltip content={<CustomTooltip/>}  />
          <Legend content={renderLegend}/>
          
          <ReferenceLine y={maxRange} label={"Max [" + maxRange +"]"} stroke="red" strokeDasharray="3 3" />
          <ReferenceLine y={average} label={"Avg [" + average +"]"} stroke="black" strokeDasharray="3 3" />

          <ReferenceLine y={minRange} label={"Min [" + minRange +"]"} stroke="green" strokeDasharray="3 3">
          </ReferenceLine>

          <Line  legendType="circle"  type="monotone" dataKey="last_Skip" stroke="#964B00"  dot={<CustomizedDot />} activeDot={<ActiveDot/>} >
          <LabelList content={<CustomizedLabel />} />
          </Line>
          
          <ReferenceLine y={maxInterval} label={<RenderDefaultLabel/>} />    
        </LineChart>
        </div>
    )
})