import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Button, Header, Table } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { PredefinedSavedData, PredefinedWatchList } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";

export default observer(function LoadExistingData() {

    const [data, setData] = useState<PredefinedSavedData[]>([]);
    const { modalStore } = useStore();
    const [needReload, setNeedReload] = useState(true);

    localStorage.setItem("retVal", "");

    const doSelection = (index:number) => {
        const selected = data[index];
        localStorage.setItem("retVal", JSON.stringify(selected));

        modalStore.closeModal();
    }

    const doDelete = (index: number) => {
        if (!window.confirm("Are you sure to delete ?")) {
            return;
        }
        const selected = data[index];

        agent.DrawTotoResult.deleteWatchList(selected).then((response) => {
            setNeedReload(true);
        });

    }

    useEffect(() => {
        if (needReload) {
            agent.DrawTotoResult.getExistingSavedPredefined().then((response) => {
                setData(response);
                setNeedReload(false);
            });
        }
    }, [needReload]);

    return (
        <div style={{ overflowX: "auto" }}>
            <Table unstackable selectable singleLine size="small">
                <Table.Header>

                    <Table.Row key="line2">
                        <Table.Cell>
                        </Table.Cell>
                        <Table.Cell>
                            <Header as="h3">
                                Wheel Name
                            </Header>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Header as="h3">
                                System
                            </Header>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Header as="h3">
                                Min Guarantee
                            </Header>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Header as="h3">
                                Power Number
                            </Header>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Header as="h3">
                                Total Numbers
                            </Header>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Header as="h3">
                                Selected Numbers
                            </Header>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Header as="h3">
                                Selected Keys
                            </Header>
                        </Table.Cell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((x, index) => {
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell>
                                        <Button data-tooltip="Select the record" icon="check" primary circular size="small" onClick={() => doSelection(index)}></Button>
                                        <Button data-tooltip="Delete saved record" color="red" icon="trash alternate" circular size="small" onClick={() => doDelete(index)}></Button>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {x.wheelName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {x.systemPick}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {x.coverage}
                                    </Table.Cell>
                                    <Table.Cell textAlign="right">
                                        {x.powerNumber}
                                    </Table.Cell>
                                    <Table.Cell textAlign="right">
                                        {x.numberSelection}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {x.selectedNumbers}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {x.selectedPowerNumber}
                                    </Table.Cell>

                                </Table.Row>
                            );
                        })
                    }
                </Table.Body>
            </Table>
        </div>
    );

})