import { number } from "yargs";
import { _FilterConditions } from "./CommonData";

export function _GetMatch(useMatch: boolean){
    return (useMatch ? "Match" : "Not Match");
}

export function _RandomNumberInRange(min:number, max:number) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
export function _GetImage(prizeType:string){
    switch(prizeType){
        case "1":
            return "/assets/firstprize100.png";
        case "2":
            return "/assets/secondprize100.png";
        case "3":
            return "/assets/thirdprize.png";
        case "S":
            return "/assets/scircle.png";
    }
    return "/assets/console.png";
}

export function _DisplayEmptyIfZero(data: number){
    if (data === 0){
        return "";
    }
    return data.toString();
}

export function _GetSavedDrawType4D( defaultValue: string){
    const retValue = localStorage.getItem("drawType4D"); 
    if (!retValue){
        localStorage.setItem("drawType4D", defaultValue);
        return  defaultValue;
    }
    return retValue;
}

export function _SetSavedDrawType4D( newValue: string){
    localStorage.setItem("drawType4D", newValue); 
}

export function _GetSavedDrawType3D( defaultValue: string){
    const retValue = localStorage.getItem("drawType3D"); 
    if (!retValue){
        localStorage.setItem("drawType3D", defaultValue);
        return  defaultValue;
    }
    return retValue;
}

export function _SetSavedDrawType3D( newValue: string){
    localStorage.setItem("drawType3D", newValue); 
}

export function _GetSavedPrizeType( defaultValue: string){
    const retValue = localStorage.getItem("prizeType"); 
    if (!retValue){
        localStorage.setItem("prizeType", defaultValue);
        return  defaultValue;
    }
    return retValue;
}

export function _SetSavedPrizeType( newValue: string){
    localStorage.setItem("prizeType", newValue); 
}

export function _GetSavedIntervalType( defaultValue: string){
    const retValue = localStorage.getItem("intervalType"); 
    if (!retValue){
        localStorage.setItem("intervalType", defaultValue);
        return  defaultValue;
    }
    return retValue;
}

export function _SetSavedIntervalType( newValue: string){
    localStorage.setItem("intervalType", newValue); 
}

export function _GetSavedSum3DType( defaultValue: string){
    const retValue = localStorage.getItem("sum3DType"); 
    if (!retValue){
        localStorage.setItem("sum3DType", defaultValue);
        return  defaultValue;
    }
    return retValue;
}

export function _SetSavedSum3DType( newValue: string){
    localStorage.setItem("sum3DType", newValue); 
}

export function _GetSavedIntervalType3D( defaultValue: string){
    const retValue = localStorage.getItem("intervalType_3D"); 
    if (!retValue){
        localStorage.setItem("intervalType_3D", defaultValue);
        return  defaultValue;
    }
    return retValue;
}

export function _GetFilterName(filterTypeID: string):string{
    const found = _FilterConditions.find(obj => {
        if (!obj) return false;
        return obj.value === filterTypeID;
      });
    return ((found) ? found.text: "N/A") ;
}

export function _SetSavedIntervalType3D( newValue: string){
    localStorage.setItem("intervalType_3D", newValue); 
}

export function _GetSavedRankType3D( defaultValue: string){
    const retValue = localStorage.getItem("rankType_3D"); 
    if (!retValue){
        localStorage.setItem("rankType_3D", defaultValue);
        return  defaultValue;
    }
    return retValue;
}

export function _SetSavedRankType3D( newValue: string){
    localStorage.setItem("rankType_3D", newValue); 
}

export function _SetSavedRankType4D( newValue: string){
    localStorage.setItem("rankType_4D", newValue); 
}

export function _GetSavedRankType4D( defaultValue: string){
    const retValue = localStorage.getItem("rankType_4D"); 
    if (!retValue){
        localStorage.setItem("rankType_4D", defaultValue);
        return  defaultValue;
    }
    return retValue;
}



export function _GetSavedDrawNo( key: string, defaultValue: number){
    const retValue = localStorage.getItem("drawNo_" + key); 
    if (!retValue){
        localStorage.setItem("drawNo_", defaultValue.toString());
        return  defaultValue;
    }
    return parseInt(retValue);
}

export function _SetSavedDrawNo( key: string, newValue: number){
    localStorage.setItem("drawNo_" + key, newValue.toString()); 
}

export function _GetBonusText(useBonus: boolean){
    return useBonus ? "Use Additional" : "No Additional"
}

export function _SortObjectByKeys(o:any) {
    return Object.keys(o).sort().reduce((r:any, k) => (r[k] = o[k], r), {});
}

export function _GetDirectHit(isDirect:string){
    
    if (isDirect === "Yes"){
        return "/assets/dollarsmile.png";
    }
    return "/assets/sad.png";
}

export function _GetRating(rating: number){
    return "/assets/point" + rating.toString() +".png";
}

export function _GetMonthName(month: number){
    switch(month){
        case 0:
            return "January";
        case 1:
            return "February";
        case 2:
            return "Maret";
        case 3:
            return "April";
        case 4:
            return "May";
        case 5:
            return "June";
        case 6:
            return "July";
        case 7:
            return "August";
        case 8:
            return "September";
        case 9:
            return "October";
        case 10:
            return "November";
        case 11:
            return "December";
    }
    return "Unknown";
}

export function _GetShortMonthName(month: number){
    switch(month){
        case 0:
            return "JAN";
        case 1:
            return "FEB";
        case 2:
            return "MAR";
        case 3:
            return "APR";
        case 4:
            return "MAY";
        case 5:
            return "JUN";
        case 6:
            return "JUL";
        case 7:
            return "AUG";
        case 8:
            return "SEP";
        case 9:
            return "OCT";
        case 10:
            return "NOV";
        case 11:
            return "DEC";
    }
    return "Unknown";
}

export function _DisplayHeader(data: string[], index: number){
    if (data.length > index - 1){
        return data[index - 1];
    }

    return "";
}

export function _CanDisplay(data: string[], index: number){
    if (data.length > index - 1){
        return true;
    }

    return false;
}

export function _getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
}

export function _DisplayPercentage(data: number, total: number, noOfDecimal: number){
    return ((data / total) * 100).toFixed(0)+'%'; 
}

export function _DisplayZero(data: number){
    if (data === 0){
        return "";
    }
    else{
        return data.toString();
    }
}

export function _GetRatingContent(rating: number){
    
    if (rating === 7 || rating === 8){
        return "Excellent [" + rating + "]";
    }

    if (rating === 6){
        return "Very Good [" + rating + "]";
    }

    if (rating === 5){
        return "Good [" + rating + "]";
    }

    if (rating === 4 || rating === 3){
        return "Average [" + rating + "]";
    }

    return "Below Average [" + rating + "]";
}

export function _GetRowPerPage(){

    const rowPerPage = localStorage.getItem("RowPerPage");
    if(rowPerPage){
        return parseInt(localStorage.getItem("RowPerPage")!);
    }
    else{
        _SetRowPerPage(5);
        return 5;
    }
}

export function _SetRowPerPage(rowPerPage: number){
    localStorage.setItem("RowPerPage", rowPerPage + "");
}

export function _SortNumber(isSystem: boolean, numberToSort: string){
    if(isSystem){
        if(!numberToSort || numberToSort.length === 0) return numberToSort;
        
        let numberList = [];

        for(var i = 0; i < numberToSort.length;i++){
            numberList.push(numberToSort.substr(i,1));     
        }
        numberList.sort();
        return numberList.join('');
    }
    else{
        return numberToSort;
    }
}
