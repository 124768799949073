import React from "react";
import {  Header, Image } from 'semantic-ui-react';


import {  Segment } from "semantic-ui-react";

interface Props{
    inverted?: boolean;
    content?: string;
}

export default function SplashScreen({inverted = true, content = "Loading ...Updating draw at 1 hour after official result."}: Props){
    return (
        <Segment
        textAlign='center' 
        style={{ minHeight: window.innerHeight, padding: '1em 0em' }}
        vertical>
                <Header as="h1">{content}</Header>
                <Image  className="centered" src="/assets/totogm-2aJPG.png" size="huge"></Image>
        </Segment>
        
    )
}