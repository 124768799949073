import { useField } from "formik";
import React from "react";
import { Form,  Input,  Label } from "semantic-ui-react";

interface Props {
    children?: never[];
    name: string;
    placeholder: string;
    label?: string;
    type?: string;
    maxLength?: number;
    focus?: boolean;
}
export default function MyTextInputNonFluid(props: Props){
    const [field, meta] = useField(props.name);

    return (
        <Form.Field inline error={meta.touched && !!meta.error}>
            <label>{props.label}</label>
            <Input data-tooltip={props.placeholder} fluid {...field} {...props}  maxLength={props.maxLength}/>
            {meta.touched && meta.error ? <Label basic color='red'>{meta.error}</Label> : null
            } 
        </Form.Field>
    );
}