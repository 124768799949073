import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { LabelKeyObject } from "react-csv/components/CommonPropTypes";
import { toast } from "react-toastify";
import { Checkbox, Icon, Input, InputOnChangeData,  Table,  CheckboxProps, Label, Popup, Dropdown, DropdownProps, Button} from "semantic-ui-react";
import agent from "../../app/api/agent";
import { _GetBonusText, _getWindowDimensions } from "../../app/lib/CommonFunctions";
import DrawLinkWithColor from "../../app/lib/DrawLinkWithColor";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { MyDictionary, MyDictionaryNumber, MyDropDownStringItems, NumbersInterval, OverviewResult } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";
import OverviewHelp from "./HelpForecast";


export default function Forecast(){
    const [noOfDraw, setNoOfDraw] = useState(30);
    const [data, setData] = useState<OverviewResult>();
    const [isLoading, setIsLoading] = useState(true);
    const [useBonus, setUseBonus] = useState(true);
    const [tempNoOfDraw, setTempNoOfDraw] = useState(30);
    const [groups, setGroups] = useState<MyDropDownStringItems[]>([]);
    const [group, setGroup] = useState("3");
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());
    const {modalStore} = useStore();
    var hitPergroup: MyDictionary<number> = {};
    var totalHitForBalls: MyDictionaryNumber<number> = {};


    useEffect(() => {
        function handleResize() {
          setWindowDimensions(_getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    

    const dropDownChanged = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps ) => {
        setGroup(data.value + "");
    }

        
    function getData():NumbersInterval[]{
        var result:NumbersInterval[] = [];
        var numbersSkip:any = {};

        if (!data || !data.totoNumbersResult) return result;

        if (data!.totoNumbersResult.length === 0) return result;

        data!.totoNumbersResult[0].ball.map((x, index)=>{

            var skip = x;

            if (skip === "O-X" || skip === "B-X"){
                skip = "0";
            }
            
            if (numbersSkip[skip]){
                numbersSkip[skip] += "," + (index + 1).toString();
            }
            else{
                numbersSkip[skip] = (index + 1).toString();
            }
            return true;
        })

        Object.keys(numbersSkip).map((key) => {
            result.push({
                interval: key,
                numbers: numbersSkip[key]
            })
            return true;
        });        
        return result;
    }    


    function getHeaders():LabelKeyObject[]{
        return [{label: "Interval", key: "interval"},
                {label: "Numbers", key: "numbers"}];
    }    

    const noOfDrawClicked = () =>{
        setNoOfDraw(tempNoOfDraw);
        setIsLoading(true);

        agent.DrawTotoResult.getForecast(noOfDraw, useBonus, group).then( (response)=> {
            setIsLoading(false);
            setData(response);
        });
    }

    const onDrawNoChanged = (event: React.ChangeEvent<HTMLElement>, data: InputOnChangeData )=>{
     
        if (Number(data.value)){
            setTempNoOfDraw(Number(data.value));
        }
        else{
            toast.error("No of Draw must be numbers !");
        }
    }

    const onBonusChanged =  (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps )=>{
        setUseBonus(data.checked!);
    }


    useEffect(()=>{
        agent.DrawTotoResult.getGroup().then((response)=>{
            setGroups(response);
            setGroup("S7")
        });
      },[]);


    useEffect(()=>{
        agent.DrawTotoResult.getForecast(noOfDraw, useBonus, group).then((response) =>{
            setData(response);
            setIsLoading(false);     
        });

    }, [noOfDraw, useBonus, group]);

    if (isLoading || !data || (data.totoNumbersResult && data.totoNumbersResult.length === 0)){
        return (<LoadingComponent></LoadingComponent>);
    }

    data.totoNumbersResult.map((r) => {

        data.groupNumbers.map((gp) => {

            gp.groupNumbers.split('-').map((ball)=>{
                const ballNo = parseInt(ball);

                if(r.ball[ballNo - 1].endsWith("-X"))
                {
                    if (hitPergroup[gp.code]){
                        hitPergroup[gp.code]++;
                    }
                    else{
                        hitPergroup[gp.code] = 1;
                    }

                    if(totalHitForBalls[ballNo]){
                        totalHitForBalls[ballNo]++;
                    }    
                    else{
                        totalHitForBalls[ballNo] = 1;
                    }
                }
                return true;
            })
    
            return true;
        });
     
        return true;
    });

    var uniqueHit: MyDictionaryNumber<number> = {};

    for(var i = 1; i <= 49; i++){
        if (!totalHitForBalls[i]){
            totalHitForBalls[i]=0;
        }
        else{
            if (!uniqueHit[totalHitForBalls[i]]){
                uniqueHit[totalHitForBalls[i]] = 1;
            }
            else{
                uniqueHit[totalHitForBalls[i]] ++;
            }
        }
    }

    const showHelp = ()=>{
        modalStore.openModal(<OverviewHelp></OverviewHelp>, "Help","small", null, ()=>{})

    }
    var hotNumbers:MyDictionaryNumber<number>={};
    var warmNumbers:MyDictionaryNumber<number>={};
    
    var isHot: boolean = true;
    var isStop: boolean = false;
    var totalHot = 0;
    var totalWarm = 0;
    
    Object.entries(uniqueHit).reverse().map (([key, value]) => {
        
        if(isStop) return false;
        if(isHot ){
            if (totalHot + value < 16){
                hotNumbers[parseInt(key)] = 1;
                totalHot += value;
            }
            else{
                
                isHot = false;
                warmNumbers[parseInt(key)] = 1;
                totalWarm += value;
            }
        }
        else{
            if(totalWarm + value < 16){
                
                warmNumbers[parseInt(key)] = 1;
                totalWarm += value;
            }
            else{
                isStop = true;
                return false;
            }
        }

        return true;
    });

    var groupPattern: MyDictionary<number>={};
    var groupPatterns:string[] = [];

    data.totoNumbersResult.map((tr)=>{
        groupPattern = {};

        data.groupNumbers.map((gp) =>{
            groupPattern[gp.code] = 0;

            gp.groupNumbers.split('-').map((gpNumber) => {

                const ballNo = parseInt(gpNumber);
                if (tr.ball[ballNo - 1].endsWith("-X")){
                    groupPattern[gp.code]++;
                }

                return true;
            });
            
            return true;
        });
    
        var tempData:number[] = [];
        Object.entries(groupPattern).map(([key,value])=>{
            tempData.push(value);
            return true;
        });

        groupPatterns.push(tempData.join("-"));

        return true;
    });
 
    if (!data || data.totoNumbersResult.length === 0){
        return (<LoadingComponent></LoadingComponent>);
    }
    return (
        <div style={{overflow:"auto", height:windowDimensions.height - 160}}>
        <Table unstackable celled basic size="small">
        <Table.Header>
            <Table.Row key="entry">
                <Table.HeaderCell verticalAlign="middle">
                <Input onChange={onDrawNoChanged} maxLength={4} onKeyPress={(event:any) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }}
                    icon={<Icon name='search' inverted circular link onClick={noOfDrawClicked} />}
                    placeholder="No of Draws" defaultValue={noOfDraw}></Input>                    
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="5">
                    <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged}>
                    </Checkbox>
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="6">
                    <Popup content="Please select group" position="bottom center" trigger={
                        <Dropdown  fluid   style={{zIndex:21}}
                            selection data-moveup="false"
                            onChange = {dropDownChanged}
                            placeholder="Select Group"
                            options={groups}
                            defaultValue = {group}
                            direction = "right"
                        />     
                      }>
                      </Popup>
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="38" textAlign="left" verticalAlign="middle">
                
                    
                    <Button primary size="mini" icon="help" floated="left" onClick={()=>showHelp()}></Button>    
                    <Label size="large" color="brown"> <b>Overview</b>  </Label>  
                    <Label circular color="red">Hot</Label>                        
                        <Label circular color="yellow">Warm</Label>                        
                        <Label circular color="blue">Cold</Label>   
                   
                
                   
                        
                             
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" colSpan="3">
                        <CSVLink data={getData()} filename="Interval" headers={getHeaders()}>
                            <Icon name="download"></Icon>Download 
                        </CSVLink>         
                    </Table.HeaderCell>                
            </Table.Row>
            <Table.Row key="ball">
            <Table.HeaderCell textAlign="center">Draw Date</Table.HeaderCell>
            
            {
                data.groupNumbers.map((yNumber, index) => {
                    return(yNumber.groupNumbers.split('-').map((ball, yIndex)=>{
                        const ballNo = parseInt(ball);
                        const isHot = hotNumbers[totalHitForBalls[ballNo]] ? true: false;
                        const isWarm = warmNumbers[totalHitForBalls[ballNo]] ? true: false;
                         
                        return (<Table.HeaderCell textAlign="center" className={(yIndex ===  yNumber.groupNumbers.split('-').length - 1) ? "doubleBar" : ""}>
                        <DrawLinkWithColor color={(isHot ? "red":(isWarm ? "yellow":"blue"))} number={ball} bonus={useBonus}></DrawLinkWithColor>
                        </Table.HeaderCell>)
                    }));
                })
            }
            <Table.HeaderCell textAlign="center" rowSpan="4">Result</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" rowSpan="4">Pattern</Table.HeaderCell>

            </Table.Row>
            <Table.Row key="ratio">
                <Table.HeaderCell textAlign="center">% Per Group</Table.HeaderCell>
                {
                    data.groupNumbers.map((gp)=>{
                        const gpLastIndex = gp.groupNumbers.split('-').length - 1;
                        const totalPerGroup = hitPergroup[gp.code] ? hitPergroup[gp.code] : 0;
                        return (
                            gp.groupNumbers.split('-').map((ball, index) => {
                                return (
                                    <Table.HeaderCell className={(index === gpLastIndex) ? "doubleBar" : ""} textAlign="center"> 
                                    {((totalHitForBalls[parseInt(ball)] / totalPerGroup) * 100).toFixed(0) +'%'} 
                                    </Table.HeaderCell>
                                )
                            }) 

                        )
                    })
                }
        </Table.Row>
        <Table.Row key="due">
                <Table.HeaderCell textAlign="center"># of Hit</Table.HeaderCell>
                {
                    data.groupNumbers.map((gp)=>{
                        const gpLastIndex = gp.groupNumbers.split('-').length - 1;

                        return (
                            gp.groupNumbers.split('-').map((ball, index) => {
                                return (
                                    <Table.HeaderCell className={(index === gpLastIndex) ? "doubleBar" : ""} textAlign="center"> 
                                    {totalHitForBalls[parseInt(ball)]} 
                                    </Table.HeaderCell>
                                )
                            }) 

                        )
                    })
                }
        </Table.Row>
        <Table.Row>
        <Table.HeaderCell textAlign="center">Interval</Table.HeaderCell>

                {data.groupNumbers.map((gp) => {
                    const gpLastIndex = gp.groupNumbers.split('-').length - 1;

                    return(gp.groupNumbers.split('-').map((ball, ballIndex)=>{
                        const ballValue = data.totoNumbersResult[0].ball[parseInt(ball) - 1]; 
                        return(<Table.HeaderCell className={(ballIndex === gpLastIndex) ? "doubleBar" : ""} textAlign="center">
                            {ballValue.endsWith("-X") && <span>0</span>
                            }
                            {
                                !ballValue.endsWith("-X") && <span>{ballValue}</span>
                            }
                        </Table.HeaderCell>)
                    }))
                    })
                }
                                   
        </Table.Row>
        </Table.Header>
        
        <Table.Body>
            {
                data.totoNumbersResult.map((x, index) => {
                    return (
                        <Table.Row key={index}>
                            <Table.Cell textAlign="center" collapsing>{x.drawDate}</Table.Cell>
                            
                            {data.groupNumbers.map((y, yIndex) => {
                                return(y.groupNumbers.split('-').map((ball, ballIndex)=>{
                                    return (
                                        <Table.Cell textAlign="center" className={(ballIndex === y.groupNumbers.split('-').length - 1) ? "doubleBar" : ""}>
                                            { 
                                                (x.ball[parseInt(ball) - 1].endsWith("O-X") ? <Label color="green" circular>{ball}</Label> :
                                                (x.ball[parseInt(ball) - 1].endsWith("B-X") ? <Label color="black" circular>{ball}</Label> : 
                                                    "" ))
                                            }
                                        </Table.Cell>)
                                }));
                            })
                            }
                            <Table.Cell textAlign="center" collapsing>{x.numbers}</Table.Cell>
                            <Table.Cell textAlign="center" collapsing>{groupPatterns[index]}</Table.Cell>

                        </Table.Row>
                    )
                })
            }
        </Table.Body>

        </Table>
        </div>
    )

}