import { observer } from "mobx-react-lite";
import React, { useState } from "react"
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { Table, Header, Checkbox, CheckboxProps, Input, Button, InputOnChangeData, Icon, Label, Container, Segment, Grid, Popup, Dropdown, DropdownProps, Tab } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import { _GetBonusText, _getWindowDimensions } from "../../app/lib/CommonFunctions";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { GroupNumbers, MyDropDownStringItems } from "../../app/models/IDayResult";
import { WeeklyForecastValues } from "../../app/models/user";
import { useStore } from "../../app/store/store";
import WeeklyForecastSet from "./WeeklyForecastSet";

export default observer(function MonthlyForecast() {

    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState("");
    const {userStore } = useStore();
    const [availableForecastDate, setAvailableForecastDate] = useState<MyDropDownStringItems[]>([]);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [groups, setGroups] = useState<MyDropDownStringItems[]>([]);
    const [data, setData] = useState<WeeklyForecastValues[]>([]);
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());

    const panes = [
        {
            menuItem: 'Set 1',
            render: () => <Tab.Pane attached={false}><WeeklyForecastSet isMobile={isMobile} data={data.filter(x => x.forecast_By === 1)}></WeeklyForecastSet></Tab.Pane>,
        },
        {
            menuItem: 'Set 2',
            render: () => <Tab.Pane attached={false}><WeeklyForecastSet isMobile={isMobile} data={data.filter(x => x.forecast_By === 2)}></WeeklyForecastSet></Tab.Pane>,
        }
    ]  

    const groupChanged = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        setSelectedGroup(data.value + "");
    }

    const dateChanged = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        setSelectedDate(data.value + "");
    }


    useEffect(() => {
        function handleResize() {
            setWindowDimensions(_getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);

        agent.DrawTotoResult.getWeeklyForecastDate().then(response => {
            setAvailableForecastDate(response);
            if (response.length > 0) {
                setSelectedDate(response[0].value);
            }
        }).finally(() => {
            setLoading(false);
        });

        agent.DrawTotoResult.getGroup().then((response) => {
            setGroups(response);
            if (response.length > 0) {
                setSelectedGroup("S7")
            }
        });

        return () => window.removeEventListener('resize', handleResize);

    }, []);


    useEffect(() => {
        if (!selectedDate || !selectedGroup) return;

        agent.DrawTotoResult.getWeeklyForecast(selectedDate, selectedGroup).then((response) => {
            setData(response);
        });
    }, [selectedDate, selectedGroup]);


    if (!userStore.isLogged) {
        return (<Header>Not Authorized</Header>)
    }

    if (loading) {
        return (<LoadingComponent></LoadingComponent>);
    }
    if (data.length === 0) {
        return (<Header>Waiting for server response...</Header>)
    }

    const isMobile = windowDimensions.width <= 768;

    return (
        <Container>
            <Header as="h2">Weekly Forecast</Header>
            <Segment raised>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Popup content="Please select group" position="top center" trigger={
                                <Dropdown fluid style={{ zIndex: 21 }}
                                    selection data-moveup="false"
                                    onChange={groupChanged}
                                    placeholder="Select Group"
                                    options={groups}
                                    value={selectedGroup}
                                    direction="right"
                                />
                            }>
                            </Popup>

                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Popup content="Please select forecast date" position="top center" trigger={
                                <Dropdown fluid style={{ zIndex: 21 }}
                                    selection data-moveup="false"
                                    onChange={dateChanged}
                                    placeholder="Select Forecast Date"
                                    options={availableForecastDate}
                                    value={selectedDate}
                                    direction="right"
                                />
                            }>
                            </Popup>

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Label size="large" circular color="red">
                                Key Numbers*
                            </Label>
                            <Label size="large" circular color="blue">
                                Forecast Numbers
                            </Label>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column width={16}>
                            <div style={{ overflow: "auto" }}>
                                <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Container>
    );
});