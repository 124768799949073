import { ErrorMessage, Field, Form, Formik } from "formik";
import { observer} from "mobx-react-lite";
import React from "react";
import { Button,  Divider,  Label } from "semantic-ui-react";
import * as Yup from 'yup';
import { _GetBonusText, _GetFilterName } from "../../app/lib/CommonFunctions";
import { MyCheckBoxField } from "../../app/lib/MyCheckBoxField";
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import { WheelingCondition } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";


export default observer(function FilterPairNeighbour(){
    const {userStore, modalStore} = useStore();
    

    const validationSchema = Yup.object({
        neighbour: Yup.string().required("Neighbour is required (01 to 08 separated by - <dash>) !").test("checkInterval","Invalid Neighbour. Use - for range or coma ! ", (value, ctx) => {
            if (!value) return false;

            const allNeighbours = value!.split(',');
            let ok = true;    
            allNeighbours.map((y) => {
                const neighbour = y.split('-');

                if (neighbour.length > 3){
                    ok = false;
                    return false;
                }
                if (neighbour.length === 3){
                    const fromNumber = Number(neighbour[0]);
                    const toNumber = Number(neighbour[1]);

                    if (isNaN(fromNumber) || isNaN(toNumber)){
                        ok = false;
                        return false;
                    }

                    if (fromNumber < 0 || fromNumber > 8){
                        ok = false;
                        return false;
                    }

                    if (toNumber < 0 || toNumber > 8){
                        ok = false;
                        return false;
                    }

                    if(fromNumber > toNumber){
                        ok = false;
                        return false;
                    }
                    return true;
                }

                ok = false;

                return false;
            })

            return ok;
        }),
        min: Yup.number().required("Minimum is required !").positive().integer().min(0,"Minimum cannot be smaller than 0 ").max(8,"Maximum cannot be greater than 8").test("checkGreater","Minimum must be smaller than Maximum !",(value, ctx)=> Number(ctx.parent.max) >= Number(value)),
        max: Yup.number().required("Maximum is required !").positive().integer().min(1,"Minimum cannot be smaller than 1 ").max(8,"Maximum cannot be greater than 8"),
    });

    return (
        <Formik initialValues={{ neighbour: "", min: 0, max: 3, useBonus : true,  error: null}}
                onSubmit={
                    (values,{setErrors}) => {
                        const neighbour = values["neighbour"]
                        const minValue = values["min"];
                        const maxValue = values["max"];
                        const useBonus = values["useBonus"];

                        var filterCondition: WheelingCondition = {
                            filterType : "08",
                            filterTypeName: _GetFilterName("08"),
                            data : neighbour + "|" + _GetBonusText(useBonus) ,
                            min: Number(minValue),
                            max: Number(maxValue)
                        };

                        localStorage.setItem("FilterCondition", JSON.stringify(filterCondition));
                        modalStore.closeModal();
                    }
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <MyTextInputNonFluid data-tooltip="Enter Neighbour 1 to 8 in 3 separated by dash (-) " name="neighbour" placeholder="Enter 01 to 08 separated by - or coma"></MyTextInputNonFluid>
                        <MyTextInputNonFluid data-tooltip="Minimum value to filter" type="number" name="min" placeholder="Enter Mininum"></MyTextInputNonFluid>
                        <MyTextInputNonFluid data-tooltip="Maximum value to filter" type="number" name="max" placeholder="Enter maximum"></MyTextInputNonFluid>
                        <Field
                                name="useBonus"
                                component={MyCheckBoxField}
                        />

                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Divider />
                        <Button icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>
                    </Form>
                
            )}
        </Formik>
    )
})