import { observer } from "mobx-react-lite";
import React, {  useRef, useState } from "react"
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Table, Header,  Input,  InputOnChangeData, Popup, Dropdown, DropdownProps, Icon, Label, Button, Grid, Sidebar, Segment, Menu, Ref, Checkbox, CheckboxProps } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/lib/LoadingComponent";
import {  MyDropDownStringItems, WheelingCondition, WheelingTemplate } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";
import { CSVLink } from "react-csv";
import { _FilterConditions, _PickSystem } from "../../app/lib/CommonData";
import FilterHotWarmCold from "./FilterHotWarmCold";
import FilterGenericMinMax from "./FilterGenericMinMax";
import FilterInterval from "./FilterInterval";
import FilterNumber from "./FilterNumber";
import FilterGenericMinMaxWithoutBonus from "./FilterGenericMinMaxWithoutBonus";
import FilterNeighbour from "./FilterNeighbour";
import FilterPairNeighbour from "./FilterPairNeighbour";
import FilterTripleNeighbour from "./FilterTripleNeighbour";
import FilterCombination from "./FilterCombination";
import FilterCombinationInterval from "./FilterCombinationInterval";
import FilterHitDue from "./FilterHitDue";
import FilterGroupPattern from "./FilterGroupPattern";
import FilterGroupDirectPattern from "./FilterGroupDirectPattern";
import { _GetFilterName, _getWindowDimensions } from "../../app/lib/CommonFunctions";
import { useHistory } from "react-router-dom";
import FilterCombinationHitCount from "./FilterCombinationHitCount";
import FilterGroupSubSet from "./FilterGroupSubSet";
import FilterGap from "./FilterGap";
import ReduceSameNumbers from "./ReduceSameNumbers";
import FilterCoverage from "./FilterCoverage";

export default observer(function FullWheeling(){
    const numbersRef = useRef<any>(null);
    const [pickSystem, setPickSystem] = useState(6);
    const [loading, setLoading] = useState(true);
    const [existingWheeling, setWheeling] = useState<MyDropDownStringItems[]>([]);
    const [numbers, setNumbers] = useState("");
    const [data, setData] = useState<WheelingCondition[]>([]);
    const {userStore, modalStore}= useStore();
    const [filterID, setFilterID] = useState("");
    const [filters, setFilters]= useState<MyDropDownStringItems[]>([]);
    const [visible, setVisible] = React.useState(false);
    const [selectedNumbers, setSelectedNumbers] = useState<boolean[]>([]);
    const [processing, setProcessing] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());
    const hiddenFileInput = useRef(null);

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(_getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    

    const history = useHistory();
    
    const headers = [{label: "Ball",key: "ball"},
                      {label: "Sum", key: "total_Sum"},
                      {label:"Range", key: "range"},
                      {label: "Std Dev",key:"std_Dev"},
                      {label:"Interval Pattern", key: "interval_Pattern"},
                      {label:"Odd/Even Pattern", key: "odd_Even_Pattern"},
                      {label:"Low/Mid/High", key: "high_Med_Low_Pattern"},
                      {label:"Gap", key: "gap_Pattern"}]

    const deleteCondition = (index: number) => {
      if (window.confirm("Are you sure to delete")){
        const tempData = data.filter((item, itemIndex) => itemIndex !== index)
        setData(tempData);
      }
    }
    const dropDownChanged = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps ) => {
        setFilterID(data.value + "");
    }

    const pickDropDownChanged = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps ) => {
      setPickSystem(Number(data.value + ""));
    }

    const downloadJSON = ()=> {
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(data)
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = "wheelfilter.json";
  
      link.click();
    }

    const addClicked = ()=>{
      const afterAdd = () =>{
        var item = localStorage.getItem("FilterCondition");
        if (item === null || item.length ===0) return;

        const filterCondition = JSON.parse(item!);
        
        var tempData = [...data];

        tempData.push(filterCondition);
        setData(tempData);
        
      }

      localStorage.setItem("FilterCondition","");

      if (filterID === "00"){
        modalStore.openModal(<FilterHotWarmCold></FilterHotWarmCold>,"Filter Hot/Warm/Cold Condition", "small", null, ()=>{
          afterAdd();
        });
      }

      if (filterID === "01"){
        localStorage.setItem("FilterID", "01");
        modalStore.openModal(<FilterGenericMinMax></FilterGenericMinMax>,"Filter Repeat Number Condition", "small", null, ()=>{
          afterAdd();
        });
      }

      if (filterID === "02"){
        modalStore.openModal(<FilterInterval></FilterInterval>,"Filter Interval Condition", "small", null, ()=>{
          afterAdd();
        });
      }

      if (filterID === "03"){
        modalStore.openModal(<FilterNumber></FilterNumber>,"Filter Numbers Condition", "small", null, ()=>{
          afterAdd();
        });
      }
      if (filterID === "04"){
        localStorage.setItem("FilterID", "04");
        
        modalStore.openModal(<FilterGenericMinMaxWithoutBonus></FilterGenericMinMaxWithoutBonus>,"Filter Sum Condition", "small", null, ()=>{
          afterAdd();
        });
      }
      if (filterID === "05"){
        localStorage.setItem("FilterID", "05");
        
        modalStore.openModal(<FilterGenericMinMaxWithoutBonus></FilterGenericMinMaxWithoutBonus>,"Filter Range Condition", "small", null, ()=>{
          afterAdd();
        });
      }

      if (filterID === "06"){
        modalStore.openModal(<FilterNeighbour></FilterNeighbour>,"Filter Neighbour Condition", "small", null, ()=>{
          afterAdd();
        });
      }

      if (filterID === "07"){
        modalStore.openModal(<FilterPairNeighbour></FilterPairNeighbour>,"Filter Pair Neighbour Condition", "small", null, ()=>{
          afterAdd();
        });
      }

      if (filterID === "08"){
        modalStore.openModal(<FilterTripleNeighbour></FilterTripleNeighbour>,"Filter Triple Neighbour Condition", "small", null, ()=>{
          afterAdd();
        });
      }

      if (filterID === "09"){
        modalStore.openModal(<FilterCombination></FilterCombination>,"Filter Balls Combination Filter", "small", null, ()=>{
          afterAdd();
        });
      }

      if (filterID === "10"){
        modalStore.openModal(<FilterCombinationInterval></FilterCombinationInterval>,"Filter Balls Combination Interval Filter", "small", null, ()=>{
          afterAdd();
        });
      }

      if (filterID === "11"){
        modalStore.openModal(<FilterGroupPattern></FilterGroupPattern>,"Filter Group Pattern Filter", "small", null, ()=>{
          afterAdd();
        });
      }

      if (filterID === "12"){
        modalStore.openModal(<FilterGroupDirectPattern></FilterGroupDirectPattern>,"Filter Group Direct Pattern Filter", "small", null, ()=>{
          afterAdd();
        });
      }

      if (filterID === "13"){
        modalStore.openModal(<FilterGroupSubSet></FilterGroupSubSet>,"Filter Group Subset", "small", null, ()=>{
          afterAdd();
        });
      }

      if (filterID === "14"){
        modalStore.openModal(<FilterGap></FilterGap>,"Filter Gap", "small", null, ()=>{
          afterAdd();
        });
      }

      if (filterID === "20"){
        modalStore.openModal(<FilterHitDue></FilterHitDue>,"Filter Hit Due Filter", "small", null, ()=>{
          afterAdd();
        });
      }

      if (filterID === "21"){
        modalStore.openModal(<FilterCombinationHitCount></FilterCombinationHitCount>,"Filter Combination Hit Count", "small", null, ()=>{
          afterAdd();
        });
      }

      if (filterID === "98"){
        modalStore.openModal(<FilterCoverage></FilterCoverage>,"Coverage Filter", "small", null, ()=>{
          afterAdd();
        });
      }

      if (filterID === "99"){
        modalStore.openModal(<ReduceSameNumbers></ReduceSameNumbers>,"Reduce Wheels", "small", null, ()=>{
          afterAdd();
        });
      }

    }

    

    const onNumbersChanged = (event: React.ChangeEvent<HTMLElement>, data: InputOnChangeData )=>{
        
      var tempNumbers = data.value.split(',');
        var tempSelectedNumber: boolean[] = [...selectedNumbers];

        for(var i = 0; i < 49; i++){
          tempSelectedNumber[i] = false;
        }

        tempNumbers.map((x)=>{
            const tempX = Number(x);
            if (tempX){
              if (tempX >= 1 && tempX <= 49){
                  tempSelectedNumber[tempX - 1] = true;
              }
            }
        })
        setSelectedNumbers(tempSelectedNumber);
        setNumbers(data.value);
        //console.log(data.value);

    }

   
  useEffect(()=>{
      agent.DrawTotoResult.getWheelingSource(userStore.user?.username!).then((response)=>{
          setWheeling(response);
          setLoading(false);
          
      });

      var tempData: boolean[] = [];

      for(var i = 1; i <= 49; i++){
        tempData.push(false);
      }

      setSelectedNumbers(tempData);
  },[]);

  useEffect(()=>{
    var groupID = userStore.user?.groupID;
    if (!groupID) groupID = 0;

    agent.Account.getWheelCondition(groupID).then((response)=>{
        setFilters(response);
    });
},[]);

useEffect(()=>{
  const userName = userStore.isLogged ? userStore.user!.username: "NoUser";

  agent.DrawTotoResult.getWheelTemplateDetail(userName).then((response)=>{
      if (response.length > 0){

        setNumbers(response[0].wheelNumbers);
        setPickSystem(response[0].pickSystem);
        var tempSelected: boolean[] = [];

        for(var i = 1; i <= 49; i++){
          tempSelected.push(false);
        }
        if (response[0].wheelNumbers && response[0].wheelNumbers.length > 0){
          response[0].wheelNumbers.split(',').map((x) => {
            tempSelected[Number(x) - 1] = true;
            return true;
          });
        }
        setSelectedNumbers(tempSelected);

        var tempData: WheelingCondition[] = [];

        response.map((x)=>{
            tempData.push({
              filterTypeName: _GetFilterName(x.wheelFilterID),
              filterType: x.wheelFilterID,
              min: x.minValue,
              max: x.maxValue,
              data: x.dataValue
            })
        });
        setData(tempData);
      }
      else{
        setData([]);
      }
  });
},[userStore.isLogged])

const saveData = () => {
  var selectedWheeledNumbers =numbersRef.current!.inputRef!.current!.value;
  if (!selectedWheeledNumbers || selectedWheeledNumbers.length === 0){
    toast.error("Wheeled Numbers is required");
    return;
  } 

  if (selectedWheeledNumbers.split(',').length > 35){
    toast.error("Maximum numbers is 30 !");
    return;
  }
  var wheelingTemplate:WheelingTemplate = {
     pickSystem: pickSystem,
     templateName: '',
     wheelNumbers: numbersRef.current!.inputRef!.current!.value,
     userID: userStore.user!.username,
    wheelConditions: data
  };

  agent.DrawTotoResult.saveTemplate(wheelingTemplate).then((response) => {
    if (response === "OK")
      toast.info("Template saved !");
    else toast.error(response);
  }).catch((err) => {
    toast.error("Error saving template with message: " + err);
  });
}

const submitWheel = () => {
  var selectedWheeledNumbers =numbersRef.current!.inputRef!.current!.value;
  if (!selectedWheeledNumbers || selectedWheeledNumbers.length === 0){
    toast.error("Wheeled Numbers is required");
    return;
  } 

  if (selectedWheeledNumbers.split(',').length > 35){
    toast.error("Maximum numbers is 30 !");
    return;
  }

  if (selectedWheeledNumbers.length  > 12){
    if (filters.length === 0){
      toast.error("Filters is required. !");
      return;
    }
  }

  if (selectedWheeledNumbers.length  > 15){
    var pass = false;
    filters.map((x)=>{
      if (x.value === "99"){
        pass = true;
        return false;
      }
      return true;
    });
    if (!pass){
      toast.error("For more than 15 numbers, please include Reduce Filter !");
      return;
    }
  }


  var wheelingTemplate:WheelingTemplate = {
     pickSystem: pickSystem,
     templateName: '',
     wheelNumbers: numbersRef.current!.inputRef!.current!.value ,
     userID: userStore.user!.username,
    wheelConditions: data
  };

  setProcessing(true);
  agent.DrawTotoResult.submitWheel(wheelingTemplate).then((response) => {
    setProcessing(false);
    const retVal = response.split('|');
    if (retVal[0] === "OK")
    {
        history.push('/showfullwheel/' + retVal[1])
    }
    else toast.error(response);
  }).catch((err) => {
    toast.error("Error saving template with message: " + err);
  });
}

const clearAll = ()=>{
  var tempData: boolean[] = [...selectedNumbers];
  var tempSelected:number[] = [];

  for(var i = 0; i < tempData.length; i++){
    tempData[i] = false;
  }

  setSelectedNumbers(tempData);

  if (numbersRef != null && numbersRef.current != null)
    numbersRef.current!.inputRef!.current!.value = "";

}

const importJson = ()=>{
  if (hiddenFileInput && hiddenFileInput.current){
      const inputFile: HTMLInputElement  = hiddenFileInput.current!;
      inputFile.click();
  }
}

const handleFileChanged = (event: any)=> {
    if(event.target.files){

      const fileReader = new FileReader();
      fileReader.readAsText(event.target.files[0], "UTF-8");

      fileReader.onload = (e:any) => {
        const content = e.target.result;

        var newData:WheelingCondition[] = [];

        newData = JSON.parse(content);
        setData(newData);
 
      };

      // can read file?
    }
}


const toggleButton = (index: number) =>{
  var tempData: boolean[] = [...selectedNumbers];

  tempData[index] = !tempData[index];

  setSelectedNumbers(tempData);
  var tempSelected:number[] = [];

  for(var i = 0; i < 49; i++){
    if (tempData[i]){
        tempSelected.push(i + 1);
    }
  }

  if (numbersRef != null && numbersRef.current != null)
    numbersRef.current!.inputRef!.current!.value = tempSelected.join(',');
}

const CreateNumberInput = (index: number) => {
    var localTemp = [];
    for (var i = 0; i < 7; i++){
      localTemp.push(i + 1 + (index - 1));
    }
    return (
        <Table.Row key={index}>
          {
              localTemp.map((x, idx) => {
                return (
                  <Table.Cell>
                    <Button size="tiny" toggle active={selectedNumbers[x - 1]} onClick={()=>toggleButton(x - 1)}>
                    {x.toString().length === 1 ? "0" + x.toString() : x.toString()}

                    </Button>
                  </Table.Cell>
                )
              })
          }
        </Table.Row>
    )
}

const selectAll = ()=>{
  
  setVisible(!visible);

  return;
  var tempData: boolean[] = [...selectedNumbers];
  var tempSelected:number[] = [];

  for(var i = 0; i < tempData.length; i++)
  {    tempData[i] = true;
        tempSelected.push(i + 1);
  }

  setSelectedNumbers(tempData);

  if (numbersRef != null && numbersRef.current != null)
    numbersRef.current!.inputRef!.current!.value = tempSelected.join(",");

}

if(!userStore.isLogged){
  return (<Header>Not Authorized</Header>)
}
   
if (processing){
  return (<LoadingComponent content="Processing data. Please wait...Or use Show Fullwheel Result after few minutes.."></LoadingComponent>); 
}
    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflow:"auto"}}>
      <Sidebar.Pushable as={Segment}>
          <Sidebar
            as={Table}
            animation='overlay'
            icon='labeled'
            inverted
            onHide={() => setVisible(false)}
            vertical="true"
            visible={visible}
            width='thin'
            direction="right"
          >
            <Table.Body>
            {numbersRef.current !== null && numbersRef.current!.inputRef !== null &&
            <Table.Row key="Header1">
              <Table.Cell colSpan="2"><Button primary icon="eraser" content="Clear" onClick={()=>clearAll()}></Button></Table.Cell>
              <Table.Cell colSpan="3" textAlign="center">{"Number selected: " + 
                (numbersRef.current!.inputRef!.current!.value.length === 0 ? 0:numbersRef.current!.inputRef!.current!.value.split(',').length)}</Table.Cell>
              <Table.Cell colSpan="2" textAlign="right"><Button color="red" icon="window close outline" content="Close" onClick={()=>selectAll()}></Button></Table.Cell>
              </Table.Row>
            }
            {
              CreateNumberInput(1)
            }
            {
              CreateNumberInput(8)
            }
            {
              CreateNumberInput(15)
            }
            {
              CreateNumberInput(22)
            }
            {
              CreateNumberInput(29)
            }
            {
              CreateNumberInput(36)
            }
            {
              CreateNumberInput(43)
            }
            </Table.Body>
          </Sidebar>

          <Sidebar.Pusher>
          <div style={{overflowX:"scroll"}}>
        <Table celled unstackable selectable singleLine>
        <Table.Header>
        <Table.Row key="header1">
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell colSpan="4" textAlign="center">
            <Header>FULL WHEEL</Header>
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="2"  textAlign="right">
            <CSVLink data={data} filename="Ordinary" headers={headers}>
              <Icon name="download"></Icon>Download 
            </CSVLink>            
          </Table.HeaderCell>
          </Table.Row>
        <Table.Row key="header2">
          <Table.HeaderCell textAlign="center" colSpan="6">
                <Input iconPosition="left" onChange={onNumbersChanged} maxLength={250} fluid ref={numbersRef}
                        icon={<Icon  name='tasks' inverted circular link onClick={()=>setVisible(!visible)} />}
                        placeholder={"Numbers to Wheel: "} defaultValue={numbers}></Input>

          </Table.HeaderCell>
        </Table.Row>
          <Table.Row key="header3">
              <Table.HeaderCell colSpan="3">
                  <Popup  content="Select / Enter Wheeling Filter" position="top center" trigger={
                      <Dropdown  fluid   style={{zIndex:21}}
                        selection
                          search
                          onChange = {dropDownChanged}
                          placeholder="Select / Enter Filter Condition"
                          options={filters}
                          defaultValue = {filterID}
                          direction = "right"
                      />
                    }>
                  </Popup>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button primary icon="add" content="Add" onClick={()=>{
                  addClicked();
                }}></Button>
                  <Button icon="download" positive onClick={()=>downloadJSON()} content="Download">
                </Button>
                <Button icon="upload" primary onClick={()=>importJson()} content="Upload">
                </Button>
                <input type="file" onChange={handleFileChanged} ref={hiddenFileInput} style={{display:"none"}} />
              </Table.HeaderCell>
              <Table.HeaderCell colSpan="2" textAlign="right">
              
                <Dropdown style={{zIndex:21, marginRight:"10px"}}
                          selection
                          onChange = {pickDropDownChanged}
                          placeholder="Select System to wheel"
                          options={_PickSystem}
                          value = {pickSystem}
                          direction = "right"
                      />

                <Button icon="save" positive onClick={()=>saveData()} content="Save">
                  </Button>
              
                <Button icon="filter" content="Submit" color="blue" onClick={()=>submitWheel()}></Button>
              </Table.HeaderCell>

          </Table.Row>
          <Table.Row key="header4">
            <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Min</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Max</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Filter Name</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Data</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
    
        <Table.Body>

          {data.map(((x, index) => {
            return (
                <Table.Row key={index} className="freeze-head-and-col">
                  <Table.Cell textAlign="center">{index + 1}</Table.Cell>
                  <Table.Cell textAlign="center">
                      {x.min}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                      {x.max}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {x.filterTypeName}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                      {x.data}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                      <Button icon="delete" onClick={()=> deleteCondition(index)} />
                  </Table.Cell>
                </Table.Row>
              );
            }))
          }   
           </Table.Body>
      </Table>
      </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
        </div>

 );
});