import { observer } from "mobx-react-lite";
import React, { useState } from "react"
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { Table, Header, Checkbox, CheckboxProps, Input, Button, InputOnChangeData, Icon, Label, Container, Segment, Grid, Popup, Dropdown, DropdownProps } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import { _GetBonusText, _getWindowDimensions } from "../../app/lib/CommonFunctions";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { GroupNumbers, MyDropDownStringItems } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";

export default observer(function MonthlyForecast() {

    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState("");
    const { userStore } = useStore();
    const [availableForecastDate, setAvailableForecastDate] = useState<MyDropDownStringItems[]>([]);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [groups, setGroups] = useState<MyDropDownStringItems[]>([]);
    const [data, setData] = useState<GroupNumbers[]>([]);
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());

    const groupChanged = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        setSelectedGroup(data.value + "");
    }

    const dateChanged = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        setSelectedDate(data.value + "");
    }


    useEffect(() => {
        function handleResize() {
            setWindowDimensions(_getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);

        agent.DrawTotoResult.getMonthlyForecastDate().then(response => {
            setAvailableForecastDate(response);
            if (response.length > 0) {
                setSelectedDate(response[0].value);
            }
        }).finally(() => {
            setLoading(false);
        });

        agent.DrawTotoResult.getGroup().then((response) => {
            setGroups(response);
            if (response.length > 0) {
                setSelectedGroup("S7")
            }
        });

        return () => window.removeEventListener('resize', handleResize);

    }, []);


    useEffect(() => {
        if (!selectedDate || !selectedGroup) return;

        agent.DrawTotoResult.getMonthlyForecast(selectedDate, selectedGroup).then((response) => {
            setData(response);
        });
    }, [selectedDate, selectedGroup]);


    if (!userStore.isLogged) {
        return (<Header>Not Authorized</Header>)
    }

    if (loading) {
        return (<LoadingComponent></LoadingComponent>);
    }
    if (data.length === 0) {
        return (<Header>Waiting for server response...</Header>)
    }
    var keyNumbers:string[] = [];
    var allNumbers:string[] = [];

    data.map((x) => {
        x.numbers.split(',').map((y) => {
            if (y.endsWith("*")) {
                keyNumbers.push(y)
            }
            if (y.endsWith("+")) {
                allNumbers.push(y.replace("+", ""));
            }
            return true;
        })
        return true;
    })

    const isMobile = windowDimensions.width <= 768;

    return (
        <Container>
            <Header as="h2">Monthly Forecast</Header>
            <Segment raised>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Popup content="Please select group" position="top center" trigger={
                                <Dropdown fluid style={{ zIndex: 21 }}
                                    selection data-moveup="false"
                                    onChange={groupChanged}
                                    placeholder="Select Group"
                                    options={groups}
                                    value={selectedGroup}
                                    direction="right"
                                />
                            }>
                            </Popup>

                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Popup content="Please select forecast date" position="top center" trigger={
                                <Dropdown fluid style={{ zIndex: 21 }}
                                    selection data-moveup="false"
                                    onChange={dateChanged}
                                    placeholder="Select Forecast Date"
                                    options={availableForecastDate}
                                    value={selectedDate}
                                    direction="right"
                                />
                            }>
                            </Popup>

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Label size="large" circular color="red">
                                Key Numbers*
                            </Label>
                            <Label size="large" circular color="blue">
                                Forecast Numbers
                            </Label>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column width={16}>
                            <div style={{ overflow: "auto" }}>
                                <Header as={isMobile ? "h2" : "h3"}>Forecast Numbers: {keyNumbers.join(", ")} - {allNumbers.join(", ")}
                                </Header>

                                <Table celled unstackable selectable singleLine size="small">
                                    {
                                        data.map((x, index) => {
                                            if (isMobile) {
                                                return (<Table.Row key={index}>
                                                    {
                                                        x.numbers.split(',').map((y, yIndex) => {
                                                            return (<Table.Cell textAlign="center">
                                                                <Label size="medium" circular color={y.endsWith("*") ? "red" : (y.endsWith("+") ? "blue" : "grey")}>
                                                                    {y.replace("+", "")}
                                                                </Label>
                                                            </Table.Cell>);
                                                        })
                                                    }
                                                </Table.Row>)
                                            }
                                            else {
                                                return (<Table.Row key={index}>
                                                    {
                                                        x.numbers.split(',').map((y, yIndex) => {
                                                            return (<Table.Cell textAlign="center">
                                                                <Label size="huge" circular color={y.endsWith("*") ? "red" : (y.endsWith("+") ? "blue" : "grey")}>
                                                                    {y.replace("+", "")}
                                                                </Label>
                                                            </Table.Cell>);
                                                        })
                                                    }
                                                </Table.Row>)
                                            }
                                        })
                                    }
                                </Table>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Container>
    );
});