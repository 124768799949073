import { observer } from "mobx-react-lite";
import React, { useState } from "react"
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { LabelKeyObject } from "react-csv/components/CommonPropTypes";
import { toast } from "react-toastify";
import {  Table, Header, Checkbox, CheckboxProps, Input, Icon, InputOnChangeData, Popup, Grid, Label } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import { _GetBonusText, _getWindowDimensions } from "../../app/lib/CommonFunctions";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { AnalysisByIntervalDataResult, AnalysisByIntervalResult, IntervalNumbers} from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";

export default observer(function AnalysisByInterval(){
    
    const displayNumbers=(groupNumbers: IntervalNumbers[], code: string) => {
      return groupNumbers.map((x) => {
        if (x.lastSkip.toString() === code){
          return x.ball;
        }
        return "";
      })
    }

    
    const [noOfDraw, setNoOfDraw] = useState(50);
    const [loading, setLoading] = useState(true);
    const [useBonus, setUseBonus] = useState(true);
    const [analysis, setAnalysis] = useState<AnalysisByIntervalResult>();
    const {userStore} = useStore();
    const [tempNoOfDraw, setTempNoOfDraw] = useState(noOfDraw);
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(_getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    

    //const periodDisplay = (curYear - 11).toString() + " to " + (curYear - 1).toString();
    const noOfDrawClicked = () =>{
      setNoOfDraw(tempNoOfDraw);
      setLoading(true);
    }

    const onDrawNoChanged = (event: React.ChangeEvent<HTMLElement>, data: InputOnChangeData )=>{
     
      if (Number(data.value)){
          setTempNoOfDraw(Number(data.value));
      }
      else{
          toast.error("No of Draw must be numbers !");
      }
  }    

    const onBonusChanged =  (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps )=>{
      setUseBonus(data.checked!);
      setLoading(true);
    }

    const intervalLength = analysis ? analysis?.intervals.split(',').length : 0;
    const bodyColSpan = analysis === null ? 1 : intervalLength > 6 ? intervalLength - 5 : 1;
 
    function getData():AnalysisByIntervalDataResult[]
    {   if (!analysis) return [];

        return analysis!.data;
    }    

    function getAverage(x: AnalysisByIntervalDataResult){
      if(!analysis) return 0;
      var average = 0;
      var counter  = 0;
      analysis.intervals.split(',').map( (interval, index) => {
        Object.entries(x).map(([key, value]) => {
          if (key === "col" + (index + 1).toString()){
              average = average + (parseInt(interval) * value);
              if (value > 0) counter+=value;
          }
        })
      })

      return average / counter;
    }

  function getHeaders():LabelKeyObject[]{
    var data:LabelKeyObject[] = [];

    data.push({
      label:"Draw Date",
      key: "drawDate"
    });

    if (analysis && analysis.data && analysis.data.length > 0) {
        analysis.intervals.split(',').map((x, index)=>{

          data.push({
            label:x,
            key: "col" + (index + 1).toString()
          })
            return true;
        })
    }

    data.push({
      label:"Interval Avg",
      key: "average"
    });

      return data;
  }    

    useEffect(()=>{
          agent.DrawTotoResult.getAnalysisByInterval(tempNoOfDraw,  useBonus).then((response)=>{
              var tempData = response;  
              tempData.data.map((x) => {
                x.average = parseFloat(getAverage(x).toFixed(1));
              });
              setAnalysis(tempData);
              setLoading(false);
              
          });
    },[noOfDraw, useBonus]);


    if(!userStore.isLogged){
      return (<Header>Not Authorized</Header>)
    }

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto",height:windowDimensions.height - 160}}>
        <Table celled unstackable selectable singleLine size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
                        <Input fluid onChange={onDrawNoChanged} maxLength={4} onKeyPress={(event:any) => {
                                if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }}
                        icon={<Icon name='search' inverted circular link onClick={noOfDrawClicked} />}
                        placeholder="No of Draws" defaultValue={tempNoOfDraw}></Input>
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="3">
                  <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged}>
                  </Checkbox>
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={bodyColSpan} textAlign="center">
              <Header>ANALYSIS BY INTERVAL</Header>
            </Table.HeaderCell>                  
            <Table.HeaderCell colSpan="3" textAlign="center">
                    <CSVLink data={getData()} filename="AnalysisByInterval" headers={getHeaders()}>
                                <Icon name="download"></Icon>Download 
                    </CSVLink>         

            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>
               Draw Date/Interval
            </Table.HeaderCell>
            {
              analysis && analysis.intervals.split(',').map(x=>{

                return (<Table.HeaderCell textAlign="center">
                <Popup hoverable content={displayNumbers(analysis.currentIntervals, x)} position="bottom center" trigger={
                  <Label>{x}</Label>                      
                  }></Popup>  
                </Table.HeaderCell>);
              })
            }
            <Table.HeaderCell textAlign="center">
                Average
            </Table.HeaderCell>
         </Table.Row>
        </Table.Header>
    
        <Table.Body>

          {analysis && analysis.data.map((x => {
            return (
                <Table.Row key={x.drawDate} className="freeze-head-and-col">
                    <Table.Cell textAlign="center">
                        {x.drawDate}
                    </Table.Cell>
                    {
                      analysis.intervals.split(',').map((y:string, index: number) => {
                            return (<Table.Cell textAlign="center">
                              {Object.entries(x).map(([key, value]) => {
                                  if (key === "col" + (index + 1).toString()){
                                      return value === 0 ? "" : value + "";
                                  }
                                  return null;
                                })
                              }
                            </Table.Cell>)                            
                      })
                    }
                    <Table.Cell textAlign="center">
                      { 
                        x.average
                      }
                    </Table.Cell>
                </Table.Row>
              );
            }))
          }   
           </Table.Body>
      </Table>
   
      </div>
    );
});