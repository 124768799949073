import React from "react";
import { Button,  Grid, Header, Icon, Item, Segment, Statistic } from "semantic-ui-react";
import { useStore } from "../../app/store/store";
import EditDisplayName from "./EditDisplayName";

export default function ProfileHeader(){
    const {userStore: {user, getPhotoURL}, modalStore} = useStore();


    const photoPath = getPhotoURL();

    return (
        <Segment>
            <Grid>
                <Grid.Row>
                <Grid.Column width={10}  verticalAlign="middle">
                    <Item.Group>
                        <Item>
                            <Item.Image avatar size="small" src={photoPath}>
                            </Item.Image>
                            <Item.Content verticalAlign="middle">
                            <Item.Meta>
                               <Header as="h1"> {user?.displayName} </Header>
                            </Item.Meta>
                            <Item.Extra>
                            <Button primary icon="edit" onClick={() =>{
                                        modalStore.openModal(<EditDisplayName></EditDisplayName>,"Edit Profile","large", null,()=>{})
                                    }}>
                                    <Icon name="edit"></Icon>Edit
                            </Button>
                            </Item.Extra>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Grid.Column>  
                <Grid.Column width={6} stretched verticalAlign="middle">
                <Segment>
                <Statistic.Group widths="2">
                <Statistic size="small">
                    <Statistic.Value>{user?.subscription}</Statistic.Value>
                    <Statistic.Label>Subscription End</Statistic.Label>
                </Statistic>
                <Statistic size="small">
                    <Statistic.Value><Icon name="disk"></Icon>&nbsp;{user?.taken}/{user?.noOfDevice}</Statistic.Value>
                    <Statistic.Label>Device</Statistic.Label>
                </Statistic>
                
                </Statistic.Group>
                </Segment>
                </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}  verticalAlign="middle">
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}