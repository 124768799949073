import { ErrorMessage, Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { Button,  Divider,  Label } from "semantic-ui-react";
import * as Yup from 'yup';
import { _GetBonusText, _GetFilterName } from "../../app/lib/CommonFunctions";
import { MyCheckBoxField } from "../../app/lib/MyCheckBoxField";
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import { WheelingCondition } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";


export default observer(function FilterCoverage(){
    const {userStore, modalStore} = useStore();
    

    const validationSchema = Yup.object({
        coverage: Yup.number().required("Coverage is required !").positive().integer().min(2,"Minimum value is 2!").max(6,"Maximum is 6 !"),
        matched: Yup.number().required("No Of Match is required !").positive().integer().min(2,"No of Matched must be greater than or equal to 2 ").max(12,"Maximum matched cannot be greater than 12").test("checkGreater","No Of Matched must be greater or equal to coverage !",(value, ctx)=> Number(ctx.parent.coverage) <= Number(value)),
        max: Yup.number().required("Maximum is required !").positive().integer().min(0,"Minimum cannot be smaller than 0 ").max(12,"Maximum cannot be greater than 12"),
    });

    return (
        <Formik initialValues={{ coverage: 4,  max: 2, matched : 6, error: null}}
                onSubmit={
                    (values,{setErrors}) => {
                        const coverage = values["coverage"];
                        const minValue = values["max"];
                        const maxValue = values["max"];

                        var filterCondition: WheelingCondition = {
                            filterType : "98",
                            filterTypeName: _GetFilterName("98"),
                            data : coverage + "|" + values["matched"],
                            min: Number(minValue),
                            max: Number(maxValue)
                        };

                        localStorage.setItem("FilterCondition", JSON.stringify(filterCondition));
                        modalStore.closeModal();
                    }
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <MyTextInputNonFluid data-tooltip="Coverage to filter" name="coverage" placeholder="Enter Coverage (3 to 5)"></MyTextInputNonFluid>
                        <MyTextInputNonFluid data-tooltip="Of Numbers Matched" type="number" name="matched" placeholder="Of Numbers Matched"></MyTextInputNonFluid>
                        <MyTextInputNonFluid data-tooltip="Maximum value to filter" type="number" name="max" placeholder="Enter maximum"></MyTextInputNonFluid>

                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Divider />
                        <Button icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>
                    </Form>
                
            )}
        </Formik>
    )
})