import { observer } from "mobx-react-lite";
import { Header, Label, Table } from "semantic-ui-react";
import { WeeklyForecastValues } from "../../app/models/user";

interface Props {
    data: WeeklyForecastValues[];
    isMobile: boolean;
}
export default observer(function WeeklyforecastSet(prop: Props) {
    var keyNumbers: string[] = [];
    var allNumbers: string[] = [];
    var maxCol = 0;

    prop.data.map((x) => {
        if (x.numbers.split(',').length > maxCol) {
            maxCol = x.numbers.split(',').length;
        }
        x.numbers.split(',').map((y) => {
            if (y.endsWith("*")) {
                keyNumbers.push(y)
            }
            if (y.endsWith("+")) {
                allNumbers.push(y.replace("+", ""));
            }
            return true;
        })
        return true;
    })

    return (
        <div>
            <Table celled unstackable selectable singleLine size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.Cell>
                            <Header as={prop.isMobile ? "h3" : "h2"}>Forecast Numbers: {keyNumbers.join(", ")} - {allNumbers.join(", ")}
                            </Header>
                        </Table.Cell>
                    </Table.Row>
                </Table.Header>
            </Table>
            <Table celled unstackable selectable singleLine size="small">
                {
                    prop.data.map((x, index) => {
                        if (prop.isMobile) {
                            return (<Table.Row key={index}>
                                {
                                    x.numbers.split(',').map((y, yIndex) => {
                                        return (<Table.Cell textAlign="center">
                                            <Label size="medium" circular color={y.endsWith("*") ? "red" : (y.endsWith("+") ? "blue" : "grey")}>
                                                {y.replace("+", "")}
                                            </Label>
                                        </Table.Cell>);
                                    })
                                }
                            </Table.Row>)
                        }
                        else {
                            return (<Table.Row key={index}>
                                {
                                    x.numbers.split(',').map((y, yIndex) => {
                                        return (<Table.Cell textAlign="center">
                                            <Label size="huge" circular color={y.endsWith("*") ? "red" : (y.endsWith("+") ? "blue" : "grey")}>
                                                {y.replace("+", "")}
                                            </Label>
                                        </Table.Cell>);
                                    })
                                }
                            </Table.Row>)
                        }
                    })
                }
            </Table >
        </div>
    )
});