import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { Button,  Label } from "semantic-ui-react";
import * as Yup from 'yup';
import { history } from "../..";
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import { useStore } from "../../app/store/store";


export default observer(function ChangeDevice(){
    const {userStore} = useStore();


    if(userStore.isAdmin){
        history.push("/");
    }
    
    const validationSchema = Yup.object({
        email: Yup.string().email().required("Email is required or Invalid Email"),
        noOfDevice: Yup.number().required("Number Of Device is required").min(1, "Minimum is 1 device !")
    });

    const defDate = moment().add(1, "y").format("DD/MM/yyyy")
    return (
        <Formik initialValues={{id: "", remark: "", price: 0, noOfDevice:2 , userName: "", phoneNo: "", email: "", password:"", displayName: "",  expiryDate: defDate, deviceID: "", error: null}}
                onSubmit={
                    (values,{setErrors}) => userStore.changeDevice(values).catch(err => setErrors({error: 'Unable to change/reset device !'}))
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <MyTextInputNonFluid name="email" placeholder="Email"></MyTextInputNonFluid>
                        <MyTextInputNonFluid maxLength={2} type="number" name="noOfDevice" placeholder="Restrict to Number of Devices"></MyTextInputNonFluid>

                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Button icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>
                    </Form>
                
            )}
        </Formik>
    )
})