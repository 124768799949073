import { observer } from "mobx-react-lite";
import React from "react";
import { Redirect } from "react-router";
import { Header, Icon, Message, Segment } from "semantic-ui-react";
import { useStore } from "../../app/store/store";
import LatestDraw from "./LatestDraw";

export default observer(function HomePage(){
    const {userStore} = useStore();

    if (userStore.isLogged){
        return (
            <Redirect to='/overview' />            
        )
        //history.push("/forecast");
        
    }
    return (
        <>
         <Message warning>
             <Icon name="exclamation circle"></Icon> 
            Please exit this website if you are under 18. Click <a href='/about'>here</a> to contact us.
        </Message>
        <LatestDraw />
        <Segment style={{marginTop: "100px"}}>
        <Header>
        Disclaimer:
        </Header>
        <p>
        The information provided on totograndmaster.com (the “Site”) is for general informational &amp; education purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site.
        </p>
        <p>
        Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site [or our mobile application] or reliance on any information provided on the site. Your use of the site and your reliance on any information on the site is solely at your own risk. 
        </p>
        <p>
        The publisher does not claim to provide its readers with a foolproof method of forecasting winning lottery numbers. The Toto Grand Master software is designed to serve merely as a guide for Toto players.  All efforts and care have been taken to ensure the accuracy of the information and data presented in this software. The Publisher, the staff, partners or associates, however, assume no responsibility and shall not have any liability for any kind arising from the use of this software.
        </p>
        </Segment>

        <Segment>
                <u>Term &amp; Condition</u><br/> 
                The TotoGM software is licensed to you for the sole and personal use as a registered subscriber.<br/>
                You are allowed to access on two of your selected own devices.<br/>
                The first two logins on your devices will be registered as authorised devices. <br/>
                Any other person or device is deemed as unauthorised user/ device.<br/>
                If detected, it may result in a locked out from accessing the software

        </Segment>
        </>
    )
})