import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Header, Label, Table } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { _getWindowDimensions } from "../../app/lib/CommonFunctions";
import { HotWarmColdSummary } from "../../app/models/IDayResult";

export default observer(function DrawSummary(){
    const useBonus = localStorage.getItem("useBonus") === "1" ? true: false;
    const [data, setData]= useState<HotWarmColdSummary[]>([]);
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(_getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    
    
    
    useEffect(()=>{
        agent.DrawTotoResult.getHotWarmColdSummary(useBonus).then((response)=>{
            setData(response);
        });

    }, [useBonus])
    return(
        <div style={{overflowX:"auto"}}>
        <div style={{overflowX:"auto", height:windowDimensions.height - 160}}>
        <Table size="large" celled unstackable selectable singleLine>
        <Table.Header>
        <Table.Row key="header1">
          <Table.HeaderCell textAlign="center">
            Number</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            No Of Matched
          </Table.HeaderCell>
        </Table.Row>
        </Table.Header>
        <Table.Body>
            {
                data.map((o, index)=>{
                    return (
                        <Table.Row key={index} error={o.ball_Type === "0-H" ? true: false}>
                            <Table.Cell textAlign="center">
                                <Label color={o.ball_Type === "0-H" ? "red" : (o.ball_Type === "1-W" ? "yellow" : "blue")}>
                                    {o.ball_No}
                                </Label>
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                <Label color={o.ball_Type === "0-H" ? "red" : (o.ball_Type === "1-W" ? "yellow" : "blue")}>
                                    {o.no_Of_Matched}
                                </Label>
                            </Table.Cell>
                        </Table.Row>
                    );
                })
            }
        </Table.Body>
        </Table>
        </div>
        </div>);
});