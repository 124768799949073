import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {  Label, Pagination, PaginationProps, Table } from "semantic-ui-react";
import agent from "../../app/api/agent";
import SplashScreen from "../../app/lib/SplashScreen";
import { TotoResult } from "../../app/models/IDayResult";
import moment from "moment";
import { _getWindowDimensions } from "../../app/lib/CommonFunctions";

export default observer(function LatestDraw(){
    const [data, setData] = useState<TotoResult[]>([]);
    const [isLoading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const totalPage = 10;
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(_getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    

    const handlePageChanged = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: PaginationProps)=>{
        setPage(parseInt(data.activePage!.toString()));
      }
  
    useEffect(()=>{
        agent.DrawTotoResult.getTotoResult(page, true).then((response) =>{
            setData(response);
            setLoading(false);        
        });
    }, [isLoading, page]);
    
    if(isLoading || data.length === 0){
        return (<SplashScreen></SplashScreen>)
    }
    return (
        <div  style={{overflow:"auto", height: windowDimensions.height - 160}}>
            <Table celled unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="center">
                            Draw Date
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">1</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">2</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">3</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">4</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">5</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">6</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">B</Table.HeaderCell>

                    </Table.Row>
                </Table.Header>
                <Table.Body>
                {data.map((x) => {
                    return(
                    <Table.Row key={x.totoResultID}>
                        <Table.Cell textAlign="center">
                            {moment(x.drawDate).format("yyyy-MM-DD (ddd)")}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Label circular size="big" className="font-number" color="blue">{x.ball1}</Label>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Label circular size="big" className="font-number" color="blue">{x.ball2}</Label>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Label circular size="big" className="font-number" color="blue">{x.ball3}</Label>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Label circular size="big" className="font-number" color="blue">{x.ball4}</Label>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Label circular size="big" className="font-number" color="blue">{x.ball5}</Label>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Label circular size="big" className="font-number" color="blue">{x.ball6}</Label>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Label circular size="big" className="font-number" color="orange">{x.ball7}</Label>
                        </Table.Cell>

                    </Table.Row>);
                })}
                </Table.Body>

                <Table.Footer>
                <Table.Row>
                <Table.Cell colSpan="8">
                <Pagination activePage={page} totalPages={totalPage} onPageChange={handlePageChanged}/>
                </Table.Cell>
                </Table.Row>

                </Table.Footer>
            </Table>            
        </div>
    );

}
)
