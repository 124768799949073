import { observer } from "mobx-react-lite";
import React, { useState } from "react"
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import {  Table, Header, Checkbox, CheckboxProps, Icon } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import { _GetBonusText, _getWindowDimensions } from "../../app/lib/CommonFunctions";
import DrawLink from "../../app/lib/DrawLink";
import LoadingComponent from "../../app/lib/LoadingComponent";
import MyTableWithSort from "../../app/lib/MyTableWithSort";
import { AnalysisByYearResult } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";

export default observer(function Analysis(){
    
    const curYear = new Date().getFullYear();

    const [loading, setLoading] = useState(true);
    const [useBonus, setUseBonus] = useState(true);
    const [analysis, setAnalysis] = useState<AnalysisByYearResult[]>([]);
    const [column, setColumn] = useState("total");
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("descending");
    const {userStore} = useStore();
    const headers = [{label: "Ball Number",key: "ball"},
                    {label: "Total", key: "total"},
                    {label:"Average Hit", key: "avg_Hit"},
                    {label: "Average 10 Years",key:"avg_10Year"},
                    {label:curYear.toString(), key: "current_year"},
                    {label:(curYear - 1).toString(), key: "year1"},
                    {label:(curYear - 2).toString(), key: "year2"},
                    {label:(curYear - 3).toString(), key: "year3"},
                    {label:(curYear - 4).toString(), key: "year4"},
                    {label:(curYear - 5).toString(), key: "year5"},
                    {label:(curYear - 6).toString(), key: "year6"},
                    {label:(curYear - 7).toString(), key: "year7"},
                    {label:(curYear - 8).toString(), key: "year8"},
                    {label:(curYear - 9).toString(), key: "year9"},
                    {label:(curYear - 10).toString(), key: "year10"},
                  
                  ]        
    //const periodDisplay = (curYear - 11).toString() + " to " + (curYear - 1).toString();
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(_getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    

    const onBonusChanged =  (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps )=>{
      setUseBonus(data.checked!);
      setLoading(true);
    }

    const handleColumnClick = (columnName : string)=>{
      if (column !== columnName){
        setDirection("ascending");
      }
      else{
        if (direction === undefined){
          setDirection('ascending');
        }
        else{
          setDirection(direction === "ascending" ? "descending" : "ascending");
        }
      }
      setColumn(columnName);
      setLoading(true);

    }

    useEffect(()=>{

        let newDirection = "none";
        if (direction !== undefined){
          if (direction === "ascending"){
            newDirection = "asc";
          }
          else{
            newDirection = "desc";
          }
        }

        agent.DrawTotoResult.getAnalysisByYear(useBonus, column, newDirection).then((response)=>{

            setAnalysis(response);
            setLoading(false);
            
        });

        
    },[useBonus, column, direction]);


    if(!userStore.isLogged){
      return (<Header>Not Authorized</Header>)
    }

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto",height:windowDimensions.height - 160}}>
        <Table celled unstackable selectable sortable singleLine size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2">
                <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged}>
                </Checkbox>
              </Table.HeaderCell>
              <Table.HeaderCell colSpan="11" textAlign="center">
                <Header>ANALYSIS BY YEAR</Header>
              </Table.HeaderCell>
              <Table.HeaderCell colSpan="2" textAlign="center">
                        <CSVLink data={analysis} filename="AnalysisByYear" headers={headers}>
                            <Icon name="download"></Icon>Download 
                        </CSVLink>         

              </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="ball" handleClick={handleColumnClick}>
              Ball<br/>Number
            </MyTableWithSort> 

            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="total" handleClick={handleColumnClick}>
            Total
            </MyTableWithSort> 

            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="avg_Hit" handleClick={handleColumnClick}>
            Average
            </MyTableWithSort> 
           
            <Table.HeaderCell textAlign="center" colSpan="12">
            No of Strike in Year
            </Table.HeaderCell>
             
          </Table.Row>
          <Table.Row>
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="avg_10year" handleClick={handleColumnClick}>
              Avg <br/>10 Years
          </MyTableWithSort>             
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="current_Year" handleClick={handleColumnClick}>
              {(curYear).toString().substring(2)}
          </MyTableWithSort> 
          

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="year1" handleClick={handleColumnClick}>
              {(curYear - 1).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="year2" handleClick={handleColumnClick}>
              {(curYear - 2).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="year3" handleClick={handleColumnClick}>
              {(curYear - 3).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="year4" handleClick={handleColumnClick}>
              {(curYear - 4).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="year5" handleClick={handleColumnClick}>
              {(curYear - 5).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="year6" handleClick={handleColumnClick}>
              {(curYear - 6).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="year7" handleClick={handleColumnClick}>
              {(curYear - 7).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="year8" handleClick={handleColumnClick}>
              {(curYear - 8).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="year9" handleClick={handleColumnClick}>
              {(curYear - 9).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="year10" handleClick={handleColumnClick}>
              {(curYear - 10).toString().substring(2)}
          </MyTableWithSort> 
         </Table.Row>
        </Table.Header>
    
        <Table.Body>

          {analysis.map((x => {
            return (
                <Table.Row key={x.ball} className="freeze-head-and-col">
                    <Table.Cell textAlign="center">
                      <DrawLink number={x.ball.toString()} bonus={useBonus}></DrawLink>
                    </Table.Cell>
                    <Table.Cell  textAlign="right">{x.total}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.avg_Hit}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.avg_10Year}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.current_Year}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.year1}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.year2}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.year3}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.year4}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.year5}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.year6}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.year7}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.year8}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.year9}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.year10}</Table.Cell>
                </Table.Row>
              );
            }))
          }   
           </Table.Body>
      </Table>
   
      </div>
    );
});