import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {  Checkbox, CheckboxProps, Header, Icon, Label, Pagination, PaginationProps, Table } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { TotoResult } from "../../app/models/IDayResult";
import moment from "moment";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { CSVLink } from "react-csv";
import { _GetBonusText, _getWindowDimensions } from "../../app/lib/CommonFunctions";

export default observer(function TotoResult(){
    const [useBonus, setUseBonus] = useState(true);
    
    const [data, setData] = useState<TotoResult[]>([]);
    const [isLoading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const totalPage = 10;
    const headers = [{label: "Draw Date",key: "drawDate"},
                     {label: "Ball 1", key: "ball1"},
                     {label:"Ball 2", key: "ball2"},
                    {label: "Ball 3",key:"ball3"},
                    {label:"Ball 4", key: "ball4"},
                    {label:"Ball 5", key: "ball5"},
                    {label:"Ball 6", key: "ball6"},
                    {label:"Additonal Number", key: "ball7"},
                    {label:"Sum", key: "sum"},
                    {label:"Range", key: "range"},
                    {label:"Odd/Even", key: "oe"},
                    {label:"Low/Med/High", key: "lmh"},
                    {label:"Low/High", key: "lh"},
                    {label:"Sum (Bonus)", key: "sum1"},
                    {label:"Range (Bonus)", key: "range1"},
                    {label:"Odd/Even (Bonus)", key: "oE1"},
                    {label:"Low/Med/High (Bonus)", key: "lowMediumHigh1"},
                    {label:"Low/High (Bonus)", key: "lH1"}
                    ]    
    const handlePageChanged = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: PaginationProps)=>{
        setPage(parseInt(data.activePage!.toString()));
      }
      const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());
      
      const onBonusChanged =  (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps )=>{
        setUseBonus(data.checked!);
        setLoading(true);
      }
  
      useEffect(() => {
          function handleResize() {
            setWindowDimensions(_getWindowDimensions());
          }
      
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
      }, []);    
    
    useEffect(()=>{
        agent.DrawTotoResult.getTotoResult(page, useBonus).then((response) =>{
            setData(response);
            setLoading(false);        
        });
    }, [isLoading, page, useBonus]);
    
    if(isLoading || data.length === 0){
        return (<LoadingComponent></LoadingComponent>)
    }
    return (
        <div style={{overflow:"auto", height:windowDimensions.height - 160}}>
            <Table celled unstackable>
                <Table.Header>
                    <Table.Row>
                    <Table.HeaderCell colSpan="2">
                        <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged}>
                        </Checkbox>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center" colSpan="9">
                        <Header color="black">RESULT</Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right" colSpan="2">
                        <CSVLink data={data} filename="Ordinary" headers={headers}>
                            <Icon name="download"></Icon>Download 
                        </CSVLink>         
                    </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell textAlign="center">
                            Draw Date
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">1</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">2</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">3</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">4</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">5</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">6</Table.HeaderCell>
                        {useBonus &&
                        <Table.HeaderCell textAlign="center">Additional</Table.HeaderCell>
                        }
                        <Table.HeaderCell textAlign="center">Range</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Sum</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">O/E</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">L/M/H</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">L/H</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                {data.map((x) => {
                    return(
                    <Table.Row key={x.totoResultID}>
                        <Table.Cell textAlign="center">
                            {moment(x.drawDate).format("yyyy-MM-DD (ddd)")}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Label circular size="big" className="font-number" color="blue">{x.ball1}</Label>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Label circular size="big" className="font-number" color="blue">{x.ball2}</Label>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Label circular size="big" className="font-number" color="blue">{x.ball3}</Label>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Label circular size="big" className="font-number" color="blue">{x.ball4}</Label>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Label circular size="big" className="font-number" color="blue">{x.ball5}</Label>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Label circular size="big" className="font-number" color="blue">{x.ball6}</Label>
                        </Table.Cell>
                        {useBonus &&
                        <Table.Cell textAlign="center">
                            <Label circular size="big" className="font-number" color="orange">{x.ball7}</Label>
                        </Table.Cell>
                        }
                        <Table.Cell textAlign="center">
                            {useBonus ? x.range1 : x.range}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {useBonus ? x.sum1 : x.sum}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {useBonus ? x.oE1 : x.oe}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {useBonus ? x.lowMediumHigh1 : x.lmh}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {useBonus ? x.lH1 : x.lh}
                        </Table.Cell>

                    </Table.Row>);
                })}
                </Table.Body>

                <Table.Footer>
                <Table.Row>
                <Table.Cell colSpan="9">
                <Pagination activePage={page} totalPages={totalPage} onPageChange={handlePageChanged}/>
                </Table.Cell>
                </Table.Row>

                </Table.Footer>
            </Table>            
        </div>
    );

}
)
