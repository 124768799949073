import { observer } from "mobx-react-lite";
import React, { useState } from "react"
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { LabelKeyObject } from "react-csv/components/CommonPropTypes";
import { toast } from "react-toastify";
import {  Table, Header, Checkbox, CheckboxProps, Input, Icon, InputOnChangeData, Popup, Dropdown, DropdownProps,Label, Button } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import { _GetBonusText, _getWindowDimensions } from "../../app/lib/CommonFunctions";
import LoadingComponent from "../../app/lib/LoadingComponent";
import {  AnalysisByGroupDataResult, AnalysisByGroupResult, GroupNumbers, MyDropDownStringItems} from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";

export default observer(function Analysis(){

    function getGroupName(groupID: string){
      if (!groupID || groupID.length == 0){
        return "GROUP";
      }

      var retVal = "";
      groups.map((x) => {
        if (x.value === groupID){
          retVal = x.text;
          return false;
        }
        return true;
      });
      return retVal.toUpperCase();
    }


    const displayNumbers=(groupNumbers: GroupNumbers[], code: string) => {
      return groupNumbers.map((x) => {
        if (x.code === code){
          return x.numbers;
        }
        return "";
      })
    }

    function getData():AnalysisByGroupDataResult[]
    {   if (!analysis) return [];

        return analysis!.data;
    }    


  function getHeaders():LabelKeyObject[]{
    var data:LabelKeyObject[] = [];

    data.push({
      label:"Draw Date",
      key: "drawDate"
    });

    if (analysis && analysis.data && analysis.data.length > 0) {
        analysis.groupNames.split(',').map((x, index)=>{

          data.push({
            label:x,
            key: "col" + (index + 1).toString()
          })
            return true;
        })
    }
      return data;
  }    


    const [noOfDraw, setNoOfDraw] = useState(50);
    const [loading, setLoading] = useState(true);
    const [useBonus, setUseBonus] = useState(true);
    const [analysis, setAnalysis] = useState<AnalysisByGroupResult>();
    const {userStore} = useStore();
    const [group, setGroup] = useState("");
    const [tempNoOfDraw, setTempNoOfDraw] = useState(noOfDraw);
    const [groups, setGroups] = useState<MyDropDownStringItems[]>([]);
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(_getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    

    //const periodDisplay = (curYear - 11).toString() + " to " + (curYear - 1).toString();
    const noOfDrawClicked = () =>{
      setNoOfDraw(tempNoOfDraw);
      setLoading(true);
    }

    const onDrawNoChanged = (event: React.ChangeEvent<HTMLElement>, data: InputOnChangeData )=>{
     
      if (Number(data.value)){
          setTempNoOfDraw(Number(data.value));
      }
      else{
          toast.error("No of Draw must be numbers !");
      }
  }    

    const onBonusChanged =  (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps )=>{
      setUseBonus(data.checked!);
      setLoading(true);
    }

    const dropDownChanged = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps ) => {
        setGroup(data.value + "");
    }

    var groupNamesLength = (!analysis ? 0 : analysis.groupNames.split(',').length)

    useEffect(()=>{
      agent.DrawTotoResult.getGroup().then((response)=>{
          setGroups(response);
          setGroup(response[0].value)
      });
    },[]);

    useEffect(()=>{
        if (group.length > 0){
          agent.DrawTotoResult.getAnalysisByGroup(tempNoOfDraw, group, useBonus).then((response)=>{

              setAnalysis(response);
              
              setLoading(false);
              
          });
        }

        
    },[noOfDraw, group, useBonus]);


    if(!userStore.isLogged){
      return (<Header>Not Authorized</Header>)
    }

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }

    
    return (
      <div style={{overflowX:"auto",height:windowDimensions.height - 160}}>
        <Table celled unstackable selectable singleLine size="small">
        <Table.Header>
          <Table.Row key="Hdr1">
                <Table.HeaderCell>
                  <Popup content="Please select group" position="top center" trigger={
                        <Dropdown  fluid   style={{zIndex:21}}
                            selection data-moveup="false"
                            onChange = {dropDownChanged}
                            placeholder="Select Group"
                            options={groups}
                            defaultValue = {group}
                            direction = "right"
                        />     
                      }>
                      </Popup>
                </Table.HeaderCell>

               <Table.HeaderCell>
                <Input fluid onChange={onDrawNoChanged} maxLength={4} onKeyPress={(event:any) => {
                                  if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                  }
                              }}
                          icon={<Icon name='search' inverted circular link onClick={noOfDrawClicked} />}
                          placeholder="No of Draws" defaultValue={tempNoOfDraw}></Input>
                
                </Table.HeaderCell>     
                <Table.HeaderCell>
                <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged}/>
                </Table.HeaderCell>
                
                <Table.HeaderCell textAlign="center" colSpan={groupNamesLength === 2 ? 1: groupNamesLength - 1}>
                      <Header>ANALYSIS BY {getGroupName(group)}</Header>
                </Table.HeaderCell>                                
                <Table.HeaderCell textAlign="center" colSpan={groupNamesLength === 2 ? 1 : 2}>
                    <CSVLink data={getData()} filename="AnalysisByGroup" headers={getHeaders()}>
                                <Icon name="download"></Icon>Download 
                    </CSVLink>         

                </Table.HeaderCell>  
          </Table.Row>
          <Table.Row key="Hdr2">
            <Table.HeaderCell>
               Draw Date
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="2" textAlign="center">
                Direct Pattern
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="2" textAlign="center">
                Group Pattern
            </Table.HeaderCell>
            {
              analysis && analysis.groupNames.split(',').map(x=>{

                return (<Table.HeaderCell textAlign="center">
                <Popup hoverable content={displayNumbers(analysis.groupNumbers, x)} position="bottom center" trigger={
                  <Label>{x}</Label>                      
                  }></Popup>  
                </Table.HeaderCell>);
              })
            }
         </Table.Row>
         <Table.Row key="Hdr3">
                    <Table.HeaderCell textAlign="center">
                    Performance Stats
                    </Table.HeaderCell>
                    {
                      
                      analysis && analysis.groupNames.split(',').map((y:string, index: number) =>{

                        return (<Table.HeaderCell textAlign="center">
                          <span>{analysis.summary["COL" + (index+1)]}<br/>
                                {((analysis.summary["COL" + (index+1)] / analysis.totalSum) * 100).toFixed(0) + ' %'}
                          </span>                      
                        </Table.HeaderCell>);
                      })
                    }

         </Table.Row>
        </Table.Header>
    
        <Table.Body>

          {analysis && analysis.data.map((x => {
            return (
                <Table.Row key={x.drawDate} className="freeze-head-and-col">
                    <Table.Cell textAlign="center">
                        {x.drawDate}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.direct_Pattern}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                        {x.pattern}
                  </Table.Cell>
                    
                    {
                      analysis.groupNames.split(',').map((y:string, index: number) => {
                            return (<Table.Cell textAlign="center">
                              {Object.entries(x).map(([key, value]) => {
                                  if (key === "col" + (index + 1).toString()){
                                      return value;
                                  }
                                  return null;
                                })
                              }
                            </Table.Cell>)                            
                      })

                   
                    }
                </Table.Row>
              );
            }))
          }   
           </Table.Body>
      </Table>
   
      </div>
    );
});