import { observer } from "mobx-react-lite";
import React, { useState } from "react"
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import {  Table, Header, Checkbox, CheckboxProps, Icon } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import { _GetBonusText, _GetShortMonthName, _getWindowDimensions } from "../../app/lib/CommonFunctions";
import DrawLink from "../../app/lib/DrawLink";
import LoadingComponent from "../../app/lib/LoadingComponent";
import MyTableWithSort from "../../app/lib/MyTableWithSort";
import { AnalysisByMonthResult } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";

export default observer(function Analysis(){
    

    const [loading, setLoading] = useState(true);
    const [useBonus, setUseBonus] = useState(true);
    const [analysis, setAnalysis] = useState<AnalysisByMonthResult[]>([]);
    const [column, setColumn] = useState("month" + (new Date().getMonth() + 1));
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("descending");
    const {userStore} = useStore();
    const headers = [{label: "Ball Number",key: "ball"},
                    {label: "JAN", key: "month1"},
                    {label:"FEB", key: "month2"},
                    {label: "MAR",key:"month3"},
                    {label: "APR", key: "month4"},
                    {label: "MAY", key: "month5"},
                    {label: "JUN",key:"month6"},
                    {label: "JUL", key: "month7"},
                    {label:"AUG", key: "month8"},
                    {label: "SEP",key:"month9"},
                    {label: "OCT", key: "month10"},
                    {label:"NOV", key: "month11"},
                    {label: "DEC",key:"month12"}
                  ]        
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());

    useEffect(() => {
      function handleResize() {
          setWindowDimensions(_getWindowDimensions());
      }
                  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);    
              
    //const periodDisplay = (curYear - 11).toString() + " to " + (curYear - 1).toString();

    const onBonusChanged =  (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps )=>{
      setUseBonus(data.checked!);
      setLoading(true);
    }

    const handleColumnClick = (columnName : string)=>{
      if (column !== columnName){
        setDirection("ascending");
      }
      else{
        if (direction === undefined){
          setDirection('ascending');
        }
        else{
          setDirection(direction === "ascending" ? "descending" : "ascending");
        }
      }
      setColumn(columnName);
      setLoading(true);

    }

    useEffect(()=>{

        let newDirection = "none";
        if (direction !== undefined){
          if (direction === "ascending"){
            newDirection = "asc";
          }
          else{
            newDirection = "desc";
          }
        }

        agent.DrawTotoResult.getAnalysisByMonth(useBonus, column, newDirection).then((response)=>{

            setAnalysis(response);
            setLoading(false);
            
        });

        
    },[useBonus, column, direction]);


    if(!userStore.isLogged){
      return (<Header>Not Authorized</Header>)
    }

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto",height:windowDimensions.height - 160}}>
        <Table celled unstackable selectable sortable singleLine size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2">
                <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged}>
                </Checkbox>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center" colSpan="9">
              <Header>ANALYSIS BY MONTH</Header>
              </Table.HeaderCell>

              <Table.HeaderCell textAlign="center" colSpan="2">
                        <CSVLink data={analysis} filename="AnalysisByMonth" headers={headers}>
                            <Icon name="download"></Icon>Download 
                        </CSVLink>         

              </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="ball" handleClick={handleColumnClick}>
              Ball<br/>Number
            </MyTableWithSort> 

            <Table.HeaderCell textAlign="center" colSpan="12">
            No of Strike By Month
            </Table.HeaderCell>
             
          </Table.Row>
          <Table.Row>
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="month1" handleClick={handleColumnClick}>
               { _GetShortMonthName(0)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="month2" handleClick={handleColumnClick}>
          { _GetShortMonthName(1)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="month3" handleClick={handleColumnClick}>
          { _GetShortMonthName(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="month4" handleClick={handleColumnClick}>
          { _GetShortMonthName(3)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="month5" handleClick={handleColumnClick}>
          { _GetShortMonthName(4)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="month6" handleClick={handleColumnClick}>
          { _GetShortMonthName(5)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="month7" handleClick={handleColumnClick}>
          { _GetShortMonthName(6)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="month8" handleClick={handleColumnClick}>
          { _GetShortMonthName(7)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="month9" handleClick={handleColumnClick}>
          { _GetShortMonthName(8)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="month10" handleClick={handleColumnClick}>
          { _GetShortMonthName(9)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="month11" handleClick={handleColumnClick}>
          { _GetShortMonthName(10)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="month12" handleClick={handleColumnClick}>
          { _GetShortMonthName(11)}
          </MyTableWithSort> 

         </Table.Row>
        </Table.Header>
    
        <Table.Body>

          {analysis.map((x => {
            return (
                <Table.Row key={x.ball} className="freeze-head-and-col">
                    <Table.Cell textAlign="center">
                      <DrawLink number={x.ball.toString()} bonus={useBonus}></DrawLink>
                    </Table.Cell>
                    <Table.Cell  textAlign="right">{x.month1}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.month2}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.month3}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.month4}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.month5}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.month6}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.month7}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.month8}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.month9}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.month10}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.month11}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.month12}</Table.Cell>
                </Table.Row>
              );
            }))
          }   
           </Table.Body>
      </Table>
   
      </div>
    );
});