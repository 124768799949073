import { observer } from "mobx-react-lite";
import React from "react"
import { Tab } from "semantic-ui-react";
import AnalysisByDrawCycleAvg from "./AnalysisByDrawCycleAvg";
import AnalysisByDrawCycleCurrent from "./AnalysisByDrawCycleCurrent";
import AnalysisByLastDrawCycle from "./AnalysisByLastDrawCycle";

export default observer(function AnalysisByDrawCycle(){

  const panes = [
    {
      menuItem: 'Average',
      render: () => <Tab.Pane attached={false}><AnalysisByDrawCycleAvg></AnalysisByDrawCycleAvg></Tab.Pane>,
    },
    {
      menuItem: 'Current',
      render: () => <Tab.Pane attached={false}><AnalysisByDrawCycleCurrent></AnalysisByDrawCycleCurrent></Tab.Pane>,
    }
    ,
    {
      menuItem: 'Last',
      render: () => <Tab.Pane attached={false}><AnalysisByLastDrawCycle></AnalysisByLastDrawCycle></Tab.Pane>,
    }
  ]  
    return (
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    );

});