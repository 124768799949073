import * as React from "react";
import { Checkbox } from "semantic-ui-react";
import { _GetMatch } from "./CommonFunctions";

export const MyCheckBoxMatchField = ({
  field: { name, value },
  form: { touched, errors, setFieldValue},
  options,
  children: _,
  ...props
}: any) => {
  const errorText = touched[name] && errors[name];
  return (
    <Checkbox  label={_GetMatch(value)} toggle
      defaultChecked={value}
      onChange={(_, { checked }) => {
        setFieldValue(name, checked)
      }}
      error={errorText}
      {...props}
    />
  );
};
