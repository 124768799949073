import * as React from "react";
import { Checkbox, Dropdown } from "semantic-ui-react";
import { _GetBonusText } from "./CommonFunctions";

export const MyCheckBoxField = ({
  field: { name, value },
  form: { touched, errors, setFieldValue },
  options,
  children: _,
  ...props
}: any) => {
  const errorText = touched[name] && errors[name];
  return (
    <Checkbox  label={_GetBonusText(value)} toggle
      checked={value}
      onChange={(_, { checked }) => setFieldValue(name, checked)}
      error={errorText}
      {...props}
    />
  );
};
