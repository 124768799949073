import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { LabelKeyObject } from "react-csv/components/CommonPropTypes";
import { toast } from "react-toastify";
import { Checkbox, Icon, Input, InputOnChangeData, CheckboxProps, Header, Popup, Dropdown, DropdownProps, Grid, List, Button, Label} from "semantic-ui-react";
import agent from "../../app/api/agent";
import { _GetBonusText, _getWindowDimensions } from "../../app/lib/CommonFunctions";
import DrawLinkWithResult from "../../app/lib/DrawLinkWithResult";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { MyDictionary, MyDictionaryNumber, MyDropDownStringItems, NumbersInterval, OverviewResult } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";
import HelpGroup from "./HelpGroup";


export default function Forecast(){
    const [noOfDraw, setNoOfDraw] = useState(30);
    const [data, setData] = useState<OverviewResult>();
    const [isLoading, setIsLoading] = useState(true);
    const [useBonus, setUseBonus] = useState(true);
    const [tempNoOfDraw, setTempNoOfDraw] = useState(30);
    const [groups, setGroups] = useState<MyDropDownStringItems[]>([]);
    const [group, setGroup] = useState("3");
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());
    const {modalStore} = useStore();

    const showHelp = ()=>{
        modalStore.openModal(<HelpGroup></HelpGroup>, "Help","small", null, ()=>{})
  
    }
  
    useEffect(() => {
        function handleResize() {
          setWindowDimensions(_getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    

    var hitPergroup: MyDictionary<number> = {};
    var totalHitForBalls: MyDictionaryNumber<number> = {};


    const dropDownChanged = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps ) => {
        setGroup(data.value + "");
    }

        
    function getData():NumbersInterval[]{
        var result:NumbersInterval[] = [];
        var numbersSkip:any = {};

        if (!data || !data.totoNumbersResult) return result;

        if (data!.totoNumbersResult.length === 0) return result;

        data!.totoNumbersResult[0].ball.map((x, index)=>{

            var skip = x;

            if (skip === "O-X" || skip === "B-X"){
                skip = "0";
            }
            
            if (numbersSkip[skip]){
                numbersSkip[skip] += "," + (index + 1).toString();
            }
            else{
                numbersSkip[skip] = (index + 1).toString();
            }
            return true;
        })

        Object.keys(numbersSkip).map((key) => {
            result.push({
                interval: key,
                numbers: numbersSkip[key]
            })
            return true;
        });        
        return result;
    }    


    function getHeaders():LabelKeyObject[]{
        return [{label: "Interval", key: "interval"},
                {label: "Numbers", key: "numbers"}];
    }    

    const noOfDrawClicked = () =>{
        setNoOfDraw(tempNoOfDraw);
        setIsLoading(true);

        agent.DrawTotoResult.getForecast(noOfDraw, useBonus, group).then( (response)=> {
            setIsLoading(false);
            setData(response);
        });
    }

    const onDrawNoChanged = (event: React.ChangeEvent<HTMLElement>, data: InputOnChangeData )=>{
     
        if (Number(data.value)){
            setTempNoOfDraw(Number(data.value));
        }
        else{
            toast.error("No of Draw must be numbers !");
        }
    }

    const onBonusChanged =  (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps )=>{
        setUseBonus(data.checked!);
    }


    useEffect(()=>{
        agent.DrawTotoResult.getGroup().then((response)=>{
            setGroups(response);
            setGroup("S7")
        });
      },[]);


    useEffect(()=>{
        agent.DrawTotoResult.getForecast(noOfDraw, useBonus, group).then((response) =>{
            setData(response);
            setIsLoading(false);     
        });

    }, [noOfDraw, useBonus, group]);

    if (isLoading || !data || (data.totoNumbersResult && data.totoNumbersResult.length === 0)){
        return (<LoadingComponent></LoadingComponent>);
    }

    data.totoNumbersResult.map((r) => {

        data.groupNumbers.map((gp) => {

            gp.groupNumbers.split('-').map((ball)=>{
                const ballNo = parseInt(ball);

                if(r.ball[ballNo - 1].endsWith("-X"))
                {
                    if (hitPergroup[gp.code]){
                        hitPergroup[gp.code]++;
                    }
                    else{
                        hitPergroup[gp.code] = 1;
                    }

                    if(totalHitForBalls[ballNo]){
                        totalHitForBalls[ballNo]++;
                    }    
                    else{
                        totalHitForBalls[ballNo] = 1;
                    }
                }
                return true;
            })
    
            return true;
        });
     
        return true;
    });

    var uniqueHit: MyDictionaryNumber<number> = {};

    for(var i = 1; i <= 49; i++){
        if (!totalHitForBalls[i]){
            totalHitForBalls[i]=0;
        }
        else{
            if (!uniqueHit[totalHitForBalls[i]]){
                uniqueHit[totalHitForBalls[i]] = 1;
            }
            else{
                uniqueHit[totalHitForBalls[i]] ++;
            }
        }
    }

    var hotNumbers:MyDictionaryNumber<number>={};
    var warmNumbers:MyDictionaryNumber<number>={};
    
    var isHot: boolean = true;
    var isStop: boolean = false;
    var totalHot = 0;
    var totalWarm = 0;
    
    Object.entries(uniqueHit).reverse().map (([key, value]) => {
        
        if(isStop) return false;
        if(isHot ){
            if (totalHot + value < 16){
                hotNumbers[parseInt(key)] = 1;
                totalHot += value;
            }
            else{
                
                isHot = false;
                warmNumbers[parseInt(key)] = 1;
                totalWarm += value;
            }
        }
        else{
            if(totalWarm + value < 16){
                
                warmNumbers[parseInt(key)] = 1;
                totalWarm += value;
            }
            else{
                isStop = true;
                return false;
            }
        }

        return true;
    });

    var groupPattern: MyDictionary<number>={};
    var groupPatterns:string[] = [];

    data.totoNumbersResult.map((tr)=>{
        groupPattern = {};

        data.groupNumbers.map((gp) =>{
            groupPattern[gp.code] = 0;

            gp.groupNumbers.split('-').map((gpNumber) => {

                const ballNo = parseInt(gpNumber);
                if (tr.ball[ballNo - 1].endsWith("-X")){
                    groupPattern[gp.code]++;
                }

                return true;
            });
            
            return true;
        });
    
        var tempData:number[] = [];
        Object.entries(groupPattern).map(([key,value])=>{
            tempData.push(value);
            return true;
        });

        groupPatterns.push(tempData.join("-"));

        return true;
    });
 
    if (!data || data.totoNumbersResult.length === 0){
        return (<LoadingComponent></LoadingComponent>);
    }
    return (
        <div style={{overflow:"scroll", height:windowDimensions.height - 160}}>
        <Grid columns={3} divided>
        <Grid.Row>
            <Grid.Column textAlign="center" verticalAlign="middle">
                <Input onChange={onDrawNoChanged} maxLength={4} onKeyPress={(event:any) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }}
                    icon={<Icon name='search' inverted circular link onClick={noOfDrawClicked} />}
                    placeholder="No of Draws" defaultValue={noOfDraw}></Input>                    
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
                    <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged}>
                    </Checkbox>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
                    <Popup content="Please select group" position="bottom center" trigger={
                        <Dropdown  fluid   style={{zIndex:21}}
                            selection data-moveup="false"
                            onChange = {dropDownChanged}
                            placeholder="Select Group"
                            options={groups}
                            defaultValue = {group}
                            direction = "right"
                        />     
                      }>
                      </Popup>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column textAlign="center" verticalAlign="middle">
                    <Button primary size="mini" icon="help"  onClick={()=>showHelp()}></Button>    
                    <Label color="blue" size="medium">
                    OVERVIEW HORIZONTAL
                    </Label>                   
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">

            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
                <CSVLink data={getData()} filename="Interval" headers={getHeaders()}>
                            <Icon name="download"></Icon>Download 
                        </CSVLink>         

            </Grid.Column>
        </Grid.Row>
        </Grid>
        <List horizontal verticalAlign="middle">
        {
            data.totoNumbersResult.map((dt)=>{
                return(
                    <List.Item className="totoOverview">
                    <List.Header><Header textAlign="center">{dt.drawDate}</Header></List.Header>
                    {data.groupNumbers.map((grp)=>{
                        return(
                            
                            <List.Content>
                            {grp.groupNumbers.split('-').map((num)=>{
                                const ballValue = dt.ball[parseInt(num) - 1];
                                return(
                                    <DrawLinkWithResult bonus={ballValue === "B-X" ? true: false} number={num} ballValue={ballValue}></DrawLinkWithResult>
                                )
                            })
                            }
                            </List.Content>
                        )
                    })} 
                    </List.Item>
                )
            })
        }
        </List>
       </div>
    )
}