import { observer } from "mobx-react-lite";
import React, { useState } from "react"
import { useEffect } from "react";
import { toast } from "react-toastify";
import {  Table, Header, Checkbox, CheckboxProps, Input, Icon, InputOnChangeData, Popup, Grid, Label } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import { _GetBonusText, _getWindowDimensions } from "../../app/lib/CommonFunctions";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { AnalysisByDrawCycleResult,  DrawCycleNumbers} from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";

export default observer(function AnalysisByDrawCycleCurrent(){
    
    const displayNumbers=(groupNumbers: DrawCycleNumbers[], code: string) => {
      return groupNumbers.map((x) => {
        if (x.drawCycle.toString() === code){
          return x.numbers;
        }
        return "";
      })
    }


    const [noOfDraw, setNoOfDraw] = useState(50);
    const [loading, setLoading] = useState(true);
    const [useBonus, setUseBonus] = useState(true);
    const [analysis, setAnalysis] = useState<AnalysisByDrawCycleResult>();
    const {userStore} = useStore();
    const [tempNoOfDraw, setTempNoOfDraw] = useState(noOfDraw);
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(_getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    

    const noOfDrawClicked = () =>{
      setNoOfDraw(tempNoOfDraw);
      setLoading(true);
    }

    const onDrawNoChanged = (event: React.ChangeEvent<HTMLElement>, data: InputOnChangeData )=>{
     
      if (Number(data.value)){
          setTempNoOfDraw(Number(data.value));
      }
      else{
          toast.error("No of Draw must be numbers !");
      }
  }    

    const onBonusChanged =  (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps )=>{
      setUseBonus(data.checked!);
      setLoading(true);
    }


    useEffect(()=>{
          agent.DrawTotoResult.getAnalysisByDrawCycleCurrent(tempNoOfDraw,  useBonus).then((response)=>{

              setAnalysis(response);
              setLoading(false);
              
          });
    },[noOfDraw, useBonus]);


    if(!userStore.isLogged){
      return (<Header>Not Authorized</Header>)
    }

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto",height:windowDimensions.height - 160}}>
        <Grid stackable >
            <Grid.Row verticalAlign="middle">
                  <Grid.Column width={8}>
                    <Input fluid onChange={onDrawNoChanged} maxLength={4} onKeyPress={(event:any) => {
                                if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }}
                        icon={<Icon name='search' inverted circular link onClick={noOfDrawClicked} />}
                        placeholder="No of Draws" defaultValue={tempNoOfDraw}></Input>
                  </Grid.Column>
                  <Grid.Column width={8}>
                  <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged}>
                  </Checkbox>
                  </Grid.Column>
            </Grid.Row>
        </Grid>
        <Table celled unstackable selectable singleLine size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
               Draw Date/Draw Cycle
            </Table.HeaderCell>
            {
              analysis && analysis.drawCycle.split(',').map(x=>{

                return (<Table.HeaderCell textAlign="center">
                <Popup hoverable content={displayNumbers(analysis.drawCycleNumbers, x)} position="bottom center" trigger={
                  <Label>{x}</Label>                      
                  }></Popup>  
                </Table.HeaderCell>);
              })
            }
         </Table.Row>
        </Table.Header>
    
        <Table.Body>

          {analysis && analysis.data.map((x => {
            return (
                <Table.Row key={x.drawDate} className="freeze-head-and-col">
                    <Table.Cell textAlign="center">
                        {x.drawDate}
                    </Table.Cell>
                    {
                      analysis.drawCycle.split(',').map((y:string, index: number) => {
                            return (<Table.Cell textAlign="center">
                              {Object.entries(x).map(([key, value]) => {
                                  if (key === "col" + (index + 1).toString()){
                                      return value;
                                  }
                                  return null;
                                })
                              }
                            </Table.Cell>)                            
                      })
                    }
                </Table.Row>
              );
            }))
          }   
           </Table.Body>
      </Table>
   
      </div>
    );
});