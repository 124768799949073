import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react"
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { LabelKeyObject } from "react-csv/components/CommonPropTypes";
import { toast } from "react-toastify";
import {  Table, Header, Checkbox, CheckboxProps, Input, Icon, InputOnChangeData, Popup, Grid, Label, DropdownProps, Dropdown } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import { _HotWarmColdDraw } from "../../app/lib/CommonData";
import { _GetBonusText } from "../../app/lib/CommonFunctions";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { TrendHotWarmColdNumbers} from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";

export default observer(function HotWarmColdTrend(){
    const selectedDrawRef = useRef<any>();
    const [noOfDraw, setNoOfDraw] = useState(30);
    const [loading, setLoading] = useState(true);
    const [useBonus, setUseBonus] = useState(true);
    const [analysis, setAnalysis] = useState<TrendHotWarmColdNumbers[]>([]);
    const {userStore} = useStore();
    const [baseDraw, setBaseDraw] = useState(30);

    function getHeaders():LabelKeyObject[]{
      var data:LabelKeyObject[] = [];
  
      data.push({
        label:"Draw Date",
        key: "drawDate"
      });

      data.push({
        label:"Hot",
        key: "hot"
      });

      data.push({
        label:"Warm",
        key: "warm"
      });

      data.push({
        label:"Cold",
        key: "cold"
      });

      for (var i = 1; i <= 4; i++){
        data.push({
            label:"Hot" + i,
            key: "hot" + i
          });
    
          data.push({
            label:"Warm" + i,
            key: "warm" + i
          });
    
          data.push({
            label:"Cold" + i,
            key: "cold" + i
          });
    
      }

        return data;
    }    
  
    //const periodDisplay = (curYear - 11).toString() + " to " + (curYear - 1).toString();
    const noOfDrawClicked = () =>{

      const selectedDraw =  selectedDrawRef.current!.inputRef!.current!.value;

      if (Number(selectedDraw)){
        setNoOfDraw(selectedDraw);
        setLoading(true);
      }
    }


    const onBonusChanged =  (_ : any, data: CheckboxProps )=>{
      setUseBonus(data.checked!);
      setLoading(true);
    }

    const baseDrawChanged = (_ : any, data: DropdownProps ) => {
        setBaseDraw(parseInt(data.value + ""));
    }


    useEffect(()=>{
          agent.DrawTotoResult.getHotWarmColdTrend(noOfDraw,  useBonus, baseDraw).then((response)=>{
                console.log("Date: " + response[0].drawDate);
              setAnalysis(response);
              setLoading(false);
              
          });
    },[noOfDraw, useBonus, baseDraw]);


    if(!userStore.isLogged){
      return (<Header>Not Authorized</Header>)
    }

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto"}}>
        <Table celled unstackable selectable singleLine size="small">
        <Table.Header>
          <Table.Row key="header1">
          <Table.HeaderCell textAlign="center">
          <Input ref={selectedDrawRef} fluid maxLength={4} onKeyPress={(event:any) => {
                                if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }}
                        icon={<Icon name='search' inverted circular link onClick={noOfDrawClicked} />}
                        placeholder="No of Draws" defaultValue={noOfDraw}></Input>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
                    <Popup content="Please select Periods" position="top center" trigger={
                        <Dropdown  fluid   style={{zIndex:21}}
                            selection data-moveup="false"
                            onChange = {baseDrawChanged}
                            placeholder="Select Periods"
                            options={_HotWarmColdDraw}
                            defaultValue = {baseDraw}
                            direction = "right"
                        />     
                      }>
                      </Popup>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" colSpan="2">
          <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged}>
          </Checkbox>
          </Table.HeaderCell>
          
          <Table.HeaderCell textAlign="left" colSpan="10">
         <Header>HOT/WARM/COLD TREND</Header>
         </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" colSpan="2">
          <CSVLink data={analysis} filename="HotWarmCold" headers={getHeaders()}>
                                <Icon name="download"></Icon>Download 
                    </CSVLink>         

         </Table.HeaderCell>
          </Table.Row>
          <Table.Row key="header2">
            <Table.HeaderCell textAlign="center">
               Draw Date
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
               Hot
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
               Warm
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
               Cold
            </Table.HeaderCell>

            <Table.HeaderCell textAlign="center">
               Hot 1
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
               Warm 1
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
               Cold 1
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
               Hot 2
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
               Warm 2
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
               Cold 2
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
               Hot 3
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
               Warm 3
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
               Cold 3
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
               Hot 4
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
               Warm 4
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
               Cold 4
            </Table.HeaderCell>

         </Table.Row>
        </Table.Header>
    
        <Table.Body>

          {analysis.length > 0 && analysis.map((x => {
            return (
                <Table.Row key={x.drawDate} className="freeze-head-and-col">
                    <Table.Cell textAlign="center">
                        {x.drawDate}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.hot}
                        {x.drawDate === "% Hit" ? " %" : ""}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.warm}
                        {x.drawDate === "% Hit" ? " %" : ""}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.cold}
                        {x.drawDate === "% Hit" ? " %" : ""}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.hot1}
                        {x.drawDate === "% Hit" ? " %" : ""}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.warm1}
                        {x.drawDate === "% Hit" ? " %" : ""}

                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.cold1}
                        {x.drawDate === "% Hit" ? " %" : ""}

                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.hot2}
                        {x.drawDate === "% Hit" ? " %" : ""}

                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.warm2}
                        {x.drawDate === "% Hit" ? " %" : ""}

                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.cold2}
                        {x.drawDate === "% Hit" ? " %" : ""}

                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.hot3}
                        {x.drawDate === "% Hit" ? " %" : ""}

                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.warm3}
                        {x.drawDate === "% Hit" ? " %" : ""}

                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.cold3}
                        {x.drawDate === "% Hit" ? " %" : ""}

                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.hot4}
                        {x.drawDate === "% Hit" ? " %" : ""}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.warm4}
                        {x.drawDate === "% Hit" ? " %" : ""}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.cold4}
                        {x.drawDate === "% Hit" ? " %" : ""}
                    </Table.Cell>

                </Table.Row>
              );
            }))
          }   
           </Table.Body>
      </Table>
   
      </div>
    );
});