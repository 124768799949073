import React from "react";
import { Button, Header, Label, } from "semantic-ui-react";
import ViewGraphics3DPopup from "../../feature/Search/ViewGraphics3DPopup";
import { useStore } from "../store/store";

interface Props{
    number: string;
    bonus: boolean;
    title?: string;
    children?: any;
    color: any;
}
export default function DrawLinkWithColor(props: Props){
    
    const {userStore, modalStore} = useStore();
    const onClick = () => {
        localStorage.setItem("NumberToSearch", props.number);
        localStorage.setItem("NumberBonus",  props.bonus ? "1" : "0");
        modalStore.openModal(<ViewGraphics3DPopup></ViewGraphics3DPopup>,"View Interval", "fullscreen", null, ()=> {

        });
    }

    return (
        <Button size="mini" content={props.number} color={props.color} circular onClick={onClick} data-tooltip={userStore.isLogged ? "Click to view detail information of the number" : "Login to use the functions !"}>
        </Button>
    );
}