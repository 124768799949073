import { observer } from "mobx-react-lite";
import React, {  useState } from "react"
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { Table, Header, Checkbox, CheckboxProps, Input, Button, InputOnChangeData, Icon, Label } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import { _GetBonusText, _getWindowDimensions } from "../../app/lib/CommonFunctions";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { AnalysisByDrawResult } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";
import DrawSummary from "./DrawSummary";

export default observer(function Analysis(){
    
    const curYear = new Date().getFullYear();

    const [loading, setLoading] = useState(true);
    const [useBonus, setUseBonus] = useState(true);
    const [analysis, setAnalysis] = useState<AnalysisByDrawResult[]>([]);
    const [column, setColumn] = useState("month" + (new Date().getMonth()));
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("descending");
    const {userStore, modalStore} = useStore();
    const [noOfDraw, setNoOfDraw]= useState(30);
    const [noOfDraw1, setNoOfDraw1]= useState(60);
    const [noOfDraw2, setNoOfDraw2]= useState(90);
    const [noOfDraw3, setNoOfDraw3]= useState(120);
    const [refreshData, setRefreshData] = useState(true);
    const [selectedNumber, setSelectedNumber] = useState("");
    const [windowDimensions, setWindowDimensions] = useState(_getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(_getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    

    const headers = [{label: "Ball Number",key: "ball"},
                    {label: "Total", key: "total"},
                    {label:"Average Hit", key: "avg_Hit"},
                    {label: "Average 10 Years",key:"avg_10Year"},
                    {label:curYear.toString(), key: "current_year"},
                    {label:(curYear - 1).toString(), key: "year1"},
                    {label:(curYear - 2).toString(), key: "year2"},
                    {label:(curYear - 3).toString(), key: "year3"},
                    {label:(curYear - 4).toString(), key: "year4"},
                    {label:(curYear - 5).toString(), key: "year5"},
                    {label:(curYear - 6).toString(), key: "year6"},
                    {label:(curYear - 7).toString(), key: "year7"},
                    {label:(curYear - 8).toString(), key: "year8"},
                    {label:(curYear - 9).toString(), key: "year9"},
                    {label:(curYear - 10).toString(), key: "year10"},
                  
                  ]        

    const cellClicked = (numberClicked: number) =>{
      setSelectedNumber(numberClicked.toString());
    }

    const getSelectedColor = (numberToCheck: number) =>{
      if (numberToCheck.toString() === selectedNumber){
        return "lightgreen";
      }
      return "";
    }
    const onBonusChanged =  (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps )=>{
      setUseBonus(data.checked!);
      setLoading(true);
    }

    const onDrawChanged =  (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>{
      setNoOfDraw(parseInt(data.value));
    } 

    const onDrawChanged1 =  (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>{
      setNoOfDraw1(parseInt(data.value));
    } 

    const onDrawChanged2 =  (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>{
      setNoOfDraw2(parseInt(data.value));
    } 

    const onDrawChanged3 =  (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>{
      setNoOfDraw3(parseInt(data.value));
    } 

    const handleColumnClick = (columnName : string)=>{
      if (column !== columnName){
        setDirection("ascending");
      }
      else{
        if (direction === undefined){
          setDirection('ascending');
        }
        else{
          setDirection(direction === "ascending" ? "descending" : "ascending");
        }
      }
      setColumn(columnName);
      setLoading(true);

    }

    const searchData = () => {
      setRefreshData(true);

    }

    useEffect(()=>{

        let newDirection = "none";
        if (direction !== undefined){
          if (direction === "ascending"){
            newDirection = "asc";
          }
          else{
            newDirection = "desc";
          }
        }

        agent.DrawTotoResult.getAnalysisByDraw(noOfDraw, noOfDraw1, noOfDraw2, noOfDraw3, useBonus, column, newDirection).then((response)=>{

            setAnalysis(response);
            setLoading(false);
            setRefreshData(false);
            
        });

        
    },[useBonus, column, direction, refreshData]);

    const openSummary = ()=>{
      localStorage.setItem("useBonus", (useBonus ? "1" : "0"));

      modalStore.openModal(<DrawSummary></DrawSummary>,"Hot Warm Cold Summary","medium", null, ()=>{})

    }

    if(!userStore.isLogged){
      return (<Header>Not Authorized</Header>)
    }

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto",height:windowDimensions.height - 160}}>
        <Table celled unstackable selectable singleLine size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
              <Button color="blue" icon="share square" onClick={()=>openSummary()}></Button>
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={userStore.user?.groupID === 8 ? "4" : "2"}>
                <Checkbox toggle label={_GetBonusText(useBonus)} defaultChecked={useBonus} onChange={onBonusChanged}>
                </Checkbox>
              </Table.HeaderCell>
              <Table.HeaderCell colSpan={userStore.user?.groupID === 8 ? "8" : "4"} textAlign="center">
                  <Header>ANALYSIS BY PERIODS
                  <Label circular color="red">Hot</Label>                        
                        <Label circular color="yellow">Warm</Label>                        
                        <Label circular color="blue">Cold</Label>                     
                  </Header>
              </Table.HeaderCell>
              <Table.HeaderCell colSpan={userStore.user?.groupID === 8 ? "4" : "2"} textAlign="center">
                        <CSVLink data={analysis} filename="AnalysisByDraw" headers={headers}>
                            <Icon name="download"></Icon>Download 
                        </CSVLink>         

              </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
            <Button primary icon="search" value={noOfDraw} onClick = {()=>searchData()}></Button>
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={userStore.user?.groupID === 8 ? "4" : "2"}>
              <Input readOnly min = "1" max="9999" type="number" fluid label="# Draw" value={noOfDraw} onChange={onDrawChanged}></Input>
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={userStore.user?.groupID === 8 ? "4" : "2"}>
              <Input readOnly min = "1" max="9999" type="number" fluid label="# Draw" value={noOfDraw1} onChange={onDrawChanged1}></Input>
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={userStore.user?.groupID === 8 ? "4" : "2"}>
              <Input readOnly min = "1" max="9999" type="number" fluid label="# Draw" value={noOfDraw2} onChange={onDrawChanged2}></Input>
            </Table.HeaderCell> 
            <Table.HeaderCell colSpan={userStore.user?.groupID === 8 ? "4" : "2"}>
              <Input readOnly min = "1" max="9999" type="number" fluid label="# Draw" value={noOfDraw3} onChange={onDrawChanged3}></Input>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
            <Table.HeaderCell  textAlign="center">
              Ball<br/>Number
            </Table.HeaderCell> 
            <Table.HeaderCell textAlign="center" className={userStore.user?.groupID === 8 ? "": "doubleBar"}>
              No of<br/>Hit
            </Table.HeaderCell>
            { userStore.user?.groupID === 8 &&  
            <Table.HeaderCell  textAlign="center">
              Ratio
            </Table.HeaderCell> 
            }
            { userStore.user?.groupID === 8 &&  
            <Table.HeaderCell  textAlign="center">
              Hit Due
            </Table.HeaderCell> 
            }
            <Table.HeaderCell  textAlign="center">
              Ball<br/>Number
            </Table.HeaderCell> 
            <Table.HeaderCell textAlign="center" className={userStore.user?.groupID === 8 ? "": "doubleBar"}>
              No of<br/>Hit
            </Table.HeaderCell> 
            { userStore.user?.groupID === 8 &&  
            <Table.HeaderCell  textAlign="center">
              Ratio
            </Table.HeaderCell>
            } 
            { userStore.user?.groupID === 8 &&  
            <Table.HeaderCell  textAlign="center">
              Hit Due
            </Table.HeaderCell>
            } 
             
            <Table.HeaderCell  textAlign="center">
              Ball<br/>Number
            </Table.HeaderCell> 
            <Table.HeaderCell className={userStore.user?.groupID === 8 ? "" :"doubleBar" } textAlign="center" >
              No of<br/>Hit
            </Table.HeaderCell> 
            { userStore.user?.groupID === 8 &&  
            <Table.HeaderCell  textAlign="center">
              Ratio
            </Table.HeaderCell>
            } 
            { userStore.user?.groupID === 8 &&  
            <Table.HeaderCell  textAlign="center">
              Hit Due
            </Table.HeaderCell>
            } 

            <Table.HeaderCell  textAlign="center">
              Ball<br/>Number
            </Table.HeaderCell> 
            <Table.HeaderCell textAlign="center" className={userStore.user?.groupID === 8 ? "" :"doubleBar" }>
              No of<br/>Hit
            </Table.HeaderCell> 
            { userStore.user?.groupID === 8 &&  
            <Table.HeaderCell  textAlign="center">
              Ratio
            </Table.HeaderCell>
            } 
            { userStore.user?.groupID === 8 &&  
            <Table.HeaderCell  textAlign="center">
              Hit Due
            </Table.HeaderCell>
            } 
          </Table.Row>
        </Table.Header>
    
        <Table.Body>

          {analysis.map(((x, index) => {
            return (
                <Table.Row key={x.ball} className="freeze-head-and-col">
                  <Table.Cell onClick={()=>cellClicked(x.ball)} textAlign="center">{index + 1}</Table.Cell>
                  <Table.Cell onClick={()=>cellClicked(x.ball)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball)}>
                    <Label circular color={x.ballType === "H" ? "red" : (x.ballType === "W" ? "yellow" :"blue")}  size="medium">
                    {x.ball}
                    </Label>
                  </Table.Cell>
                  <Table.Cell className={userStore.user?.groupID === 8 ? "" :"doubleBar" }  onClick={()=>cellClicked(x.ball)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball)}>
                    {x.noOfHit}
                  </Table.Cell>
                  {userStore.user?.groupID === 8 && 
                  <Table.Cell  onClick={()=>cellClicked(x.ball)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball)}>
                    {x.ratio}
                  </Table.Cell>
                  }
                    {userStore.user?.groupID === 8 && 
                    <Table.Cell className="doubleBar"  onClick={()=>cellClicked(x.ball)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball)}>
                      {x.lastSkip}
                    </Table.Cell>
                  }

                  <Table.Cell onClick={()=>cellClicked(x.ball1)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball1)}>
                  <Label circular color={x.ballType1 === "H" ? "red" : (x.ballType1 === "W" ? "yellow" :"blue")}  size="medium">
                    {x.ball1}
                    </Label>
                  </Table.Cell>
                  <Table.Cell  className={userStore.user?.groupID === 8 ? "" :"doubleBar" } onClick={()=>cellClicked(x.ball1)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball1)}>
                    {x.noOfHit1}
                  </Table.Cell>
                  {userStore.user?.groupID === 8 && 
                  <Table.Cell  onClick={()=>cellClicked(x.ball1)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball1)}>
                    {x.ratio1}
                  </Table.Cell>
                  }
                    {userStore.user?.groupID === 8 && 
                    <Table.Cell className="doubleBar" onClick={()=>cellClicked(x.ball1)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball1)}>
                      {x.lastSkip1}
                    </Table.Cell>
                  }

                  <Table.Cell onClick={()=>cellClicked(x.ball2)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball2)}>
                  <Label circular color={x.ballType2 === "H" ? "red" : (x.ballType2 === "W" ? "yellow" :"blue")}  size="medium">
                    {x.ball2}
                    </Label>
                  </Table.Cell>
                  <Table.Cell className={userStore.user?.groupID === 8 ? "" :"doubleBar" }  onClick={()=>cellClicked(x.ball2)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball2)}>
                    {x.noOfHit2}
                  </Table.Cell>
                  {userStore.user?.groupID === 8 && 
                  <Table.Cell  onClick={()=>cellClicked(x.ball2)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball2)}>
                    {x.ratio2}
                  </Table.Cell>
                  }
                    {userStore.user?.groupID === 8 && 
                    <Table.Cell className="doubleBar" onClick={()=>cellClicked(x.ball2)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball2)}>
                      {x.lastSkip2}
                    </Table.Cell>
                  }

                  <Table.Cell onClick={()=>cellClicked(x.ball3)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball3)}>
                  <Label circular color={x.ballType3 === "H" ? "red" : (x.ballType3 === "W" ? "yellow" :"blue")}  size="medium">
                    {x.ball3}
                    </Label>
               </Table.Cell>
                  <Table.Cell className={userStore.user?.groupID === 8 ? "" :"doubleBar" }  onClick={()=>cellClicked(x.ball3)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball3)}>
                    {x.noOfHit3}
                  </Table.Cell>
                  {userStore.user?.groupID === 8 && 
                  <Table.Cell  onClick={()=>cellClicked(x.ball3)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball3)}>
                    {x.ratio3}
                  </Table.Cell>
                  }
                    {userStore.user?.groupID === 8 && 
                    <Table.Cell  onClick={()=>cellClicked(x.ball3)}  textAlign="center" selectable bgcolor={getSelectedColor(x.ball3)}>
                      {x.lastSkip3}
                    </Table.Cell>
                  }

                </Table.Row>
              );
            }))
          }   
           </Table.Body>
      </Table>
   
      </div>
    );
});