import { ErrorMessage, Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { Button,  Divider,  Label } from "semantic-ui-react";
import * as Yup from 'yup';
import { _GetBonusText, _GetFilterName } from "../../app/lib/CommonFunctions";
import { MyCheckBoxField } from "../../app/lib/MyCheckBoxField";
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import { WheelingCondition } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";


export default observer(function FilterGenericMinMax(){
    const {userStore, modalStore} = useStore();
    

    const validationSchema = Yup.object({
        min: Yup.number().positive().integer().min(0,"Minimum cannot be smaller than 0 ").max(12,"Maximum cannot be greater than 12").test("checkGreater","Minimum must be smaller than Maximum !",(value, ctx)=> Number(ctx.parent.max) >= Number(value)),
        max: Yup.number().positive().integer().min(0,"Minimum cannot be smaller than 0 ").max(12,"Maximum cannot be greater than 12"),
    });

    const defDate = moment().add(1, "y").format("DD/MM/yyyy")
    return (
        <Formik initialValues={{ min: 0, max: 3, useBonus: true, error: null}}
                onSubmit={
                    (values,{setErrors}) => {
                        const minValue = values["min"];
                        const maxValue = values["max"];
                        const useBonus = values["useBonus"];
                        const filterID = localStorage.getItem("FilterID") + "";

                        var filterCondition: WheelingCondition = {
                            filterType : filterID,
                            filterTypeName: _GetFilterName(filterID),
                            data :  _GetBonusText(useBonus),
                            min: Number(minValue),
                            max: Number(maxValue)
                        };

                        localStorage.setItem("FilterCondition", JSON.stringify(filterCondition));
                        modalStore.closeModal();
                    }
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                      
                        <MyTextInputNonFluid data-tooltip="Minimum value to filter" type="number" name="min" placeholder="Enter Mininum"></MyTextInputNonFluid>
                        <MyTextInputNonFluid data-tooltip="Maximum value to filter" type="number" name="max" placeholder="Enter maximum"></MyTextInputNonFluid>

                        <Field
                                name="useBonus"
                                component={MyCheckBoxField}
                        />

                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Divider />
                        <Button icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>
                    </Form>
                
            )}
        </Formik>
    )
})