import { makeAutoObservable } from "mobx"

interface Modal{
    open: boolean;
    body: JSX.Element | null;
    header? : string;
    size: any;
    action: JSX.Element| null;
}

export default class ModalStore{
    modal: Modal = {
        open: false,
        body: null,
        header: "",
        size: "mini",
        action: null
    }

    afterCloseHandler: ()=>void;

    constructor(){
        makeAutoObservable(this);
        this.afterCloseHandler = () => {

        };
    }

    openModal = (content: JSX.Element, header: string, size: any, action: JSX.Element | null, afterClose: ()=>void) => {
        this.modal.open = true;
        this.modal.body = content;
        this.modal.header = header;
        this.modal.size= size;
        this.modal.action = action;
        this.afterCloseHandler = afterClose;
    }

    closeModal = () =>{
        this.modal.open = false;
        this.modal.body = null;

        this.afterCloseHandler();

    }
}